import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Container, IconButton, TextField } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { ConfirmDialog } from '../../component/utils';


export function MomSituacionEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  return (
    <Container>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box>

        <Box>
          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            id="nombre"
            label='Nombre'
            name='nombre'

            value={state.nombre}
            onChange={handleInputChange}
          />

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            id="orden"
            label='Orden'
            name='orden'
            type={'number'}

            value={state.orden}
            onChange={handleInputChange}
          />

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            id="nivel"
            label='Nivel'
            name='nivel'
            type={'number'}

            value={state.nivel}
            onChange={handleInputChange}
          />

        </Box>

        <div style={{ height: 20 }}></div>

        <Box display='flex' justifyContent='flex-end' width={1}>

          {
            props.onDelete ?
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: '5px' }}
                onClick={() => setOpenConfirmationDialog(true)}
              >
                Borrar
              </Button>
              : <div></div>
          }

          <Button
            variant="contained"
            color="default"
            style={{ margin: '5px' }}
            onClick={() => props.cancel()}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => props.save(state)}
          >
            Guardar
          </Button>

        </Box>

      </Box>

    </Container>

  );
};