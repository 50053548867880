import React, { useEffect, useState, useRef } from 'react';

import { Box, Typography, Chip } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { Container, TextField } from '@material-ui/core';

// utilidades
import { ConfirmDialog } from '../../component/utils';


export function EmailPlantillaViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);
  const [palabras] = useState(props.emailPalabrasClave);

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);


  const cabeceraRef = useRef(null);
  const detalleRef = useRef(null);
  const pieRef = useRef(null);

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);


  const ListadoPalabras = ({ palabras, onClick }) => {
    return <Box margin={1} width={1} display='flex' flexWrap={'wrap'}>
      {
        palabras.map((palabra, index) => {
          return <Chip
            key={index}
            label={palabra.nombre}
            clickable
            size='small'
            color="primary"
            style={{ margin: '3px' }}
            onClick={() => onClick(palabra)}
          />
        })
      }
    </Box>;

  }


  return (
    <Container maxWidth='md'>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box className='background-forms'>

        <TextField
          margin='dense'
          size='small'
          variant='standard'
          fullWidth
          id="nombre"
          label='Nombre'
          name='nombre'
          autoComplete='nombre'

          value={state.nombre}
          onChange={handleInputChange}
        />

        <div style={{ height: 20 }}></div>
        <Typography variant='h6'>Cabecera</Typography>

        <ListadoPalabras palabras={palabras} onClick={(palabra) => setState({ ...state, 'cabecera': state.cabecera + palabra.valor })} />

        <textarea
          rows={8}
          style={{ width: '100%' }}
          value={state.cabecera}
          onChange={(e) => setState({ ...state, 'cabecera': e.target.value })} />

        <div style={{ height: 20 }}></div>
        <Typography variant='h6'>Detalle</Typography>

        <ListadoPalabras palabras={palabras} onClick={(palabra) => setState({ ...state, 'detalle': state.detalle + palabra.valor })} />

        <textarea
          rows={20}
          style={{ width: '100%' }}
          value={state.detalle}
          onChange={(e) => setState({ ...state, 'detalle': e.target.value })} />

        <div style={{ height: 20 }}></div>
        <Typography variant='h6'>Pie</Typography>

        <ListadoPalabras palabras={palabras} onClick={(palabra) => setState({ ...state, 'pie': state.pie + palabra.valor })} />

        <textarea
          rows={20}
          style={{ width: '100%' }}
          value={state.pie}
          onChange={(e) => setState({ ...state, 'pie': e.target.value })} />

        <div style={{ height: 20 }}></div>

        <Box display='flex' justifyContent='flex-end' width={1}>

          {
            props.onDelete ?
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: '5px' }}
                onClick={() => setOpenConfirmationDialog(true)}
              >
                Borrar
              </Button>
              : <div></div>
          }

          <Button
            variant="contained"
            color="default"
            style={{ margin: '5px' }}
            onClick={() => props.cancel()}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => props.save(state)}
          >
            Guardar
          </Button>
        </Box>

      </Box>

    </Container>

  );
};
