import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Container, IconButton, TextareaAutosize, TextField } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core/';
import { FormControlLabel, Checkbox } from '@material-ui/core';

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import DeleteIcon from '@material-ui/icons/Delete';

// entity para funciones del mundo
import { World } from '../../entity/world';

import { BotJsonSkinMenuItem } from '../botsjsonskin/botJsonSkinMenuItem';


// utilidades
import { ConfirmDialog } from '../../component/utils';

import { ContainerSkills } from '../../component/containerSkills';

export function BotViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // ref para el input de tipo file
  const hiddenFileInput = React.createRef();

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  // mensaje socket
  const [mensajeSocket, setMensajeSocket] = useState('');

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);


  // esta función añade un nuevo skill al bot
  const addSkill = element => {
    let tmp = state.skills;

    // si el skill ya está añadido, no lo vuelvo a añadir
    let existe = tmp.filter(x => x.id === element.id).length;

    // si no existe, lo añado
    if (existe === 0) {
      tmp.push(element);
    }

    setState({ ...state, 'skills': tmp });
  }


  return (
    <Container maxWidth='xl'>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box className='background-forms' display='flex'>

        <Box className='m-2'>
          <Box display='flex' justifyContent="center">
            <img
              alt={state.nombre}
              src={state.imagenAvatar}
              style={{ margin: '10px', maxWidth: '100%', maxHeight: '200px' }}
            />
          </Box>
          <Box display='flex mr-2'>

            <input ref={hiddenFileInput} accept='image/*' type='file' style={{ display: 'none' }}
              onChange={e => {
                setState({
                  ...state,
                  imagenData: e.target.files[0],
                  imagenAvatar: URL.createObjectURL(e.target.files[0])
                });
              }} />
            <Avatar style={{ marginRight: '10px' }}>
              <IconButton onClick={() => { hiddenFileInput.current.click(); }} >
                <PhotoCameraIcon />
              </IconButton>
            </Avatar>

            <TextField
              margin='dense'
              size='small'
              variant='standard'
              fullWidth
              id="nombre"
              label='Nombre'
              name='nombre'
              autoComplete='nombre'

              value={state.nombre}
              onChange={handleInputChange}
            />

          </Box>

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            id="descripcion"
            label='Descripcion'
            name='descripcion'

            value={state.descripcion}
            onChange={handleInputChange}
          />

          <FormControl fullWidth>
            <InputLabel htmlFor="set-genero">Genero</InputLabel>
            <Select
              labelId="set-genero"
              id="genero"
              value={state.genero}
              onChange={(e) => setState({ ...state, 'genero': e.target.value })}
            >
              <MenuItem value={''} key={'seleccionar-genero'}>{'Seleccionar'}</MenuItem>
              <MenuItem value={'masculino'} key={'masculino'}>{'Masculino'}</MenuItem>
              <MenuItem value={'femenino'} key={'Femenino'}>{'Femenino'}</MenuItem>
            </Select>
          </FormControl>

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            label='Coste'
            type="number"

            value={state.coste}
            onChange={(e) => setState({ ...state, 'coste': e.target.value })}
          />

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            label='Precio Venta'
            type="number"

            value={state.precioVenta}
            onChange={(e) => setState({ ...state, 'precioVenta': e.target.value })}
          />

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            label='Usos'
            type="number"

            value={state.usos}
            onChange={(e) => setState({ ...state, 'usos': e.target.value })}
          />

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            label='Disponibilidad'
            type="number"

            value={state.disponibilidad}
            onChange={(e) => setState({ ...state, 'disponibilidad': e.target.value })}
          />

          <FormControl fullWidth>
            <InputLabel htmlFor="set-skin">Skin Json</InputLabel>
            <Select
              labelId="set-skin"
              id="skinJSON"
              value={state.skinJSON}
              onChange={(e) => setState({ ...state, 'skinJSON': e.target.value })}
            >
              {
                props.botsjsonskin.map((element) => {
                  return <MenuItem value={element.skinJSON} key={element._id}><BotJsonSkinMenuItem item={element} /></MenuItem>
                })
              }
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <TextareaAutosize
              id='skinJSON'
              name='skinJSON'
              placeholder='skinJSON'
              wrap='true'
              rowsMin={3}
              style={{ width: 200 }}

              value={state.skinJSON}
              onChange={(e) => setState({ ...state, 'skinJSON': e.target.value })}
            />
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                checked={state.default}
                onChange={(e) => {
                  setState({ ...state, 'default': !state.default });
                }}
                name={"default"}
                color="secondary"
              />
            }
            label="Es el Bot por defecto (en TODO)"
          />


          <FormControl fullWidth>
            <InputLabel htmlFor="set-empresa">Empresa</InputLabel>
            <Select
              labelId="set-empresa"
              id="empresa"
              value={state.empresaTarget}
              onChange={(e) => setState({ ...state, 'empresaTarget': e.target.value })}
            >
              {
                props.empresas.map((element) => {
                  return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
                })
              }
            </Select>
          </FormControl>

          <Box display='flex'>
            <TextField
              margin='dense'
              size='small'
              variant='standard'
              fullWidth
              id="mensaje"
              label='Mensaje a enviar'
              name='mensaje'

              value={mensajeSocket}
              onChange={(e) => setMensajeSocket(e.target.value)}
            />

            <Button
              variant="contained"
              size="small"
              color="secondary"
              style={{ margin: '5px' }}
              onClick={() => {
                let data = new FormData();

                data.append('targetid', state._id);
                data.append('mensaje', mensajeSocket);

                World.sendSocketMessageBot(data);
              }}
            >
              Send
            </Button>


          </Box>

          <div style={{ height: 20 }}></div>

          <Box display='flex' justifyContent='flex-end' width={1}>

            {
              props.onDelete ?
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: '5px' }}
                  onClick={() => setOpenConfirmationDialog(true)}
                >
                  Borrar
                </Button>
                : <div></div>
            }

            <Button
              variant="contained"
              color="default"
              style={{ margin: '5px' }}
              onClick={() => props.cancel()}
            >
              Cancelar
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ margin: '5px' }}
              onClick={() => props.save(state)}
            >
              Guardar
            </Button>
          </Box>

        </Box>

        <Box display='flex'>
          {
            state.skills && state.skills.length > 0
              ? (
                <Box className='background-list-cards' m={1} p={1} >
                  {
                    state.skills.map((element, index) => {
                      return <Box m={1} key={element.id + element.nombre} display='flex' alignItems='center'>

                        <Avatar
                          src={element.imagenAvatar}
                          alt={element.nombre}
                          title={element.nombre} />

                        <TextField
                          size='small'
                          variant='standard'
                          fullWidth
                          id="nivel"
                          label={element.nombre}
                          name='nivel'
                          type='number'
                          style={{ margin: 10 }}

                          value={element.valor}
                          onChange={(event) => {
                            let tmpSkill = state.skills;
                            tmpSkill[index].valor = event.target.value;
                            setState({ ...state, 'skills': tmpSkill });
                          }}
                        />

                        <IconButton onClick={() => {
                          let tmpSkill = state.skills;
                          tmpSkill.splice(index, 1);
                          setState({ ...state, 'skills': tmpSkill });
                        }} >
                          <DeleteIcon />
                        </IconButton>

                      </Box>
                    }
                    )
                  }
                </Box>
              )
              : ''
          }

          <ContainerSkills skills={props.skills} addSkill={addSkill} />

        </Box>

      </Box>

    </Container>

  );
};