import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Container, IconButton, TextField } from '@material-ui/core';
import { Avatar, Typography } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import { ContainerSkills } from '../../component/containerSkills';


export function RespuestaPreguntaViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  // esta función añade un nuevo skill a la respuesta
  const addSkill = element => {
    let tmp = state.skills;

    if(tmp === undefined) {
      tmp = [];
    }

    // si el skill ya está añadido, no lo vuelvo a añadir
    let existe = tmp.filter(x => x.id === element.id).length;

    // si no existe, lo añado
    if (existe === 0) {
      tmp.push(element);
    }

    setState({ ...state, 'skills': tmp });
  }


  return (
    <Container maxWidth='md'>

      <Box className='background-forms'>

        <Typography variant="body1" align="center">Preguntas</Typography>

        <Box>
          <TextField
            margin='dense'
            size='small'
            variant='standard'
            id="orden"
            label='Orden'
            name='orden'
            type={'number'}

            value={state.orden}
            onChange={handleInputChange}
          />

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            id="descripcion"
            label='Respuesta'
            name='descripcion'

            multiline
            rows={4}

            value={state.descripcion}
            onChange={handleInputChange}
          />

          <div style={{ height: 20 }}></div>

          <Box display='flex'>
            {
              state.skills && state.skills.length > 0
                ? (
                  <Box className='background-list-cards' m={1} p={1} >
                    {
                      state.skills.map((element, index) => {
                        return <Box m={1} key={element.id + element.nombre} display='flex' alignItems='center'>

                          <Avatar
                            src={element.imagenAvatar}
                            alt={element.nombre}
                            title={element.nombre} />

                          <TextField
                            size='small'
                            variant='standard'
                            fullWidth
                            id="nivel"
                            label={element.nombre}
                            name='nivel'
                            type='number'
                            style={{ margin: 10 }}

                            value={element.valor}
                            onChange={(event) => {
                              let tmpSkill = state.skills;
                              tmpSkill[index].valor = event.target.value;
                              setState({ ...state, 'skills': tmpSkill });
                            }}
                          />

                          <IconButton onClick={() => {
                            let tmpSkill = state.skills;
                            tmpSkill.splice(index, 1);
                            setState({ ...state, 'skills': tmpSkill });
                          }} >
                            <DeleteIcon />
                          </IconButton>

                        </Box>
                      }
                      )
                    }
                  </Box>
                )
                : ''
            }

            <ContainerSkills skills={props.skills} addSkill={addSkill} />

          </Box>

          <Box display='flex' justifyContent='flex-end' width={1}>

            {
              props.onDelete ?
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: '5px' }}
                  onClick={() => props.onDelete(state)}
                >
                  Borrar
                </Button>
                : <div></div>
            }

            <Button
              variant="contained"
              color="default"
              style={{ margin: '5px' }}
              onClick={() => props.cancel()}
            >
              Cancelar
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ margin: '5px' }}
              onClick={() => props.save(state)}
            >
              Guardar
            </Button>
          </Box>

        </Box>

      </Box>

    </Container>

  );
};