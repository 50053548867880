import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import orange from '@material-ui/core/colors/orange';
import { blue } from '@material-ui/core/colors';

import Login from './views/users/login';
import Registro from './views/users/registro';
import EditUser from './views/users/editUser';
import Main from './views/main';


import MisPlantillas from './views/principales/misPlantillas';
import NuevaPlantilla from './views/principales/nuevaPlantilla';
import Editor from './views/principales/editor';
import EditorReactFlow from './views/principales/editorReactFlow';
import EditorWorld from './views/principales/editorWorld';

import Moms from './views/mom/moms';
import MomFlow from './views/mom/momFlow';

import { Usuarios } from './entity/usuarios';
import { PrivateRoute } from './util/private-route';


const theme = createMuiTheme({
  typography: {
    fontSize: 12,
  },
  palette: {
    type: 'light',
    primary: blue,
    secondary: orange,
  },
  props: {
    MuiFormControl: {
      variant: 'outlined',
    },
    MuiSelect: {
      autoWidth: true
    }
  },
  overrides: {
    MuiFab: {
      root: {
        position: "fixed",
        bottom: 10,
        right: 10,
        zIndex: 2000000
      }
    },
    MuiAccordionDetails: {
      root: {
        padding: 5,
      },
    },
    MuiFormControl: {
      root: {
        margin: '10px'
      }
    },
    MuiTreeItem: {
      root: {
        color: 'white'
      }
    },
    MuiDialogContent: {
      root: {
        backgroundColor: 'white',
        '& label': {
          color: 'black'
        },
        '& svg': {
          color: 'black'
        },
        '& div': {
          color: 'black'
        },
        '& P': {
          color: 'black'
        },
      }
    },
  }
});


class App extends React.Component {

  render() {

    Usuarios.validaToken().then(isOk => {
      if (!isOk) {
        localStorage.removeItem('userLogged');
      }
    });

    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <PrivateRoute exact path="/" component={Main}></PrivateRoute>

            {/* usuarios */}
            <Route path="/login" component={Login}></Route>
            <Route path="/registro" component={Registro}></Route>
            <PrivateRoute path="/editarusuario" component={EditUser}></PrivateRoute>

            {/* simulaciones */}
            <PrivateRoute path="/misplantillas" component={MisPlantillas}></PrivateRoute>
            <PrivateRoute path="/nuevaplantilla" component={NuevaPlantilla}></PrivateRoute>

            {/* moms */}
            <PrivateRoute path="/moms" component={Moms}></PrivateRoute>
            <PrivateRoute path="/mom/:id" component={MomFlow} ></PrivateRoute>


            <PrivateRoute path="/editor/:id" component={Editor} ></PrivateRoute>
            <PrivateRoute path="/editorreactflow/:id" component={EditorReactFlow} ></PrivateRoute>
            <PrivateRoute path="/editordemundo" component={EditorWorld} ></PrivateRoute>



            <PrivateRoute component={Main} />
          </Switch>
        </BrowserRouter >
      </ThemeProvider>
    );
  }
}


export default App;