import { BINARY_EVENT } from "socket.io-parser";

export class Constantes {
    // static API_URL = "http://192.168.0.108:3000/api/";
    // static SOCKET_URI = "http://192.168.0.108:3000/";
    // static DOCUMENTOS_URI = "http://192.168.0.108:3000/";

    static API_URL = "http://core.groobi.com/api/";
    static SOCKET_URI = "http://core.groobi.com/";
    static DOCUMENTOS_URI = "http://core.groobi.com/";

    static getAuthHeaders() {

        let token = '';

        if (localStorage.getItem('userLogged')
            && JSON.parse(localStorage.getItem('userLogged')).token) {
            token = JSON.parse(localStorage.getItem('userLogged')).token;
        }
        else {
            token = 'no-token';
        }

        return {
            "Authorization": "Bearer " + token,
            'Content-Type': 'application/json'
        }
    };
    
    static getAuthHeadersUpload() {

        let token = '';

        if (localStorage.getItem('userLogged')
            && JSON.parse(localStorage.getItem('userLogged')).token) {
            token = JSON.parse(localStorage.getItem('userLogged')).token;
        }
        else {
            token = 'no-token';
        }

        return {
            "Authorization": "Bearer " + token,
            "enctype": "multipart/form-data"
        }
    };

    static enVozAlta(texto) {
        //window.responsiveVoice.speak(texto, "Spanish Female");
    }

    static getUserLogged() {
        if (localStorage.getItem('userLogged')
            && JSON.parse(localStorage.getItem('userLogged')).token) {
            return JSON.parse(localStorage.getItem('userLogged'));
        }
        else {
            return false;
        }
    }
}


/*
chrome.exe --user-data-dir="C:/Chrome dev session" --disable-web-security
*/





