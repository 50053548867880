import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { Container, IconButton, TextField } from '@material-ui/core';
import { Dialog, DialogContent } from '@material-ui/core';
import { AppBar, Tabs, Tab } from '@material-ui/core';

// vista para elementos de slides
import { SlideViewWorld } from '../slide/slideViewWorld';
import { SlideViewWorldEdit } from '../slide/slideViewWorldEdit';


// utilidades
import { ConfirmDialog } from '../../component/utils';
import { World } from '../../entity/world';
import { PreguntaViewWorldEdit } from './preguntaViewWorldEdit';
import { RespuestaPreguntaViewWorldEdit } from './respuestaPreguntaViewWorldEdit';


export function CuestionarioViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // ref para el input de tipo file
  const hiddenFileInput = React.createRef();

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  // inicializamos el hook de elementShow
  const [elementShow, setElementShow] = useState({});

  // tab a mostrar
  const [tabIndex, setTabIndex] = useState(0);


  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);


  // render para los objetos de edición
  const renderElementShowEdit = (element) => {

    // slide
    if (element.className === 'Slide') {
      if (element.animacion !== undefined) {
        element.animacion = element.animacion._id;
      }

      return <Dialog open={elementShow.id !== undefined} >
        <DialogContent>
          <SlideViewWorldEdit item={elementShow} animaciones={props.animaciones} save={(data) => saveSlide(data)} cancel={() => setElementShow()} />
        </DialogContent>
      </Dialog>;
    }

    // pregunta
    if (element.className === 'Pregunta') {
      return <Dialog open={true} >
        <DialogContent>
          <PreguntaViewWorldEdit item={element}
            save={(data) => {
              let preguntas = [...state.preguntas];

              preguntas.forEach((pregunta, index) => {
                if (pregunta.orden === element.orden && pregunta.descripcion === element.descripcion) {
                  preguntas[index].orden = data.orden;
                  preguntas[index].descripcion = data.descripcion;
                  preguntas[index].respuestas = data.respuestas;
                  setState({ ...state, "preguntas": preguntas });

                  setElementShow();
                }
              });
            }} cancel={() => setElementShow()} />
        </DialogContent>
      </Dialog>;
    }

    // respuesta
    if (element.className === 'Respuesta') {
      return <Dialog open={true} >
        <DialogContent>
          <RespuestaPreguntaViewWorldEdit item={element}
            save={(data) => {
              let preguntas = [...state.preguntas];
              let index = preguntas.indexOf(element.preguntaPadre);

              if (index >= 0) {

                // buscamos las respuestas
                let respuestas = [...preguntas[index].respuestas];

                respuestas.forEach((respuesta, indexRespuesta) => {
                  if (respuesta.orden === element.orden && respuesta.descripcion === element.descripcion) {
                    respuestas[indexRespuesta].orden = data.orden;
                    respuestas[indexRespuesta].descripcion = data.descripcion;
                  }
                });

                preguntas[index].respuestas = respuestas;

                setState({ ...state, "preguntas": preguntas });

                setElementShow();
              }
            }}
            skills={props.skills}
            cancel={() => setElementShow()} />
        </DialogContent>
      </Dialog>;
    }

  }


  // funcion para guardar el slide
  const saveSlide = (element) => {
    let data = new FormData();
    data.append('targetid', state._id);

    if (element) {
      data.append('id', element.id);
      data.append('descripcion', element.descripcion.length === 0 ? " " : element.descripcion);
      data.append('orden', element.orden);
      data.append('link', element.link.length === 0 ? " " : element.link);
      data.append('enviarImagen', element.enviarImagen ? element.enviarImagen : false);
      data.append('animacion', element.animacion);

      // si hemos indicado una nueva imagen, la enviamos
      if (element.imagenData) {
        data.append('fichero', element.imagenData);
      }

    }

    World.addSlideCuestionario(data).then(() => {
      World.getCuestionarios()
        .then((data) => {
          if (data.records) {
            let cuestionarios = data.records.filter(x => x._id.toString() === state._id.toString());

            console.log(cuestionarios);
            if (cuestionarios.length > 0) {
              setState({ ...state, 'slides': cuestionarios[0].slides });
            }

            setElementShow();
          }
        });
    });

  };

  const deleteSlide = (id) => {
    /*
    Simulacion.deleteSlideTarea({ targetid: state._id, id: id }).then((respuesta) => {
      if (respuesta) {
        props.onCargar();
      }
    });
    */
  }


  // funcion para guardar la pregunta
  const savePregunta = (element) => {
    if (element === undefined) {
      let preguntas = [...state.preguntas];

      // buscamos el siguiente en orden
      let ultimoOrden = 1;
      if (preguntas.length > 0) {
        ultimoOrden = parseInt(preguntas.sort((a, b) => a.orden - b.orden).at(-1).orden) + 1
      }

      preguntas.push({ orden: ultimoOrden, descripcion: "nueva pregunta", respuestas: [{ orden: 1, descripcion: "nueva respuesta" }] });

      setState({ ...state, 'preguntas': preguntas });
    }
  };

  const deletePregunta = (item) => {
    /*
    Simulacion.deleteSlideTarea({ targetid: state._id, id: id }).then((respuesta) => {
      if (respuesta) {
        props.onCargar();
      }
    });
    */
  }


  return (
    <Container maxWidth='md' className='background-forms-secundario'>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box className='background-forms'>

        <AppBar position="static">
          <Tabs value={tabIndex} onChange={(e, value) => setTabIndex(value)} aria-label="simple tabs example">
            <Tab label="General" id={0} />
            <Tab label="Slides" id={1} />
            <Tab label="Preguntas" id={2} />
          </Tabs>
        </AppBar>

        {tabIndex === 0 && (
          <Box>
            <TextField
              margin='dense'
              size='small'
              variant='standard'
              fullWidth
              id="nivel"
              label='Nivel'
              name='nivel'
              type={'number'}

              value={state.nivel}
              onChange={handleInputChange}
            />

            <TextField
              margin='dense'
              size='small'
              variant='standard'
              fullWidth
              id="orden"
              label='Orden'
              name='orden'
              type={'number'}

              value={state.orden}
              onChange={handleInputChange}
            />

            <TextField
              margin='dense'
              size='small'
              variant='standard'
              fullWidth
              id="nombre"
              label='Nombre'
              name='nombre'

              value={state.nombre}
              onChange={handleInputChange}
            />

          </Box>
        )}

        {tabIndex === 1 && (
          <Box>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: '5px' }}
              onClick={() => saveSlide()}
            >
              ADD Slide
            </Button>

            <Box display='flex' flexDirection='column'>
              {state.slides.map((element) => {
                return <SlideViewWorld item={element} setElementShow={() => {
                  if (element.animacion !== undefined) {
                    element.animacion = element.animacion._id;
                  }

                  setElementShow(element);
                }} key={element.id} onDelete={() => deleteSlide(element.id)} />;
              })}
            </Box>
          </Box>
        )}

        {tabIndex === 2 && (
          <Box className='background-list-cards'>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: '5px' }}
              onClick={() => savePregunta()}
            >
              ADD Pregunta
            </Button>

            <Box display='flex' flexDirection='column'>


              {
                state.preguntas.map((pregunta, indexPregunta) =>
                  <Box key={indexPregunta}>

                    <Box m={1} border={'1px solid black'} onClick={() => {
                      let tmp = { ...pregunta };
                      tmp.className = "Pregunta";
                      setElementShow(tmp);
                    }
                    }>
                      <Typography variant="body1" style={{ marginLeft: 10 }}>{`${pregunta.orden} ${pregunta.descripcion}`}</Typography>
                    </Box>

                    {
                      pregunta.respuestas.map((respuesta, indexRespuesta) =>
                        <Box m={1} marginLeft={'30px'} onClick={() => {
                          let tmp = { ...respuesta };
                          tmp.className = "Respuesta";
                          tmp.preguntaPadre = pregunta;
                          setElementShow(tmp);
                        }}>
                          <Typography variant="body2" style={{ marginLeft: 10 }}>{`${respuesta.orden} ${respuesta.descripcion}`}</Typography>
                        </Box>
                      )
                    }

                  </Box>
                )
              }

            </Box>
          </Box>
        )}

        <Box display='flex' justifyContent='flex-end' width={1}>

          {
            props.onDelete ?
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: '5px' }}
                onClick={() => setOpenConfirmationDialog(true)}
              >
                Borrar
              </Button>
              : <div></div>
          }

          <Button
            variant="contained"
            color="default"
            style={{ margin: '5px' }}
            onClick={() => props.cancel()}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => props.save(state)}
          >
            Guardar
          </Button>
        </Box>

      </Box>

      {elementShow ? renderElementShowEdit(elementShow) : ''}

    </Container>

  );
};
