import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

import { Container, IconButton, TextField } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

// utilidades
import { ConfirmDialog } from '../../component/utils';


export function NivelViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // ref para el input de tipo file
  const hiddenFileInput = React.createRef();

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);


  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  return (
    <Container maxWidth='xs'>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box className='background-forms'>


        <TextField
          margin='dense'
          size='small'
          variant='standard'
          fullWidth
          id="nivel"
          label='Nivel'
          name='nivel'
          type={'number'}

          value={state.nivel}
          onChange={handleInputChange}
        />

        <TextField
          margin='dense'
          size='small'
          variant='standard'
          fullWidth
          id="puntosDesde"
          label='Puntos desde'
          name='puntosDesde'
          type={'number'}

          value={state.puntosDesde}
          onChange={handleInputChange}
        />

        <TextField
          margin='dense'
          size='small'
          variant='standard'
          fullWidth
          id="puntosHasta"
          label='Puntos hasta'
          name='puntosHasta'
          type={'number'}

          value={state.puntosHasta}
          onChange={handleInputChange}
        />

        <Box display='flex' justifyContent='flex-end' width={1}>

          {
            props.onDelete ?
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: '5px' }}
                onClick={() => setOpenConfirmationDialog(true)}
              >
                Borrar
              </Button>
              : <div></div>
          }

          <Button
            variant="contained"
            color="default"
            style={{ margin: '5px' }}
            onClick={() => props.cancel()}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => props.save(state)}
          >
            Guardar
          </Button>
        </Box>

      </Box>

    </Container>

  );
};
