import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { Usuarios } from '../../entity/usuarios';


class Login extends React.Component {

  constructor(props) {
    super(props);

    this.state = { usuario: '', clave: '', disable: false, errorLogin: false, errorText: '' }

    this.onLogin = this.onLogin.bind(this);
    this.onChangeUsuario = this.onChangeUsuario.bind(this);
    this.onChangeClave = this.onChangeClave.bind(this);
  }

  onLogin(event) {
    event.preventDefault();

    this.setState({ loading: true, errorLogin: false });

    Usuarios.login(this.state.usuario, this.state.clave)
      .then(
        user => {
          if (user.records) {

            const { from } = this.props.location.state || { from: { pathname: "/main" } };
            this.props.history.push(from);
          }
          else {
            this.setState({ errorText: user.message }, () => {
              this.setState({ errorLogin: true, loading: false });
            })
          }
        }
      );

  }

  onChangeUsuario(event) {
    this.setState({ usuario: event.target.value });
  }

  onChangeClave(event) {
    this.setState({ clave: event.target.value });
  }

  render() {
    const AlertloginFailed = <Alert severity="error">{this.state.errorText}</Alert>;

    return (
      <div>
        <div style={{ height: 80 }}></div>

        <Container maxWidth="xs">

          <Box>

            <form noValidate autoComplete="off">

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="usuario"
                label="Usuario"
                name="usuario"
                autoComplete="usuario"
                autoFocus
                inputProps={{ maxLength: 20 }}

                onChange={this.onChangeUsuario}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="clave"
                label="Clave"
                type="password"
                id="clave"
                autoComplete="current-password"
                inputProps={{ maxLength: 20 }}
                onChange={this.onChangeClave}
              />
              <Button
                fullWidth
                variant="contained"
                disabled={this.state.loading}
                color="primary"
                onClick={this.onLogin}
              >
                Acceder
          </Button>

            </form>

            {this.state.errorLogin === true ? AlertloginFailed : ''}

          </Box>

          <div style={{ height: 10 }}></div>

          <Typography align="center" style={{ color: 'white' }}>
            ¿Aún no tienes una cuenta?,
              <Link href="/registro" mx={1}>Regístrate</Link>
          </Typography>

          <div style={{ height: 20 }}></div>

          <img src="./images/block/groobi_logo.png" width="100%" alt="Groobi" />


        </Container>
      </div>
    );
  }
}

export default Login;