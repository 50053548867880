import React from 'react';
import Container from '@material-ui/core/Container';

import Header from '../../component/header';
import { setLoading } from '../../component/utils';

import { Simulacion } from '../../entity/simulacion';

import ShowTableData from '../../component/showTableData';

// vista para la simulacion (cabecera)
import { SimulacionViewWorld } from '../simulacion/simulacionViewWorld';

class MisPlantillas extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: [],

      headersTable: [
        { nombre: 'imagenAvatar', caption: 'Imagen', tipo: 'imagen', buscar: false },
        { nombre: 'empresaNombre', caption: 'Empresa', tipo: 'texto', buscar: true },
        { nombre: 'nombre', caption: 'Plantilla', tipo: 'texto', buscar: true },
        { nombre: 'tipo', caption: 'Tipo', tipo: 'texto', buscar: true },
        { nombre: 'enProduccion', caption: 'En Produccion', tipo: 'bool', buscar: true },
      ]
    }


    this.onCargar = this.onCargar.bind(this);
  }

  onCargar() {

    this.setState({ loading: true });

    Simulacion.misPlantillas()
      .then(
        data => {
          if (data.records) {
            this.setState({ loading: false, data: data.records });
          }
        }
      );

  }

  componentDidMount() {
    this.onCargar();
  }

  render() {

    return (
      <div className="backgroundJuego">

        <Header title="Plantillas" history={this.props.history}></Header>

        <div style={{ height: 20 }}></div>

        <Container>

          {this.state.loading ? setLoading() : ''}

          <ShowTableData headers={this.state.headersTable} data={this.state.data} onClick={(element) => {
            // window.open("/editorreactflow/" + element._id, "target=_blank");
            // window.location.href = "/editor/" + element._id;
            window.location.href = "/editorreactflow/" + element._id;
          }} />

        </Container>
      </div>
    );
  }
}

export default MisPlantillas;