import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EditIcon from '@material-ui/icons/Edit';
import ExtensionIcon from '@material-ui/icons/Extension';

import { withStyles } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors';

import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { socketCustom } from '../util/socket';

import { Usuarios } from '../entity/usuarios';

import '../css/style.css';

const useStyles = theme => ({
  offset: theme.mixins.toolbar,
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
});

class Header extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      userLogged: JSON.parse(localStorage.getItem('userLogged')),
      registros: this.props.registros,
      openDrawer: false,
      userLoggedInfo: false,
      horaSimulacion: '',
      messageInfo: '',
      messageFromFirebox: [],
      handleNew: this.props.handleNew,
    }

    this.onShowView = this.onShowView.bind(this);

    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);

    this.handleShowuserLoggedInfo = this.handleShowuserLoggedInfo.bind(this);
    this.handleHideuserLoggedInfo = this.handleHideuserLoggedInfo.bind(this);

    this.handleSnackBarClose = this.handleSnackBarClose.bind(this);

    // evento de cambio de hora, para mostrarla
    socketCustom.on('cambioHora', (data) => {
      if (window.innerWidth > 800) {
        this.setState({ horaSimulacion: data.fecha });
      }
      else {
        this.setState({ horaSimulacion: data.hora });
      }
    });

    // evento para mostrar notificaciones
    socketCustom.on('messageInfo', (data) => {
      console.log(data.message);
      if (data.message) {
        this.setState({ messageInfo: data.message });
      }
      else {
        this.setState({ messageInfo: '' });
      }
    });

    /*
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    var firebaseConfig = {
      apiKey: "AIzaSyB-wb8b8TOIOjMPyIKjfbLbSQL7gxRMp8o",
      authDomain: "groobi-proyect.firebaseapp.com",
      databaseURL: "https://groobi-proyect.firebaseio.com",
      projectId: "groobi-proyect",
      storageBucket: "groobi-proyect.appspot.com",
      messagingSenderId: "215689295226",
      appId: "1:215689295226:web:a8803adb1b4a9a7b6152c3",
      measurementId: "G-90VXKWPX41"
    };
    // Initialize Firebase
    window.firebase.initializeApp(firebaseConfig);
    window.firebase.analytics();

    const messaging = window.firebase.messaging();

    messaging.usePublicVapidKey("BCF2jIXYoBLOQS5PSaKHPxGOdPQZYGu5CCQ2v2Z3iS9Kq2LxK_OjgM9Ul0W0QcqVIYMhswmM9-R9iuBnrClHF2w");

    messaging.getToken().then((currentToken) => {
      if (currentToken) {
        console.log('Enviando token al servidor ', currentToken);
        socketCustom.emit('player.tokenpush', currentToken);
      } else {
        // Show permission request.
        console.log('No Instance ID token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });

    messaging.onMessage((payload) => {
      console.log(payload);

      let tmp = this.state.messageFromFirebox;
      tmp.push(payload.notification.title + ' : ' + payload.notification.body);

      this.setState({ messageFromFirebox: tmp });
    });

*/


  }

  componentDidMount() {
  }

  cambiarHora(data) {
    console.log(data);
    if (window.innerWidth > 800) {
      this.setState({ horaSimulacion: data.fecha });
    }
    else {
      this.setState({ horaSimulacion: data.hora });
    }
  }

  handleDrawerOpen = () => {
    this.setState({ openDrawer: true });
  };
  handleDrawerClose = () => {
    this.setState({ openDrawer: false });
  };

  handleShowuserLoggedInfo() {
    this.setState({ userLoggedInfo: true });
  }
  handleHideuserLoggedInfo() {
    this.setState({ userLoggedInfo: false });
  }

  onShowView(view) {
    window.location.href = "/" + view;
  }

  handleSnackBarClose() {
    this.setState({ messageInfo: '' });
  }

  render() {

    const { classes } = this.props;

    const infoDialog =
      (< Dialog aria-labelledby="simple-dialog-title" open={this.state.userLoggedInfo} onClose={this.handleHideuserLoggedInfo}>
        <DialogTitle>Datos del usuario</DialogTitle>
        <List component="nav">

          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <Avatar alt={this.state.userLogged.nombre} src={this.state.userLogged.avatar} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={this.state.userLogged.nombre} />
          </ListItem>

          <ListItem>
            <ListItemIcon><AccessTimeIcon /></ListItemIcon>
            <ListItemText primary={`Usuario desde el ${this.state.userLogged.fecha_creacion}`} />
          </ListItem>

          <ListItem button onClick={this.onShowView.bind(this, 'editarusuario')}>
            <ListItemIcon><EditIcon /></ListItemIcon>
            <ListItemText primary="Editar perfil" />
          </ListItem>

        </List>
      </Dialog >);

    return (
      <div>

        <Snackbar open={this.state.messageInfo.length > 0 ? true : false}
          autoHideDuration={6000}
          onClose={this.handleSnackBarClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          key={this.state.userLoggedInfo}>
          <Alert severity="info">{this.state.messageInfo}</Alert>
        </Snackbar>

        {this.state.userLoggedInfo === true ? infoDialog : ''}

        <AppBar position="fixed" variant="outlined">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpen}
              edge="start">
              <MenuIcon />
            </IconButton>

            <Box width={1}>
              <Typography variant="subtitle1" style={{ color: 'rgb(0, 0, 0)' }}>{'GroobiMaker'}</Typography>
              <Typography variant="caption">{this.props.title}</Typography>
            </Box>

            <Box display='flex' flexDirection='column' width='150px'>
              <Typography variant="caption">{'Reloj Global'}</Typography>
              <Typography variant="caption">{this.state.horaSimulacion}</Typography>
            </Box>

            <IconButton
              onClick={this.handleShowuserLoggedInfo}>
              <Avatar alt={this.state.userLogged.nombre} src={this.state.userLogged.avatar} />
            </IconButton>
          </Toolbar>
          <Box alignContent="center">
          </Box>

        </AppBar>

        <Drawer
          variant="persistent"
          anchor="left"

          className="menuDrawer"
          
          open={this.state.openDrawer}
        >
          <div>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem button key={"inicio"} onClick={this.onShowView.bind(this, 'main')}>
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary={"Inicio"} />
            </ListItem>

            <ListItem button key={"nuevaplantilla"} onClick={this.onShowView.bind(this, 'nuevaplantilla')}>
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary={"Nueva Plantilla"} />
            </ListItem>

            <ListItem button key={"misplantillas"} onClick={this.onShowView.bind(this, 'misplantillas')}>
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary={"Mis Plantillas"} />
            </ListItem>

            <ListItem button key={"editordemundo"} onClick={this.onShowView.bind(this, 'editordemundo')}>
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary={"Editor (Mundo)"} />
            </ListItem>

            <ListItem button key={"moms"} onClick={this.onShowView.bind(this, 'moms')}>
              <ListItemIcon><ExtensionIcon /></ListItemIcon>
              <ListItemText primary={"MOM´s"} />
            </ListItem>

          </List>

          <Divider />

          <List>
            <ListItem button key={"salir"} onClick={() => {
              Usuarios.logout();
              this.onShowView.bind(this, 'login');
            }}>
              <ListItemIcon><ExitToAppIcon /></ListItemIcon>
              <ListItemText primary={"Salir"} />
            </ListItem>
          </List>
        </Drawer>

        <div className={classes.offset}></div>

        {
      this.state.messageFromFirebox.map((element, index) => {
        return <p key={index}>{element}</p>
      })
    }

      </div >
    );
  }

}

export default withStyles(useStyles)(Header);
