import React from 'react';

import { Box, Container, Button } from '@material-ui/core';

import { Typography, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import Header from '../../component/header';
import { setLoading } from '../../component/utils';

import { World } from '../../entity/world';
import { Simulacion } from '../../entity/simulacion';

// vista para elementos de segmento
import { SegmentoViewWorld } from '../segmento/segmentoViewWorld';

// utilidades
import { ConfirmDialog } from '../../component/utils';


class NuevaPlantilla extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      moms: [],

      nombreNuevaPlantilla: '',

      segmentoSeleccionado: '',
      momSeleccionado: '',

      openConfirmationDialog: false,
    }


    this.onCargar = this.onCargar.bind(this);
    this.addPlantilla = this.addPlantilla.bind(this);

  }

  onCargar() {

    this.setState({ loading: true });

    Simulacion.getMoms()
      .then(
        data => {
          if (data.records) {

            let records = data.records.map((element) => {
              if (element.conocimiento) {
                element.conocimientoNombre = element.conocimiento.nombre;
                element.conocimientoColor = element.conocimiento.color;
              }
              return element;
            });

            this.setState({ loading: false, moms: records });
          }
        }
      );


  }

  componentDidMount() {
    this.onCargar();
  }

  addPlantilla() {
    let data = new FormData();
    data.append('nombre', this.state.nombreNuevaPlantilla);
    data.append('mom', this.state.momSeleccionado);

    Simulacion.addPlantilla(data)
      .then((response) => {
        if (response) {
          window.location.href = "/editorreactflow/" + response[0]._id;
        }
      });
  }


  render() {

    return (
      <div className="backgroundJuego">

        <Header title="Nueva plantilla" history={this.props.history}></Header>

        <div style={{ height: 20 }}></div>

        <Container maxWidth="sm">

          <ConfirmDialog
            content={`¿Crear plantila?`}
            open={this.state.openConfirmationDialog}
            setOpen={(value) => this.setState({ openConfirmationDialog: value })}
            onConfirm={() => this.addPlantilla()}
          />


          <Box>
            <Typography align="center" variant="h6">{'1) Indique el nombre para la nueva plantilla'}</Typography>

            <Box display='flex' m={1} p={1}>
              <TextField
                margin='dense'
                size='small'
                variant='standard'
                fullWidth
                id="nombreNuevaPlantilla"
                label='Generar nueva plantilla'
                name='nombreNuevaPlantilla'
                color="primary"

                error={this.state.nombreNuevaPlantilla.length > 0 && this.state.nombreNuevaPlantilla.length < 6}
                helperText={'6 carácteres mínimo'}

                value={this.state.nombreNuevaPlantilla}
                onChange={(e) => this.setState({ nombreNuevaPlantilla: e.target.value })}
              />

            </Box>

            <FormControl fullWidth>
              <InputLabel htmlFor="set-moms">MOM´s</InputLabel>
              <Select
                labelId="set-moms"
                id="moms"
                value={this.state.momSeleccionado}
                onChange={(e) => this.setState({ momSeleccionado: e.target.value })}
              >
                {
                  this.state.moms.map((element) => {
                    return <MenuItem value={element._id} key={element._id}>{`${element.nombre} (${element.segmentoNombre})`}</MenuItem>
                  })
                }
              </Select>
            </FormControl>

            <div style={{ height: 20 }}></div>

            <Box display="flex" justifyContent="center">
              {this.state.loading ? setLoading() : ''}
            </Box>

          </Box>

          <Button
            variant="contained"
            color="primary"
            onClick={() => this.setState({ openConfirmationDialog: true })}
          >
            Crear Plantilla
          </Button>


        </Container>
      </div>
    );
  }
}

export default NuevaPlantilla;