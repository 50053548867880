import React from 'react';

import { Box } from "@material-ui/core";

import Header from '../component/header';

import '../css/style.css';

class Main extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      openDrawer: false
    }

    // liberamos una posible simulación en juego
    localStorage.removeItem('simulacionSeleccionada');

    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
  }

  componentDidMount() {
  }
  1
  handleDrawerOpen = () => {
    this.setState({ openDrawer: true });
  };
  handleDrawerClose = () => {
    this.setState({ openDrawer: false });
  };

  render() {

    return (
      <div className="backgroundJuego">

        <Header title="Inicio" history={this.props.history}></Header>

        <div style={{ height: 40 }}></div>

        <Box display='flex' justifyContent='center'>
          <img src="./images/block/groobi_logo.png" alt="Groobi" />
        </Box>

      </div>
    );
  }

}

export default Main;
