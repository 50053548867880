import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { Container, IconButton, TextField } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core/';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { LinearProgress, Slider } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

// entity para la simulacion
import { Simulacion } from '../../../entity/simulacion';

// vista para elementos de slides
import { SlideViewWorld } from '../../slide/slideViewWorld';
import { SlideViewWorldEdit } from '../../slide/slideViewWorldEdit';

// componente para la gestión de periodos de tiempo
import TranscursoTiempo from '../../../component/transcursoTiempo';

// utilidades
import { ConfirmDialog } from '../../../component/utils';

import { BotMenuItem } from '../../bot/botMenuItem';

import { ContainerSkills } from '../../../component/containerSkills';


export function TareaBuildViewEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // inicializamos el hook de elementShow
  const [elementShow, setElementShow] = useState(undefined);

  // inicializamos el hook de la lista de secciones
  const [seccionesList, setSeccionesList] = useState([]);

  // inicializamos el hook de la lista de situaciones
  const [situacionesList, setSituacionesList] = useState([]);

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  // tab a mostrar
  const [tabIndex, setTabIndex] = useState(0);


  // ref para el input de tipo file
  const hiddenFileInput = React.createRef();

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }


  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  useEffect(() => {
  }, [state.imagenAvatar]);


  // comprobamos si hemos cambiado de registro fases
  useEffect(() => {
    let tmp = props.fases.filter(x => x.id === state.fase);
    if (tmp.length > 0) {
      setSeccionesList(tmp[0].secciones);
    }
    else {
      setSeccionesList([]);
    }
  }, [state.fase]);


  // comprobamos si hemos cambiado de registro seccion
  useEffect(() => {
    let tmpfase = props.fases.filter(x => x.id === state.fase);

    if (tmpfase.length === 0) {
      return;
    }

    let tmpSeccion = tmpfase[0].secciones.filter(x => x.id === state.seccion)[0];

    if (tmpSeccion) {
      setSituacionesList(tmpSeccion.situaciones);
    }

  }, [state.seccion]);


  // funcion para guardar el slide
  const saveSlide = (element) => {
    let data = new FormData();
    data.append('targetid', state._id);

    if (element) {
      data.append('id', element.id);
      data.append('descripcion', element.descripcion.length === 0 ? " " : element.descripcion);
      data.append('orden', element.orden);
      data.append('link', element.link.length === 0 ? " " : element.link);
      data.append('enviarImagen', element.enviarImagen ? element.enviarImagen : false);
      data.append('animacion', element.animacion);

      // si hemos indicado una nueva imagen, la enviamos
      if (element.imagenData) {
        data.append('fichero', element.imagenData);
      }

    }

    Simulacion.addSlideTarea(data).then(() => {
      Simulacion.getTareas(state.simulacionTarget)
        .then((data) => {
          if (data.records) {
            let tareas = data.records.filter(x => x._id.toString() === state._id.toString());

            if (tareas.length > 0) {
              setState({ ...state, 'slides': tareas[0].slides });
            }
          }
        });
    });

  };

  const deleteSlide = (id) => {
    Simulacion.deleteSlideTarea({ targetid: state._id, id: id }).then((respuesta) => {
      if (respuesta) {
        props.onCargar();
      }
    });
  }


  // esta función añade un nuevo skill al item
  const addSkill = element => {
    let tmp = state.skills;

    // si el skill ya está añadido, no lo vuelvo a añadir
    let existe = tmp.filter(x => x.id === element.id).length;

    // si no existe, lo añado
    if (existe === 0) {
      tmp.push(element);
    }

    setState({ ...state, 'skills': tmp });
  }


  return (
    <Container maxWidth='lg' className='background-forms-secundario'>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box>

        {
          elementShow
            ? (<Dialog open={elementShow.id !== undefined} maxWidth='md' fullWidth >
              <DialogContent>
                <SlideViewWorldEdit item={elementShow} animaciones={props.animaciones} save={(data) => saveSlide(data)} cancel={() => setElementShow()} />
              </DialogContent>
            </Dialog>)
            : ''
        }

        <AppBar position="static">
          <Tabs value={tabIndex} onChange={(e, value) => setTabIndex(value)} aria-label="simple tabs example">
            <Tab label="General" id={0} />
            <Tab label="Slides" id={1} />
          </Tabs>
        </AppBar>

        {tabIndex === 0 && (
          <Box>

            <Typography align="center" variant="h5" style={{ margin: '10px' }}>{'Tarea de tipo Build'}</Typography>

            <Box>
              <input ref={hiddenFileInput} accept='image/*' type='file' style={{ display: 'none' }}
                onChange={e => {
                  setState({
                    ...state,
                    imagenData: e.target.files[0],
                    imagenAvatar: URL.createObjectURL(e.target.files[0])
                  });
                  //setState({ ...state, imagenAvatar: URL.createObjectURL(e.target.files[0]) });
                }} />
              <Avatar style={{ marginRight: '10px' }}>
                <IconButton onClick={() => { hiddenFileInput.current.click(); }} >
                  <PhotoCameraIcon />
                </IconButton>
              </Avatar>

              <Box display='flex' justifyContent='center' width={1}>
                <img
                  alt={state.nombre}
                  src={state.imagenAvatar}
                  style={{ margin: '10px', maxWidth: '100%', maxHeight: '200px' }}
                />
              </Box>

            </Box>

            <Container maxWidth='xl'>
              <Box>

                <TextField
                  margin='dense'
                  size='small'
                  variant='standard'
                  fullWidth
                  id="nombre"
                  label='Nombre de tarea'
                  name='nombre'
                  autoComplete='nombre'

                  value={state.nombre}
                  onChange={handleInputChange}
                />

                <FormControl fullWidth>
                  <InputLabel htmlFor="set-ubicacion">Ubicación</InputLabel>
                  <Select
                    labelId="set-ubicacion"
                    id="ubicacion"
                    value={state.ubicacion}
                    onChange={(e) => setState({ ...state, 'ubicacion': e.target.value })}
                  >
                    {
                      props.ubicaciones.map((element) => {
                        return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel htmlFor="set-canalComunicacion">Canal Comunicación</InputLabel>
                  <Select
                    labelId="set-canalComunicacion"
                    id="canalComunicacion"
                    value={state.canalComunicacion}
                    onChange={(e) => setState({ ...state, 'canalComunicacion': e.target.value })}
                  >
                    {
                      props.canalComunicacion.map((element) => {
                        return <MenuItem value={element} key={element}>{element}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>

                <Box display='flex'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.daPasoSeccion}
                        onChange={(e) => {
                          setState({ ...state, 'daPasoSeccion': !state.daPasoSeccion });
                        }}
                        name={"daPasoSeccion"}
                        color="primary"
                      />
                    }
                    label="Da paso a la siguiente sección"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.ejecucionDirecta}
                        onChange={(e) => {
                          setState({ ...state, 'ejecucionDirecta': !state.ejecucionDirecta });
                        }}
                        name={"ejecucionDirecta"}
                        color="primary"
                      />
                    }
                    label="Ejecución directa"
                  />

                  <TextField
                    margin='dense'
                    size='small'
                    variant='standard'
                    id="orden"
                    label='Orden'
                    name='orden'
                    type={'number'}

                    value={state.orden}
                    onChange={handleInputChange}
                  />


                </Box>

                <Box display='flex'>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="set-fase">Fase</InputLabel>
                    <Select
                      labelId="set-fase"
                      id="fase"
                      value={state.fase}
                      onChange={(e) => {
                        setState({ ...state, 'fase': e.target.value });
                      }}
                    >
                      <MenuItem value={{ orden: 0, nombre: '' }} key={'no-selecciondo-fase'}>{'SIN SELECCIONAR'}</MenuItem>
                      {
                        props.fases.map((element, index) => {
                          return <MenuItem value={element.id} key={index}>{`${element.orden} ${element.nombre}`}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel htmlFor="set-seccion">Seccion</InputLabel>
                    <Select
                      labelId="set-seccion"
                      id="seccion"
                      value={state.seccion}
                      onChange={(e) => setState({ ...state, 'seccion': e.target.value })}
                    >
                      <MenuItem value={{ orden: 0, nombre: '' }} key={'no-selecciondo-seccion'}>{'SIN SELECCIONAR'}</MenuItem>
                      {
                        seccionesList.map((element, index) => {
                          return <MenuItem value={element.id} key={index}>{`${element.orden} ${element.nombre}`}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel htmlFor="set-situacion">Situacion</InputLabel>
                    <Select
                      labelId="set-situacion"
                      id="situacion"
                      value={state.situacion}
                      onChange={(e) => setState({ ...state, 'situacion': e.target.value })}
                    >
                      {
                        situacionesList.map((element, index) => {
                          return <MenuItem value={element.id} key={index}>{element.nombre}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>

                </Box>

                <FormControl fullWidth>
                  <InputLabel htmlFor="set-bot">Bot</InputLabel>
                  <Select
                    labelId="set-bot"
                    id="bot"
                    value={state.bot}
                    onChange={(e) => setState({ ...state, 'bot': e.target.value })}
                  >
                    {
                      props.bots.map((element) => {
                        return <MenuItem value={element._id} key={element._id}><BotMenuItem item={element} /></MenuItem>
                      })
                    }
                  </Select>
                </FormControl>

                <Box display='flex' justifyContent='center'>
                  <TranscursoTiempo titulo={'Momento Activación'} item={state.momentoActivacion} onChange={(element) => setState({ ...state, 'momentoActivacion': element })} />
                  <TranscursoTiempo titulo={'Duración Tarea'} item={state.duracionTarea} onChange={(element) => setState({ ...state, 'duracionTarea': element })} />
                </Box>


              </Box>
            </Container>

          </Box>
        )}

        {tabIndex === 1 && (
          <Box display='flex' flexDirection='column'>
            <Typography align="center" variant="button">{'Slides'}</Typography>
            {state.slides.map((element) => {
              return <SlideViewWorld item={element} setElementShow={() => {
                if (element.animacion !== undefined) {
                  element.animacion = element.animacion._id;
                }

                setElementShow(element);
              }} key={element.id} onDelete={() => deleteSlide(element.id)} />;
            })}
          </Box>
        )}

      </Box>

      <div style={{ height: 20 }}></div>

      <Box display='flex' justifyContent='flex-end' width={1}>

        {
          props.onDelete ?
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: '5px' }}
              onClick={() => setOpenConfirmationDialog(true)}
            >
              Borrar
            </Button>
            : <div></div>
        }

        <Button
          variant="contained"
          color="primary"
          style={{ margin: '5px' }}
          onClick={() => saveSlide()}
        >
          ADD Slide
        </Button>

        <Button
          variant="contained"
          color="default"
          style={{ margin: '5px' }}
          onClick={() => props.cancel()}
        >
          Cancelar
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{ margin: '5px' }}
          onClick={() => {
            props.save(state);
            props.cancel();
          }}

        >
          Guardar
        </Button>
      </Box>


      <Box display='flex'>
        {
          state.skills && state.skills.length > 0
            ? (
              <Box className='background-list-cards' m={1} p={1} >
                {
                  state.skills.map((element, index) => {
                    return <Box m={1} key={element.id + element.nombre} display='flex' alignItems='center'>

                      <Avatar
                        src={element.imagenAvatar}
                        alt={element.nombre}
                        title={element.nombre} />

                      <TextField
                        size='small'
                        variant='standard'
                        fullWidth
                        id="nivel"
                        label={element.nombre}
                        name='nivel'
                        type='number'
                        style={{ margin: 10 }}

                        value={element.valor}
                        onChange={(event) => {
                          let tmpSkill = state.skills;
                          tmpSkill[index].valor = event.target.value;
                          setState({ ...state, 'skills': tmpSkill });
                        }}
                      />

                      <IconButton onClick={() => {
                        let tmpSkill = state.skills;
                        tmpSkill.splice(index, 1);
                        setState({ ...state, 'skills': tmpSkill });
                      }} >
                        <DeleteIcon />
                      </IconButton>

                    </Box>
                  }
                  )
                }
              </Box>
            )
            : ''
        }

        <ContainerSkills skills={props.skills} addSkill={addSkill} />

      </Box>


    </Container>

  );
};