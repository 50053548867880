import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import { Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';

import { Avatar, Slider, LinearProgress } from '@material-ui/core';
import { IconButton } from '@material-ui/core/';

import DeleteIcon from '@material-ui/icons/Delete';


import { ContainerSkills } from '../../../component/containerSkills';


export function RespuestaViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // ref para el input de tipo file
  const hiddenFileInput = React.createRef();

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // función que controla los cambios en los checkbox
  const handleInputChangeChecked = e => {
    setState({ ...state, [e.target.name]: e.target.checked })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  // esta función añade un nuevo skill al item
  const addSkill = element => {
    let tmp = state.skills;

    // si el skill ya está añadido, no lo vuelvo a añadir
    let existe = tmp.filter(x => x.id === element.id).length;

    // si no existe, lo añado
    if (existe === 0) {
      tmp.push(element);
    }

    setState({ ...state, 'skills': tmp });
  }


  return (
    <Box className='background-forms' display='flex'>

      <Box width='50%' m={1}>

        <TextField
          margin='dense'
          size='small'
          variant='standard'
          fullWidth
          id="nombre"
          label='Texto abreviado'
          name='nombre'
          autoComplete='nombre'

          value={state.nombre}
          onChange={handleInputChange}
        />

        <TextField
          margin='dense'
          size='small'
          variant='standard'
          id="orden"
          label='Orden'
          name='orden'
          autoComplete='orden'
          type={'number'}
          
          InputProps={{ inputProps: { min: 0 } }}

          value={state.orden}
          onChange={handleInputChange}
        />

        <TextField
          margin='dense'
          size='small'
          variant='standard'
          fullWidth
          id="descripcion"
          label='Texto a mostrar'
          name='descripcion'

          multiline
          rows={5}

          value={state.descripcion}
          onChange={handleInputChange}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={state.finalizarTarea}
              onChange={handleInputChangeChecked}
              name="finalizarTarea"
              color="primary"
            />
          }
          label="Finalizar tarea con esta respuesta"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={state.correcta}
              onChange={handleInputChangeChecked}
              name="correcta"
              color="primary"
            />
          }
          label="Esta es la respuesta correcta"
        />

        <TextField
          margin='dense'
          size='small'
          variant='standard'
          fullWidth
          id="mensajeCorrecto"
          label='Mensaje a mostrar si es correcto'
          name='mensajeCorrecto'
          autoComplete='mensajeCorrecto'

          value={state.mensajeCorrecto}
          onChange={handleInputChange}
        />

        <TextField
          margin='dense'
          size='small'
          variant='standard'
          fullWidth
          id="mensajeIncorrecto"
          label='Mensaje a mostrar si es incorrecto'
          name='mensajeIncorrecto'
          autoComplete='mensajeIncorrecto'

          value={state.mensajeIncorrecto}
          onChange={handleInputChange}
        />

        <FormControl fullWidth>
          <InputLabel htmlFor="set-animacion">Animación</InputLabel>
          <Select
            labelId="set-animacion"
            id="animacion"
            value={state.animacion}
            onChange={(e) => setState({ ...state, 'animacion': e.target.value })}
          >
            {
              props.animaciones.map((element) => {
                return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
              })
            }
          </Select>
        </FormControl>

        <div style={{ height: 20 }}></div>

        <Box display='flex' justifyContent='flex-end' width={1}>

          <Button
            variant="contained"
            color="default"
            style={{ margin: '5px' }}
            onClick={() => props.cancel()}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => props.save(state)}
          >
            Guardar
          </Button>
        </Box>

      </Box>

      <Box width='50%' m={1}>

        <Box display='flex'>
          {
            state.skills && state.skills.length > 0
              ? (
                <Box className='background-list-cards' m={1} p={1} >
                  {
                    state.skills.map((element, index) => {
                      return <Box m={1} key={element.id + element.nombre} display='flex' alignItems='center'>

                        <Avatar
                          src={element.imagenAvatar}
                          alt={element.nombre}
                          title={element.nombre} />

                        <TextField
                          size='small'
                          variant='standard'
                          fullWidth
                          id="nivel"
                          label={element.nombre}
                          name='nivel'
                          type='number'
                          style={{ margin: 10 }}

                          value={element.valor}
                          onChange={(event) => {
                            let tmpSkill = state.skills;
                            tmpSkill[index].valor = event.target.value;
                            setState({ ...state, 'skills': tmpSkill });
                          }}
                        />

                        <IconButton onClick={() => {
                          let tmpSkill = state.skills;
                          tmpSkill.splice(index, 1);
                          setState({ ...state, 'skills': tmpSkill });
                        }} >
                          <DeleteIcon />
                        </IconButton>

                      </Box>
                    }
                    )
                  }
                </Box>
              )
              : ''
          }

          <ContainerSkills skills={props.skills} addSkill={addSkill} />

        </Box>

      </Box>

    </Box>

  );
};