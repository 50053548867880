import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { FormControl, InputLabel, Select, MenuItem, TextField, IconButton } from '@material-ui/core/';

import AutorenewIcon from '@material-ui/icons/Autorenew';


class TranscursoTiempo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      titulo: this.props.titulo ? this.props.titulo : "Definir transcurso",
    }


  }

  render() {

    return (
      <Box className='background-list-cards' p={1}>

        <Typography align="center" variant="button">{this.state.titulo}</Typography>

        <Box display='flex'>

          <IconButton
            title={'valor aleatorio'}
            onClick={() => {
              let valor = Math.ceil(Math.random() * 100);
              this.props.onChange({ valor: valor, tipo: this.props.item.tipo })
            }
            } >
            <AutorenewIcon />
          </IconButton>

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            label='Valor'
            type='number'

            value={this.props.item.valor}
            onChange={(e) => this.props.onChange({ valor: e.target.value, tipo: this.props.item.tipo })}
          />

          <FormControl fullWidth margin='dense' size='small'>
            <InputLabel htmlFor="set-tipo">Tipo</InputLabel>
            <Select
              labelId="set-tipo"
              id="tipo"
              value={this.props.item.tipo}
              onChange={(e) => this.props.onChange({ valor: this.props.item.valor, tipo: e.target.value })}
            >
              <MenuItem value={'s'} key={'s'}>{'Segundos'}</MenuItem>
              <MenuItem value={'m'} key={'m'}>{'Minutos'}</MenuItem>
              <MenuItem value={'h'} key={'h'}>{'Horas'}</MenuItem>
              <MenuItem value={'d'} key={'d'}>{'Dias'}</MenuItem>
              <MenuItem value={'M'} key={'M'}>{'Meses'}</MenuItem>
            </Select>
          </FormControl>

        </Box>
      </Box>);
  }

}

export default TranscursoTiempo;
