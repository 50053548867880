import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Box, Button } from '@material-ui/core';
import { Simulacion } from '../../entity/simulacion';
import { World } from '../../entity/world';



class EditorToolsWorld extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      expanded: 'simulacion',
    }

    this.accordeonExpandedChange = this.accordeonExpandedChange.bind(this);
  }


  accordeonExpandedChange(panel) {
    this.setState({ expanded: panel });
  }



  render() {
    return (
      <div>

        <Accordion square
          expanded={this.state.expanded === 'empresas'}
          onChange={() => this.accordeonExpandedChange('empresas')}>

          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Empresas</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
              <Button variant="outlined" style={{ margin: '3px' }} onClick={() => {
                World.addEmpresa().then(() => this.props.onCargar('empresas'));
              }}>Add</Button>
            </Box>
          </AccordionDetails>

        </Accordion>

        <Accordion square
          expanded={this.state.expanded === 'logros'}
          onChange={() => this.accordeonExpandedChange('logros')}>

          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Logros</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
              <Button variant="outlined" style={{ margin: '3px' }} onClick={() => {
                World.addLogro().then(() => this.props.onCargar('logros'));
              }}>Add</Button>
            </Box>
          </AccordionDetails>

        </Accordion>

        <Accordion square
          expanded={this.state.expanded === 'segmentos'}
          onChange={() => this.accordeonExpandedChange('segmentos')}>

          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Segmentos</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
              <Button variant="outlined" style={{ margin: '3px' }} onClick={() => {
                World.addSegmento().then(() => this.props.onCargar('segmentos'));
              }}>Add</Button>
            </Box>
          </AccordionDetails>

        </Accordion>

        <Accordion square
          expanded={this.state.expanded === 'zonas'}
          onChange={() => this.accordeonExpandedChange('zonas')}>

          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Zonas</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
              <Button variant="outlined" style={{ margin: '3px' }} onClick={() => {
                World.addZona().then(() => this.props.onCargar('zonas'));
              }}>Add</Button>
            </Box>
          </AccordionDetails>

        </Accordion>

        <Accordion square
          expanded={this.state.expanded === 'ubicaciones'}
          onChange={() => this.accordeonExpandedChange('ubicaciones')}>

          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Ubicaciones</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
              <Button variant="outlined" style={{ margin: '3px' }} onClick={() => {
                World.addUbicacion().then(() => this.props.onCargar('ubicaciones'));
              }}>Add</Button>
            </Box>
          </AccordionDetails>

        </Accordion>

        <Accordion square
          expanded={this.state.expanded === 'players'}
          onChange={() => this.accordeonExpandedChange('players')}>

          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Bots (Plantillas)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
              <Button variant="outlined" style={{ margin: '3px' }} onClick={() => {
                World.addPlayer().then(() => this.props.onCargar('players'));
              }}>Add</Button>
            </Box>
          </AccordionDetails>

        </Accordion>

        <Accordion square
          expanded={this.state.expanded === 'skills'}
          onChange={() => this.accordeonExpandedChange('skills')}>

          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Skills</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
              <Button variant="outlined" style={{ margin: '3px' }} onClick={() => {
                World.addSkill().then(() => this.props.onCargar('skills'));
              }}>Add</Button>
            </Box>
          </AccordionDetails>

        </Accordion>

        <Accordion square
          expanded={this.state.expanded === 'itemtipo'}
          onChange={() => this.accordeonExpandedChange('itemtipo')}>

          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Tipos de Item</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
              <Button variant="outlined" style={{ margin: '3px' }} onClick={() => {
                World.addItemTipo().then(() => this.props.onCargar('itemtipo'));
              }}>Add</Button>

            </Box>
          </AccordionDetails>

        </Accordion>

        <Accordion square
          expanded={this.state.expanded === 'items'}
          onChange={() => this.accordeonExpandedChange('items')}>

          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Items</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
              <Button variant="outlined" style={{ margin: '3px' }} onClick={() => {
                World.addItem().then(() => this.props.onCargar('items'));
              }}>Add</Button>

            </Box>
          </AccordionDetails>

        </Accordion>

        <Accordion square
          expanded={this.state.expanded === 'animaciones'}
          onChange={() => this.accordeonExpandedChange('animaciones')}>

          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Animaciones</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
              <Button variant="outlined" style={{ margin: '3px' }} onClick={() => {
                World.addAnimacion().then(() => this.props.onCargar('animaciones'));
              }}>Add</Button>

            </Box>
          </AccordionDetails>

        </Accordion>

        <Accordion square
          expanded={this.state.expanded === 'niveles'}
          onChange={() => this.accordeonExpandedChange('niveles')}>

          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Niveles</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
              <Button variant="outlined" style={{ margin: '3px' }} onClick={() => {
                World.addNivel().then(() => this.props.onCargar('niveles'));
              }}>Add</Button>

            </Box>
          </AccordionDetails>

        </Accordion>

        <Accordion square
          expanded={this.state.expanded === 'cuestionarios'}
          onChange={() => this.accordeonExpandedChange('cuestionarios')}>

          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Cuestionarios</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
              <Button variant="outlined" style={{ margin: '3px' }} onClick={() => {
                World.addCuestionario().then(() => this.props.onCargar('cuestionarios'));
              }}>Add</Button>

            </Box>
          </AccordionDetails>

        </Accordion>


        <Accordion square
          expanded={this.state.expanded === 'tareas'}
          onChange={() => this.accordeonExpandedChange('tareas')}>

          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Tareas</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexWrap='wrap' justifyContent='center'>
              {
                this.props.tipostareas.map((element) => {

                  return <Button variant="outlined" fullWidth style={{ margin: '3px', fontSize: '12px' }} key={element} onClick={() => {

                    let data = new FormData();
                    data.append('targetid', this.state._id);
                    data.append('tipo', element);

                    World.addTarea(data).then((data) => {
                      this.props.onCargar('tareas', data[0]._id);
                    });
                  }}>{element}</Button>
                })
              }
            </Box>
          </AccordionDetails>

        </Accordion>

        <Accordion square
          expanded={this.state.expanded === 'botsjsonskin'}
          onChange={() => this.accordeonExpandedChange('botsjsonskin')}>

          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>BotsJsonSkin</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
              <Button variant="outlined" style={{ margin: '3px' }} onClick={() => {
                World.addBotsJsonSkin().then(() => this.props.onCargar('botsJsonSkin'));
              }}>Add</Button>
            </Box>
          </AccordionDetails>

        </Accordion>


      </div >

    );
  }
}

export default EditorToolsWorld;