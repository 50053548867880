import React from 'react';

import { Container } from '@material-ui/core';

import Header from '../../component/header';
import { setLoading } from '../../component/utils';

import { Simulacion } from '../../entity/simulacion';

import ShowTableData from '../../component/showTableData';


class Moms extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: [],

      headersTable: [
        { nombre: 'nombre', caption: 'MOM', tipo: 'texto', buscar: true },
        { nombre: 'publico', caption: 'Publico', tipo: 'bool', buscar: true },
        { nombre: 'conocimientoNombre', caption: 'Conocimiento', tipo: 'texto', buscar: true },
        { nombre: 'segmentoNombre', caption: 'Segmento', tipo: 'texto', buscar: true },
        { nombre: 'tags', caption: 'Tags', tipo: 'array', buscar: true }
      ]
    }


    this.onCargar = this.onCargar.bind(this);

    this.addNewMom = this.addNewMom.bind(this);
  }

  onCargar() {

    this.setState({ loading: true });    

    Simulacion.getMoms()
      .then(
        data => {
          if (data.records) {

            let records = data.records.map((element) => {
              if (element.conocimiento) {
                element.conocimientoNombre = element.conocimiento.nombre;
                element.conocimientoColor = element.conocimiento.color;
              }
              return element;
            });

            this.setState({ loading: false, data: records });
          }
        }
      );

  }

  addNewMom() {
    this.setState({ loading: true });

    Simulacion.addMom()
      .then(
        data => {
          this.setState({ loading: false }, () => window.location.href = "/mom/" + data._id);
        }
      );
  }

  componentDidMount() {
    this.onCargar();
  }

  render() {

    return (
      <div className="backgroundJuego">

        <Header title="MOM´s" history={this.props.history}></Header>

        <div style={{ height: 20 }}></div>

        <Container maxWidth='md'>

          {this.state.loading ? setLoading() : ''}

          <ShowTableData
            headers={this.state.headersTable}
            data={this.state.data}
            onClick={(element) => window.location.href = "/mom/" + element._id}
            onNuevo={this.addNewMom}
          />

        </Container>
      </div>
    );
  }
}

export default Moms;