import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Container, TextField } from '@material-ui/core';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { ConfirmDialog } from '../../component/utils';

import TranscursoTiempo from '../../component/transcursoTiempo';


export function SimulacionReactFlowSeccionEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  return (
    <Container maxWidth={'sm'}>

      <Typography variant='h5' align='center'>Seccion</Typography>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <TextField
        margin='dense'
        size='small'
        variant='standard'
        fullWidth
        id="nombre"
        label='Nombre'
        name='nombre'

        value={state.nombre}
        onChange={handleInputChange}
      />

      <TextField
        margin='dense'
        size='small'
        variant='standard'
        id="orden"
        label='Orden'
        name='orden'
        type={'number'}

        value={state.orden}
        onChange={handleInputChange}
      />

      <div style={{ height: 20 }}></div>

      <Typography>{`Situaciones -> ${state.situaciones.length}`}</Typography>

      <div style={{ height: 20 }}></div>

      <Container maxWidth='md'>
        <Box display='flex' flexDirection='column' className='background-list-cards'>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.activa}
                onChange={(e) => {
                  setState({ ...state, 'activa': e.target.checked });
                }}
                name={"activa"}
                color="primary"
              />
            }
            label="Sección activa"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={state.tareasGlobales}
                onChange={(e) => {
                  setState({ ...state, 'tareasGlobales': e.target.checked });
                }}
                name={"tateasglobales"}
                color="primary"
              />
            }
            label="Tareas Globales"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={state.pasoSeccionAutomatico}
                onChange={(e) => {
                  setState({ ...state, 'pasoSeccionAutomatico': e.target.checked });
                }}
                name={"pasoSeccionAutomatico"}
                color="primary"
              />
            }
            label="Paso sección automático (todas las Situaciones)"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={state.seccionRandom}
                onChange={(e) => {
                  setState({ ...state, 'seccionRandom': e.target.checked });
                }}
                name={"seccionRandom"}
                color="primary"
              />
            }
            label="Sección con Situaciones Random"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={state.pasoSeccionPorID}
                onChange={(e) => {
                  setState({ ...state, 'pasoSeccionPorID': e.target.checked });
                }}
                name={"pasoSeccionPorID"}
                color="primary"
              />
            }
            label="Paso sección por Tarea"
          />

          <Box width='350px'>

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.pasoSeccionPorTiempo}
                  onChange={(e) => {
                    setState({ ...state, 'pasoSeccionPorTiempo': e.target.checked });
                  }}
                  name={"pasoSeccionPorTiempo"}
                  color="primary"
                />
              }
              label="Paso sección por tiempo"
            />

            {
              state.pasoSeccionPorTiempo
                ? (<TranscursoTiempo

                  titulo={'Duración Sección'}
                  item={state.pasoSeccionTiempo}
                  onChange={(element) => {
                    setState({ ...state, 'pasoSeccionTiempo': element });
                  }}
                />)
                : <div></div>


            }

          </Box>
        </Box>
      </Container>

      <div style={{ height: 20 }}></div>

      <Box display='flex' justifyContent='flex-end' width={1}>

        {
          props.onDelete ?
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: '5px' }}
              onClick={() => setOpenConfirmationDialog(true)}
            >
              Borrar
            </Button>
            : <div></div>
        }

        <Button
          variant="contained"
          color="default"
          style={{ margin: '5px' }}
          onClick={() => props.cancel()}
        >
          Cancelar
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{ margin: '5px' }}
          onClick={() => props.save(state)}
        >
          Guardar
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{ margin: '5px' }}
          onClick={() => {

            let situaciones = [...state.situaciones];

            situaciones.push({ orden: (parseInt(situaciones.length) + 1), nombre: 'nueva situacion' });


            setState({ ...state, "situaciones": situaciones });
          }}
        >
          Añadir Situación
        </Button>


      </Box>

    </Container>

  );
};