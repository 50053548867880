import React, { useState } from 'react';

import { Box, Typography, Button } from '@material-ui/core';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

export function setLoading() {
  return <Dialog open={true} >
    <DialogContent>
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection='column' margin={2}>
        <Typography align="center" style={{ margin: 10 }}>Loading...</Typography>
        <CircularProgress color='secondary' />
      </Box>
    </DialogContent>
  </Dialog>
}

export function ConfirmDialog(props) {
  const { title, content, open, setOpen, onConfirm } = props;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogContent>
        <DialogTitle id="confirm-dialog">{title ? title : 'Confirmación'}</DialogTitle>
        <Typography align="center">{content ? content : '¿Está seguro de dar este paso?'}</Typography>

        <div style={{ height: 10 }}></div>

        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
            color="primary"
          >
            Confirmar
          </Button>
        </DialogActions>
      </DialogContent>

    </Dialog>
  );
};

export function InfoDialog(props) {
  const { title, content, open, onClose } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title ? title : 'Información'}</DialogTitle>
      <DialogContent>
        <Typography align="center" gutterBottom>{content ? content : 'Información'}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
          color="secondary"
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};



export const ColoresPaletaAzul = {
  ColorBg1: "#0A303B",
  ColorBg2: "rgb(190 216 223)",
  ColorBg3: "#15647A",
  ColorBg4: "#176F87",
  ColorBg5: "#22A3C7",

  ColorFont1: "white",
  ColorFont2: "white",
  ColorFont3: "white",
  ColorFont4: "white",
  ColorFont5: "black",

  TareaInteraccion: "rgb(153 165 44)",
  TareaBuild: "#c73891",
  TareaRandom: "#2196f3",
};