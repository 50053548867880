import React, { useState } from 'react';

import { Box, } from '@material-ui/core';
import { Button, } from '@material-ui/core';

import { Container, TextField } from '@material-ui/core';

import { FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core';


// utilidades
import { ConfirmDialog } from '../../component/utils';


export function SegmentoViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }


  return (
    <Container maxWidth='lg'>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box className='background-forms'>

        <TextField
          margin='dense'
          size='small'
          variant='standard'
          fullWidth
          id="nombre"
          label='Nombre'
          name='nombre'
          autoComplete='nombre'

          value={state.nombre}
          onChange={handleInputChange}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={state.publico}
              onChange={(e) => setState({ ...state, "publico": e.target.checked })}
              name="publico"
              color="primary"
            />
          }
          label="Segmento público"
        />

        <FormControl fullWidth>
          <InputLabel htmlFor="set-cuestionario">Cuestionario</InputLabel>
          <Select
            labelId="set-cuestionario"
            id="cuestionario"
            value={state.cuestionario}
            onChange={(e) => setState({ ...state, 'cuestionario': e.target.value })}
          >
            {
              props.cuestionarios.map((element) => {
                return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
              })
            }
          </Select>
        </FormControl>

        <div style={{ height: 20 }}></div>

        <Box display='flex' justifyContent='flex-end' width={1}>

          {
            props.onDelete ?
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: '5px' }}
                onClick={() => setOpenConfirmationDialog(true)}
              >
                Borrar
              </Button>
              : <div></div>
          }

          <Button
            variant="contained"
            color="default"
            style={{ margin: '5px' }}
            onClick={() => props.cancel()}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => props.save(state)}
          >
            Guardar
          </Button>
        </Box>

      </Box>

    </Container>

  );
};