import React from 'react';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

// header
import Header from '../../component/header';

// componente que muestra la barra de herramientas
import EditorToolsWorld from './editorToolsWorld';

// entity para funciones del mundo
import { World } from '../../entity/world';
// entity para funciones de usuarios
import { Usuarios } from '../../entity/usuarios';
//* entity para las simulaciones
import { Simulacion } from '../../entity/simulacion';

// vista para elementos de segmento
import { SegmentoViewWorld } from '../segmento/segmentoViewWorld';
import { SegmentoViewWorldEdit } from '../segmento/segmentoViewWorldEdit';

// vista para elementos de ubicación
import { UbicacionViewWorldEdit } from '../ubicacion/ubicacionViewWorldEdit';
// vista para elementos de zonas
import { ZonaViewWorldEdit } from '../ubicacion/zonaViewWorldEdit';

// vista para elementos de player
import { PlayerViewWorldEdit } from '../playerplantilla/playerViewWorldEdit';

// vista para elementos de bot
import { BotViewWorldEdit } from '../bot/botViewWorldEdit';


// vista para elementos de skill
import { SkillViewWorldEdit } from '../skill/skillViewWorldEdit';

// vista para elementos de item
import { ItemViewWorldEdit } from '../item/itemViewWorldEdit';
import { ItemTipoViewWorldEdit } from '../item/itemTipoViewWorldEdit';

// vista para elementos de tareas de tipo interaccion
import { TareaInteraccionViewWorldEdit } from '../tareas/world/tareaInteraccionViewWorldEdit';

// vista para elementos de tareas de tipo mensaje
import { TareaMensajeViewWorldEdit } from '../tareas/world/tareaMensajeViewWorldEdit';

// vista para elementos de tareas de tipo build
import { TareaBuildViewWorldEdit } from '../tareas/world/tareaBuildViewWorldEdit';

import ShowTableData from '../../component/showTableData';

import { EmpresaViewdEdit } from '../empresa/empresaViewEdit';
import { AnimacionViewWorldEdit } from '../animaciones/animacionViewWorldEdit';
import { NivelViewWorldEdit } from '../niveles/nivelViewWorldEdit';
import { CuestionarioViewWorldEdit } from '../cuestionarios/cuestionarioViewWorldEdit';
import { LogroViewWorldEdit } from '../logros/logroViewWorldEdit';
import { BotJsonSkinViewWorldEdit } from '../botsjsonskin/botJsonSkinViewWorldEdit';
import { EmailPlantillaViewWorldEdit } from '../email/emailPlantillaViewWorldEdit';

import { InfoDialog } from '../../component/utils';

class EditorWorld extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ubicaciones: [],
      zonas: [],
      players: [],
      skills: [],
      items: [],
      itemtipo: [],
      tipostareas: [],
      tareas: [],
      bots: [],
      empresas: [],
      segmentos: [],
      usuarios: [],
      simulaciones: [],
      animaciones: [],
      niveles: [],
      cuestionarios: [],
      logros: [],
      emailPlantillas: [],
      emailPalabrasClave: [],
      botsjsonskin: [],

      listShow: [],
      headersTable: [],
      elementShow: undefined,

      message: ''
    }

    // para cargar los nuevos datos
    this.onCargar = this.onCargar.bind(this);

    // funcion para asignar qué elemento es con el que estamos trabajando ahora mismo
    this.setElementShow = this.setElementShow.bind(this);
    // funcion para salir del modo de edición
    this.clearElementShow = this.clearElementShow.bind(this);
    // funcion para asignar qué lista de elementos se va a visualizar
    this.setListShow = this.setListShow.bind(this);

    // función para guardar datos
    this.saveSegmento = this.saveSegmento.bind(this);
    this.deleteSegmento = this.deleteSegmento.bind(this);

    this.saveUbicacion = this.saveUbicacion.bind(this);
    this.deleteUbicacion = this.deleteUbicacion.bind(this);

    this.saveZona = this.saveZona.bind(this);
    this.deleteZona = this.deleteZona.bind(this);

    this.saveEmpresa = this.saveEmpresa.bind(this);
    this.deleteEmpresa = this.deleteEmpresa.bind(this);

    this.savePlayer = this.savePlayer.bind(this);
    this.deletePlayer = this.deletePlayer.bind(this);

    this.saveSkill = this.saveSkill.bind(this);
    this.deleteSkill = this.deleteSkill.bind(this);

    this.saveItem = this.saveItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);

    this.saveItemTipo = this.saveItemTipo.bind(this);
    this.deleteItemTipo = this.deleteItemTipo.bind(this);

    this.saveTarea = this.saveTarea.bind(this);
    this.deleteTarea = this.deleteTarea.bind(this);

    this.saveBot = this.saveBot.bind(this);
    this.deleteBot = this.deleteBot.bind(this);

    this.saveAnimacion = this.saveAnimacion.bind(this);
    this.deleteAnimacion = this.deleteAnimacion.bind(this);

    this.saveNivel = this.saveNivel.bind(this);
    this.deleteNivel = this.deleteNivel.bind(this);

    this.saveCuestionario = this.saveCuestionario.bind(this);
    this.deleteCuestionario = this.deleteCuestionario.bind(this);

    this.saveLogro = this.saveLogro.bind(this);
    this.deleteLogro = this.deleteLogro.bind(this);

    this.saveBotsJsonSkin = this.saveBotsJsonSkin.bind(this);
    this.deleteBotsJsonSkin = this.deleteBotsJsonSkin.bind(this);

    this.saveEmailPlantilla = this.saveEmailPlantilla.bind(this);
    this.deleteEmailPlantilla = this.deleteEmailPlantilla.bind(this);


    // render del formulario a mostrar
    this.renderElementShow = this.renderElementShow.bind(this);
    this.renderElementShowEdit = this.renderElementShowEdit.bind(this);
  }

  componentDidMount() {
    this.onCargar();
  }

  onCargar(seccion, _id) {
    this.setState({ loading: true });

    console.log('actualizando ' + seccion);


    let tmpElementShow = this.state.elementShow;

    if (!seccion) {
      World.getTiposTareas().then((data) => {
        this.setState({ tipostareas: data.records, loading: false });
      });
    }

    //obtenemos los segmentos
    if (!seccion || seccion === 'segmentos') {
      World.getSegmentos()
        .then(
          data => {
            if (data.records) {
              this.setState({ segmentos: data.records, listShow: (seccion === 'segmentos' ? data.records : []), loading: false });
            }
          }
        );
    }

    //obtenemos los logros
    if (!seccion || seccion === 'logros') {
      World.getLogros()
        .then(
          data => {
            if (data.records) {
              this.setState({ logros: data.records, listShow: (seccion === 'logros' ? data.records : []), loading: false });
            }
          }
        );
    }

    //obtenemos los botsJsonSkin
    if (!seccion || seccion === 'botsJsonSkin') {
      World.getBotsJsonSkin()
        .then(
          data => {
            if (data.records) {
              this.setState({ botsjsonskin: data.records, listShow: (seccion === 'botsJsonSkin' ? data.records : []), loading: false });
            }
          }
        );
    }

    //obtenemos las ubicaciones
    if (!seccion || seccion === 'ubicaciones') {
      World.getUbicaciones()
        .then(
          data => {
            if (data.records) {
              this.setState({ ubicaciones: data.records, listShow: (seccion === 'ubicaciones' ? data.records : []), loading: false });
            }
          }
        );
    }

    //obtenemos las zonas
    if (!seccion || seccion === 'zonas') {
      World.getZonas()
        .then(
          data => {
            if (data.records) {
              this.setState({ zonas: data.records, listShow: (seccion === 'zonas' ? data.records : []), loading: false });
            }
          }
        );
    }

    //obtenemos los skills
    if (!seccion || seccion === 'skills') {
      World.getSkill()
        .then(
          data => {
            if (data.records) {
              this.setState({ skills: data.records, listShow: (seccion === 'skills' ? data.records : []), loading: false });
            }
          }
        );
    }

    //obtenemos los tipos items
    if (!seccion || seccion === 'itemtipo') {
      World.getItemTipo()
        .then(
          data => {
            if (data.records) {
              this.setState({ itemtipo: data.records, listShow: (seccion === 'itemtipo' ? data.records : []), loading: false });
            }
          }
        );
    }

    //obtenemos las empresas
    if (!seccion || seccion === 'empresas') {
      World.getEmpresa()
        .then(
          data => {
            if (data.records) {
              this.setState({ empresas: data.records, listShow: (seccion === 'empresas' ? data.records : []), loading: false });
            }
          }
        );
    }

    //obtenemos las animaciones
    if (!seccion || seccion === 'animaciones') {
      World.getAnimaciones()
        .then(
          data => {
            if (data.records) {
              this.setState({ animaciones: data.records, listShow: (seccion === 'animaciones' ? data.records : []), loading: false });
            }
          }
        );
    }

    //obtenemos los niveles
    if (!seccion || seccion === 'niveles') {
      World.getNiveles()
        .then(
          data => {
            if (data.records) {
              this.setState({ niveles: data.records, listShow: (seccion === 'niveles' ? data.records : []), loading: false });
            }
          }
        );
    }

    //obtenemos los bots
    if (!seccion || seccion === 'bots') {
      World.getBot()
        .then(
          data => {
            if (data.records) {
              this.setState({ bots: data.records, listShow: (seccion === 'bots' ? data.records : []), loading: false });
            }
          }
        );
    }

    //obtenemos los palyers
    if (!seccion || seccion === 'players') {
      World.getPlayer()
        .then(
          data => {
            if (data.records) {
              this.setState({ players: data.records, listShow: (seccion === 'players' ? data.records : []), loading: false });
            }
          }
        );
    }

    //obtenemos los usuarios
    if (!seccion || seccion === 'usuarios') {
      Usuarios.getAll()
        .then(
          data => {
            if (data.records) {
              this.setState({ usuarios: data.records, listShow: (seccion === 'usuarios' ? data.records : []), loading: false });
            }
          }
        );
    }

    //obtenemos los items
    if (!seccion || seccion === 'items') {
      World.getItem()
        .then(
          data => {
            if (data.records) {
              this.setState({ items: data.records, listShow: (seccion === 'items' ? data.records : []), loading: false });
            }
          }
        );
    }

    // obtenemos la lista de plantillas de simulaciones
    Simulacion.misPlantillas()
      .then(
        data => {
          if (data.records) {
            this.setState({ simulaciones: data.records.filter(x => x.enProduccion === true) });
          }
        }
      );

    //obtenemos las palabras clave de las plantillas de email
    World.getEmailPalabrasClave()
      .then(
        data => {
          if (data.records) {
            this.setState({ emailPalabrasClave: data.records });
          }
        }
      );


    //obtenemos las plantillas de email
    if (!seccion || seccion === 'emailplantillas') {
      World.getEmailPlantillas()
        .then(
          data => {
            if (data.records) {
              this.setState({ emailPlantillas: data.records, listShow: (seccion === 'emailplantillas' ? data.records : []), loading: false });
            }
          }
        );
    }


    /*   
                    
       //obtenemos las tareas
       if (!seccion || seccion === 'tareas') {
         World.getTareas()
           .then(
             data => {
               if (data.records) {
                 this.setState({ tareas: data.records, loading: false });
               }
   
               if (seccion === 'tareas') {
   
                 // si es un nuevo registro, deberia contener algo el parámetro _id
                 if (_id) {
                   this.setState({ listShow: data.records, elementShow: data.records.filter(x => x._id.toString() === _id.toString())[0] });
                 }
   
                 // miramos si hay que mostrar un elemento concreto
                 if (tmpElementShow) {
                   this.setState({ listShow: data.records });
                   this.setState({ elementShow: data.records.filter(x => x.id === tmpElementShow.id)[0] });
                 }
                 else {
                   this.setState({ listShow: data.records });
                 }
               }
             }
           );
       }
   
   
       //obtenemos los cuestionarios
       if (!seccion || seccion === 'cuestionarios') {
         World.getCuestionarios()
           .then(
             data => {
               if (data.records) {
                 this.setState({ cuestionarios: data.records, listShow: (seccion === 'cuestionarios' ? data.records : []), loading: false });
               }
             }
           );
       }
         
   */

    // dejo de mostrar el que ubiese en ese momento para editar
    this.clearElementShow();

  }

  saveSegmento(element) {

    let data = new FormData();

    if (element._id) data.append('_id', element._id);
    if (element.nombre) data.append('nombre', element.nombre);
    if (element.nivel) data.append('nivel', element.nivel);
    data.append('publico', element.publico ? element.publico : false);
    if (element.fases) data.append('fases', JSON.stringify(element.fases));
    if (element.segmentoPadre) data.append('segmentoPadre', element.segmentoPadre);
    if (element.cuestionario) data.append('cuestionario', element.cuestionario);

    World.addSegmento(data).then(() => this.onCargar('segmentos'));
  }

  deleteSegmento(element) {
    World.deleteSegmento({ targetid: element._id }).then(response => {
      if (response === true) {
        this.onCargar('segmentos');
      }
      else {
        this.setState({ message: response.message });
      }
    });
  }

  saveUbicacion(element) {

    let data = new FormData();

    data.append('_id', element._id);
    data.append('nombre', element.nombre);
    data.append('orden', element.orden);
    data.append('empresaTarget', element.empresaTarget);
    data.append('zonaTarget', element.zonaTarget);

    if (element.bot) data.append('bot', element.bot);

    // si hemos indicado una nueva imagen, la enviamos
    if (element.imagenData) {
      data.append('fichero', element.imagenData);
    }

    World.addUbicacion(data).then(() => this.onCargar('ubicaciones'));
  }

  deleteUbicacion(element) {
    World.deleteUbicacion({ targetid: element._id }).then(response => {
      if (response === true) {
        this.onCargar('ubicaciones');
      }
      else {
        this.setState({ message: response.message });
      }

    });
  }

  saveZona(element) {

    let data = new FormData();

    data.append('_id', element._id);
    data.append('nombre', element.nombre);
    data.append('empresaTarget', element.empresaTarget);

    // si hemos indicado una nueva imagen, la enviamos
    if (element.imagenData) {
      data.append('fichero', element.imagenData);
    }

    World.addZona(data).then(() => this.onCargar('zonas'));
  }

  deleteZona(element) {
    World.deleteZona({ targetid: element._id }).then(response => {
      if (response === true) {
        this.onCargar('zonas');
      }
      else {
        this.setState({ message: response.message });
      }
    });
  }

  saveEmpresa(element) {

    let data = new FormData();

    data.append('_id', element._id);
    data.append('nombre', element.nombre);
    data.append('direccion', element.direccion);
    data.append('email', element.email);
    data.append('telefono', element.telefono);
    data.append('cif', element.cif);

    // si hemos indicado una nueva imagen, la enviamos
    if (element.imagenData) {
      data.append('fichero', element.imagenData);
    }

    World.addEmpresa(data).then(() => this.onCargar('empresas'));
  }

  deleteEmpresa(element) {
    World.deleteEmpresa({ targetid: element._id }).then(response => {
      if (response === true) {
        this.onCargar('empresas');
      }
      else {
        this.setState({ message: response.message });
      }

    });
  }

  saveAnimacion(element) {

    let data = new FormData();

    data.append('_id', element._id);
    data.append('nombre', element.nombre);

    World.addAnimacion(data).then(() => this.onCargar('animaciones'));
  }

  deleteAnimacion(element) {
    World.deleteAnimacion({ targetid: element._id }).then(response => {
      if (response === true) {
        this.onCargar('animaciones');
      }
      else {
        this.setState({ message: response.message });
      }
    });
  }

  saveNivel(element) {

    let data = new FormData();

    data.append('_id', element._id);
    data.append('nivel', element.nivel);
    data.append('puntosDesde', element.puntosDesde);
    data.append('puntosHasta', element.puntosHasta);

    World.addNivel(data).then(() => this.onCargar('niveles'));
  }

  deleteNivel(element) {
    World.deleteNivel({ targetid: element._id }).then(response => {
      if (response === true) {
        this.onCargar('niveles');
      }
      else {
        this.setState({ message: response.message });
      }
    });
  }

  saveCuestionario(element) {

    let data = new FormData();

    data.append('_id', element._id);
    data.append('nivel', element.nivel);
    data.append('orden', element.orden);
    data.append('nombre', element.nombre);

    if (element.skills) data.append('skills', JSON.stringify(element.skills));

    if (element.preguntas) data.append('preguntas', JSON.stringify(element.preguntas));

    World.addCuestionario(data).then(() => this.onCargar('cuestionarios'));
  }

  deleteCuestionario(element) {
    World.deleteCuestionario({ targetid: element._id }).then(response => {
      if (response === true) {
        this.onCargar('cuestionarios');
      }
      else {
        this.setState({ message: response.message });
      }
    });
  }

  saveLogro(element) {

    let data = new FormData();

    data.append('_id', element._id);
    data.append('nombre', element.nombre);

    // si hemos indicado una nueva imagen, la enviamos
    if (element.imagenData) {
      data.append('fichero', element.imagenData);
    }

    World.addLogro(data).then(() => this.onCargar('logros'));
  }

  deleteLogro(element) {
    World.deleteLogro({ targetid: element._id }).then(response => {
      if (response === true) {
        this.onCargar('logros');
      }
      else {
        this.setState({ message: response.message });
      }
    });
  }

  saveBotsJsonSkin(element) {

    let data = new FormData();

    data.append('_id', element._id);
    data.append('nombre', element.nombre);
    data.append('skinJSON', element.skinJSON);

    // si hemos indicado una nueva imagen, la enviamos
    if (element.imagenData) {
      data.append('fichero', element.imagenData);
    }

    World.addBotsJsonSkin(data).then(() => this.onCargar('botsJsonSkin'));
  }

  deleteBotsJsonSkin(element) {
    World.deleteBotsJsonSkin({ targetid: element._id }).then(response => {
      if (response === true) {
        this.onCargar('botsJsonSkin');
      }
      else {
        this.setState({ message: response.message });
      }
    });
  }

  saveEmailPlantilla(element) {

    let data = new FormData();

    data.append('_id', element._id);
    data.append('nombre', element.nombre);
    data.append('cabecera', element.cabecera);
    data.append('detalle', element.detalle);
    data.append('pie', element.pie);

    World.addEmailPlantilla(data).then(() => this.onCargar('emailplantillas'));
  }

  deleteEmailPlantilla(element) {
    // World.deleteLogro({ targetid: element._id }).then(() => this.onCargar('logros'));
  }

  savePlayer(element) {

    let data = new FormData();

    data.append('_id', element._id);
    data.append('nombre', element.nombre);
    if (element.descripcion) data.append('descripcion', element.descripcion);
    if (element.genero) data.append('genero', element.genero);
    if (element.empresaTarget) data.append('empresaTarget', element.empresaTarget);
    if (element.skills) data.append('skills', JSON.stringify(element.skills));

    // si hemos indicado una nueva imagen, la enviamos
    if (element.imagenData) {
      data.append('fichero', element.imagenData);
    }

    World.addPlayer(data).then(() => this.onCargar('players'));
  }

  deletePlayer(element) {
    World.deletePlayer({ targetid: element._id }).then(response => {
      if (response === true) {
        this.onCargar('players');
      }
      else {
        this.setState({ message: response.message });
      }
    });
  }


  saveSkill(element) {

    let data = new FormData();

    data.append('_id', element._id);
    data.append('nombre', element.nombre);
    data.append('minimo', element.minimo);
    data.append('maximo', element.maximo);
    data.append('valor', element.valor);

    data.append('empresaTarget', element.empresaTarget);

    // si hemos indicado una nueva imagen, la enviamos
    if (element.imagenData) {
      data.append('fichero', element.imagenData);
    }

    World.addSkill(data).then(() => { this.onCargar('skills') });
  }

  deleteSkill(element) {
    World.deleteSkill({ targetid: element._id }).then(response => {
      if (response === true) {
        this.onCargar('skills');
      }
      else {
        this.setState({ message: response.message });
      }
    });
  }


  saveItem(element) {

    let data = new FormData();

    data.append('_id', element._id);
    if (element.nombre) data.append('nombre', element.nombre);
    if (element.skills) data.append('skills', JSON.stringify(element.skills));
    if (element.skillsTemporal) data.append('skillsTemporal', JSON.stringify(element.skillsTemporal));
    if (element.tipo) data.append('tipo', element.tipo);
    if (element.coste) data.append('coste', element.coste);
    if (element.usos) data.append('usos', element.usos);
    if (element.nivel) data.append('nivel', element.nivel);
    if (element.empresaTarget) data.append('empresaTarget', element.empresaTarget);
    if (element.iniciarSimulacion) data.append('iniciarSimulacion', element.iniciarSimulacion);

    if (element.duracion) data.append('duracion', JSON.stringify(element.duracion));

    // si hemos indicado una nueva imagen, la enviamos
    if (element.imagenData) {
      data.append('fichero', element.imagenData);
    }

    World.addItem(data).then(() => this.onCargar('items'));
  }

  deleteItem(element) {
    World.deleteItem({ targetid: element._id }).then(response => {
      if (response === true) {
        this.onCargar('items');
      }
      else {
        this.setState({ message: response.message });
      }
    });
  }

  saveItemTipo(element) {

    let data = new FormData();

    data.append('_id', element._id);
    if (element.nombre) data.append('nombre', element.nombre);

    World.addItemTipo(data).then(() => this.onCargar('itemtipo'));
  }

  deleteItemTipo(element) {
    World.deleteItemTipo({ targetid: element._id }).then(response => {
      if (response === true) {
        this.onCargar('itemtipo');
      }
      else {
        this.setState({ message: response.message });
      }
    });
  }

  saveTarea(element) {
    let data = new FormData();

    data.append('_id', element._id);
    data.append('tipo', element.tipo);
    if (element.nombre) data.append('nombre', element.nombre);
    if (element.tags) data.append('tags', JSON.stringify(element.tags));
    if (element.ubicacion) data.append('ubicacion', element.ubicacion);
    if (element.bot) data.append('bot', element.bot);

    data.append('finalizarTarea', element.finalizarTarea ? element.finalizarTarea : false);
    data.append('ejecucionDirecta', element.ejecucionDirecta ? element.ejecucionDirecta : false);

    if (element.momentoActivacion) data.append('momentoActivacion', JSON.stringify(element.momentoActivacion));
    if (element.duracionTarea) data.append('duracionTarea', JSON.stringify(element.duracionTarea));

    // si hemos indicado una nueva imagen, la enviamos
    if (element.imagenData) {
      data.append('fichero', element.imagenData);
    }

    World.addTarea(data).then(() => {
      this.onCargar('tareas');
    });
  }

  deleteTarea(element) {
    World.deleteTarea({ targetid: element._id }).then(response => {
      if (response === true) {
        this.onCargar('tareas');
      }
      else {
        this.setState({ message: response.message });
      }
    });
  }

  saveBot(element) {

    let data = new FormData();

    data.append('_id', element._id);
    if (element.nombre) data.append('nombre', element.nombre);
    if (element.descripcion) data.append('descripcion', element.descripcion);
    if (element.genero) data.append('genero', element.genero);
    if (element.skills) data.append('skills', JSON.stringify(element.skills));
    if (element.coste) data.append('coste', element.coste);
    if (element.precioVenta) data.append('precioVenta', element.precioVenta);
    if (element.usos) data.append('usos', element.usos);
    if (element.disponibilidad) data.append('disponibilidad', element.disponibilidad);
    if (element.empresaTarget) data.append('empresaTarget', element.empresaTarget);
    data.append('skinJSON', element.skinJSON);

    data.append('default', element.default ? element.default : false);

    // si hemos indicado una nueva imagen, la enviamos
    if (element.imagenData) {
      data.append('fichero', element.imagenData);
    }

    World.addBot(data).then(() => this.onCargar('bots'));
  }

  deleteBot(element) {
    World.deleteBot({ targetid: element._id }).then(response => {
      if (response === true) {
        this.onCargar('bots');
      }
      else {
        this.setState({ message: response.message });
      }
    });
  }


  setElementShow(element) {
    this.setState({ elementShow: element });
  }

  clearElementShow() {
    this.setState({ elementShow: undefined });
  }

  setListShow(list, headers) {
    this.setState({ listShow: list, headersTable: headers, elementShow: undefined });
  }

  renderElementShow(element) {

    // segmento
    if (element.className === 'Segmento') {
      return <SegmentoViewWorld item={element} setElementShow={(this.setElementShow)} key={element.id} />;
    }

    return <div>{element.className}</div>;
  }


  renderElementShowEdit(element) {

    // segmento
    if (element.className === 'Segmento') {
      return <SegmentoViewWorldEdit
        item={element}
        cuestionarios={this.state.cuestionarios}
        save={this.saveSegmento}
        cancel={this.clearElementShow}
        onDelete={this.deleteSegmento}
        setElementShow={(this.setElementShow)} />;
    }

    // ubicacion
    if (element.className === 'Ubicacion') {
      return <UbicacionViewWorldEdit
        item={element}
        bots={this.state.bots.filter(x => x.tipo === 'Bot')}
        empresas={this.state.empresas}
        zonas={this.state.zonas}
        save={this.saveUbicacion}
        cancel={this.clearElementShow}
        onDelete={this.deleteUbicacion} />;
    }

    // zona
    if (element.className === 'Zonas') {
      return <ZonaViewWorldEdit
        item={element}
        empresas={this.state.empresas}
        save={this.saveZona}
        cancel={this.clearElementShow}
        onDelete={this.deleteZona} />;
    }

    // empresa
    if (element.className === 'Empresa') {
      return <EmpresaViewdEdit
        item={element}
        save={this.saveEmpresa}
        cancel={this.clearElementShow}
        onDelete={this.deleteEmpresa} />;
    }

    // animacion
    if (element.className === 'Animacion') {
      return <AnimacionViewWorldEdit
        item={element}
        save={this.saveAnimacion}
        cancel={this.clearElementShow}
        onDelete={this.deleteAnimacion} />;
    }

    // nivel
    if (element.className === 'Niveles') {
      return <NivelViewWorldEdit
        item={element}
        save={this.saveNivel}
        cancel={this.clearElementShow}
        onDelete={this.deleteNivel} />;
    }

    // cuestionario
    if (element.className === 'Cuestionarios') {
      return <CuestionarioViewWorldEdit
        item={element}
        skills={this.state.skills}
        animaciones={this.state.animaciones}
        save={this.saveCuestionario}
        cancel={this.clearElementShow}
        onDelete={this.deleteCuestionario} />;
    }


    // bot (Bot)
    if (element.className === 'Bot') {
      return <BotViewWorldEdit
        item={element}
        empresas={this.state.empresas}
        botsjsonskin={this.state.botsjsonskin}
        save={this.saveBot}
        cancel={this.clearElementShow}
        skills={this.state.skills}
        onDelete={this.deleteBot} />;
    }

    // player (ItemPersona)
    if (element.className === 'ItemPersona') {
      return <PlayerViewWorldEdit
        item={element}
        empresas={this.state.empresas}
        save={this.savePlayer}
        cancel={this.clearElementShow}
        skills={this.state.skills}
        onDelete={this.deletePlayer}
        onRefreshBots={() => this.onCargar('bots')} />;
    }

    // skill
    if (element.className === 'Skill') {
      return <SkillViewWorldEdit
        item={element}
        empresas={this.state.empresas}
        save={this.saveSkill}
        cancel={this.clearElementShow}
        onDelete={this.deleteSkill} />;
    }

    // item
    if (element.className === 'Item') {
      return <ItemViewWorldEdit
        item={element}
        skills={this.state.skills}
        empresas={this.state.empresas}
        itemtipo={this.state.itemtipo}
        save={this.saveItem}
        cancel={this.clearElementShow}
        simulaciones={this.state.simulaciones}
        onDelete={this.deleteItem} />;
    }

    // itemtipo
    if (element.className === 'ItemTipo') {
      return <ItemTipoViewWorldEdit
        item={element}
        save={this.saveItemTipo}
        cancel={this.clearElementShow}
        onDelete={this.deleteItemTipo} />;
    }

    // logro
    if (element.className === 'Logro') {
      return <LogroViewWorldEdit
        item={element}
        save={this.saveLogro}
        cancel={this.clearElementShow}
        onDelete={this.deleteLogro} />;
    }

    // BotsJsonSkin
    if (element.className === 'BotsJsonSkin') {
      return <BotJsonSkinViewWorldEdit
        item={element}
        save={this.saveBotsJsonSkin}
        cancel={this.clearElementShow}
        onDelete={this.deleteBotsJsonSkin} />;
    }

    // email plantilla
    if (element.className === 'EmailPlantilla') {
      return <EmailPlantillaViewWorldEdit
        item={element}
        emailPalabrasClave={this.state.emailPalabrasClave}
        save={this.saveEmailPlantilla}
        cancel={this.clearElementShow}
        onDelete={this.deleteEmailPlantilla} />;
    }

    // tareainteraccion
    if (element.className === 'TareaInteraccion') {
      return <TareaInteraccionViewWorldEdit
        item={element}
        tareas={this.state.tareas}
        ubicaciones={this.state.ubicaciones}
        bots={this.state.bots.filter(x => x.tipo === 'Bot')}
        skills={this.state.skills}
        animaciones={this.state.animaciones}
        save={this.saveTarea}
        cancel={this.clearElementShow}
        onCargar={() => this.onCargar('tareas')}
        onDelete={this.deleteTarea} />;

    }

    // tareamensaje
    if (element.className === 'TareaMensaje') {
      return <TareaMensajeViewWorldEdit
        item={element}
        ubicaciones={this.state.ubicaciones}
        bots={this.state.bots.filter(x => x.tipo === 'Bot')}
        save={this.saveTarea}
        cancel={this.clearElementShow}
        onCargar={() => this.onCargar('tareas')}
        onDelete={this.deleteTarea} />;
    }

    // tareabuild
    if (element.className === 'TareaBuild') {
      return <TareaBuildViewWorldEdit
        item={element}
        ubicaciones={this.state.ubicaciones}
        bots={this.state.bots.filter(x => x.tipo === 'Bot')}
        save={this.saveTarea}
        cancel={this.clearElementShow}
        onCargar={() => this.onCargar('tareas')}
        onDelete={this.deleteTarea} />;
    }

    return <div>Elemento no reconocido</div>;


  }

  render() {

    return (
      <div className="backgroundJuego">

        <InfoDialog
          content={this.state.message}
          open={this.state.message.length > 0}
          onClose={() => {
            this.setState({ message: '' });
          }}
        />

        <Header title="Editor (Mundo)" history={this.props.history}></Header>

        <Box padding={1}>

          <Box display='flex' alignItems='baseline'>

            <Box width='180px' m={1}>
              <EditorToolsWorld tipostareas={this.state.tipostareas} onCargar={this.onCargar} />
            </Box>

            <Box width={1}>

              <Box className='background-forms' display={'flex'}>

                {this.state.empresas.length > 0
                  ?
                  <Chip
                    avatar={<Avatar>{this.state.empresas.length}</Avatar>}
                    label="Empresas"
                    clickable
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      this.setListShow(this.state.empresas, [
                        { nombre: 'imagenAvatar', caption: 'Imagen', tipo: 'imagen', buscar: false },
                        { nombre: 'nombre', caption: 'Empresa', tipo: 'texto', buscar: true },
                        { nombre: 'direccion', caption: 'Dirección', tipo: 'texto', buscar: true },
                        { nombre: 'email', caption: 'Email', tipo: 'texto', buscar: true },
                        { nombre: 'telefono', caption: 'Teléfono', tipo: 'texto', buscar: true },
                        { nombre: 'cif', caption: 'CIF/NIF', tipo: 'texto', buscar: true },
                      ]);
                    }}
                  />
                  : <div></div>
                }

                {this.state.logros.length > 0
                  ?
                  <Chip
                    avatar={<Avatar>{this.state.logros.length}</Avatar>}
                    label="Logros"
                    clickable
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      this.setListShow(this.state.logros, [
                        { nombre: 'imagenAvatar', caption: 'Imagen', tipo: 'imagen', buscar: false },
                        { nombre: 'nombre', caption: 'Logro', tipo: 'texto', buscar: true },
                      ]);
                    }}
                  />
                  : <div></div>
                }

                {this.state.emailPlantillas.length > 0
                  ?
                  <Chip
                    avatar={<Avatar>{this.state.emailPlantillas.length}</Avatar>}
                    label="Email Plantillas"
                    clickable
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      this.setListShow(this.state.emailPlantillas, [
                        { nombre: 'nombre', caption: 'Plantilla', tipo: 'texto', buscar: true },
                      ]);
                    }}
                  />
                  : <div></div>
                }

                {this.state.cuestionarios.length > 0
                  ?
                  <Chip
                    avatar={<Avatar>{this.state.cuestionarios.length}</Avatar>}
                    label="Cuestionarios"
                    clickable
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      this.setListShow(this.state.cuestionarios, [
                        { nombre: 'nombre', caption: 'Cuestionario', tipo: 'texto', buscar: true },
                        { nombre: 'nivel', caption: 'Nivel', tipo: 'numero', buscar: true },
                      ]);
                    }}
                  />
                  : <div></div>
                }

                {this.state.animaciones.length > 0
                  ?
                  <Chip
                    avatar={<Avatar>{this.state.animaciones.length}</Avatar>}
                    label="Animaciones"
                    clickable
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      this.setListShow(this.state.animaciones, [
                        { nombre: 'nombre', caption: 'Animación', tipo: 'texto', buscar: true },
                      ]);
                    }}
                  />
                  : <div></div>
                }

                {this.state.niveles.length > 0
                  ?
                  <Chip
                    avatar={<Avatar>{this.state.niveles.length}</Avatar>}
                    label="Niveles"
                    clickable
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      this.setListShow(this.state.niveles, [
                        { nombre: 'nivel', caption: 'Nivel', tipo: 'numero', buscar: false },
                        { nombre: 'puntosDesde', caption: 'Puntos desde', tipo: 'numero', buscar: false },
                        { nombre: 'puntosHasta', caption: 'Puntos hasta', tipo: 'numero', buscar: false },
                      ]);
                    }}
                  />
                  : <div></div>
                }

                {this.state.segmentos.length > 0
                  ?
                  <Chip
                    avatar={<Avatar>{this.state.segmentos.length}</Avatar>}
                    label="Segmentos/Profesiones"
                    clickable
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      this.setListShow(this.state.segmentos, []);
                    }}
                  />
                  : <div></div>
                }

                {this.state.zonas.length > 0
                  ?
                  <Chip
                    avatar={<Avatar>{this.state.zonas.length}</Avatar>}
                    label="Zonas"
                    clickable
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      this.setListShow(this.state.zonas, [
                        { nombre: 'imagenAvatar', caption: 'Imagen', tipo: 'imagen', buscar: false },
                        { nombre: 'nombre', caption: 'Zona', tipo: 'texto', buscar: true },
                        { nombre: 'empresaNombre', caption: 'Empresa', tipo: 'texto', buscar: true },
                      ]);
                    }}
                  />
                  : <div></div>
                }

                {this.state.ubicaciones.length > 0
                  ?
                  <Chip
                    avatar={<Avatar>{this.state.ubicaciones.length}</Avatar>}
                    label="Ubicaciones"
                    clickable
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      this.setListShow(this.state.ubicaciones, [
                        { nombre: 'imagenAvatar', caption: 'Imagen', tipo: 'imagen', buscar: false },
                        { nombre: 'orden', caption: 'Orden', tipo: 'numero', buscar: true },
                        { nombre: 'nombre', caption: 'Ubicación', tipo: 'texto', buscar: true },
                        { nombre: 'zonaNombre', caption: 'Zona', tipo: 'texto', buscar: true },
                        { nombre: 'empresaNombre', caption: 'Empresa', tipo: 'texto', buscar: true },
                      ]);
                    }}
                  />
                  : <div></div>
                }

                {this.state.usuarios.length > 0
                  ?
                  <Chip
                    avatar={<Avatar>{this.state.usuarios.length}</Avatar>}
                    label="Usuarios"
                    clickable
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      this.setListShow(this.state.usuarios, [
                        { nombre: 'nombre', caption: 'Nombre', tipo: 'texto', buscar: true },
                        { nombre: 'usuario', caption: 'Usuarios', tipo: 'texto', buscar: true },
                        { nombre: 'email', caption: 'Email', tipo: 'texto', buscar: true },
                        { nombre: 'rol', caption: 'ROL', tipo: 'texto', buscar: true },
                      ]);
                    }}
                  />
                  : <div></div>
                }

                {this.state.botsjsonskin.length > 0
                  ?
                  <Chip
                    avatar={<Avatar>{this.state.botsjsonskin.length}</Avatar>}
                    label="BotsJsonSkin"
                    clickable
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      this.setListShow(this.state.botsjsonskin, [
                        { nombre: 'imagenAvatar', caption: 'Imagen', tipo: 'imagen', buscar: false },
                        { nombre: 'nombre', caption: 'JsonSkin', tipo: 'texto', buscar: true },
                      ]);
                    }}
                  />
                  : <div></div>
                }

                {this.state.bots.length > 0
                  ?
                  <Chip
                    avatar={<Avatar>{this.state.bots.length}</Avatar>}
                    label="Bots"
                    clickable
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      this.setListShow(this.state.bots, [
                        { nombre: 'imagenAvatar', caption: 'Imagen', tipo: 'imagen', buscar: false },
                        { nombre: 'nombre', caption: 'Nombre', tipo: 'texto', buscar: true },
                        { nombre: 'empresaNombre', caption: 'Empresa', tipo: 'texto', buscar: true },
                        { nombre: 'default', caption: 'Por Defecto', tipo: 'bool', buscar: false },
                        { nombre: 'descripcion', caption: 'Descripción', tipo: 'texto', buscar: true },
                        { nombre: 'tipo', caption: 'Tipo', tipo: 'texto', buscar: true },
                        { nombre: 'nivel', caption: 'Nivel', tipo: 'numero', buscar: true },
                        { nombre: 'disponibilidad', caption: 'Disponibilidad (H)', tipo: 'numero', buscar: true },
                      ]);
                    }}
                  />
                  : <div></div>
                }

                {this.state.players.length > 0
                  ?
                  <Chip
                    avatar={<Avatar>{this.state.players.length}</Avatar>}
                    label="Bots (Plantillas)"
                    clickable
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      this.setListShow(this.state.players, [
                        { nombre: 'imagenAvatar', caption: 'Imagen', tipo: 'imagen', buscar: false },
                        { nombre: 'descripcion', caption: 'Descripción', tipo: 'texto', buscar: true },
                        { nombre: 'empresaNombre', caption: 'Empresa', tipo: 'texto', buscar: true },
                        { nombre: 'tipo', caption: 'Tipo', tipo: 'texto', buscar: true },
                        { nombre: 'genero', caption: 'Genero', tipo: 'genero', buscar: true },
                      ]);
                    }}
                  />
                  : <div></div>
                }

                {this.state.skills.length > 0
                  ?
                  <Chip
                    avatar={<Avatar>{this.state.skills.length}</Avatar>}
                    label="Skills"
                    clickable
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      this.setListShow(this.state.skills, [
                        { nombre: 'imagenAvatar', caption: 'Imagen', tipo: 'imagen', buscar: false },
                        { nombre: 'nombre', caption: 'Skill', tipo: 'texto', buscar: true },
                        { nombre: 'empresaNombre', caption: 'Empresa', tipo: 'texto', buscar: true },
                      ]);
                    }}
                  />
                  : <div></div>
                }

                {this.state.itemtipo.length > 0
                  ?
                  <Chip
                    avatar={<Avatar>{this.state.itemtipo.length}</Avatar>}
                    label="ItemTipo"
                    clickable
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      this.setListShow(this.state.itemtipo, [
                        { nombre: 'nombre', caption: 'Tipo de Item', tipo: 'texto', buscar: true }
                      ]);
                    }}
                  />
                  : <div></div>
                }

                {this.state.items.length > 0
                  ?
                  <Chip
                    avatar={<Avatar>{this.state.items.length}</Avatar>}
                    label="Items"
                    clickable
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      this.setListShow(this.state.items, [
                        { nombre: 'imagenAvatar', caption: 'Imagen', tipo: 'imagen', buscar: false },
                        { nombre: 'nombre', caption: 'Item', tipo: 'texto', buscar: true },
                        { nombre: 'tipoNombre', caption: 'Tipo', tipo: 'texto', buscar: true },
                        { nombre: 'usos', caption: 'Usos', tipo: 'numero', buscar: true },
                        { nombre: 'coste', caption: 'Coste', tipo: 'numero', buscar: false },
                        { nombre: 'empresaNombre', caption: 'Empresa', tipo: 'texto', buscar: true },
                      ]);
                    }}
                  />
                  : <div></div>
                }

                {this.state.tareas.length > 0
                  ?
                  <Chip
                    avatar={<Avatar>{this.state.tareas.length}</Avatar>}
                    label="Tareas (Plantillas)"
                    clickable
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    onClick={() => {
                      this.setListShow(this.state.tareas, [
                        { nombre: 'imagenAvatar', caption: 'Imagen', tipo: 'imagen', buscar: false },
                        { nombre: 'tipo', caption: 'Tipo', tipo: 'texto', buscar: true },
                        { nombre: 'ubicacionNombre', caption: 'Ubicación', tipo: 'texto', buscar: true },
                        { nombre: 'nombre', caption: 'Tarea', tipo: 'texto', buscar: true },
                        { nombre: 'botNombre', caption: 'Bot', tipo: 'texto', buscar: true },
                        { nombre: 'tags', caption: 'Tags', tipo: 'array', buscar: true }
                      ]);
                    }}
                  />
                  : <div></div>
                }


              </Box>


              <Box display='flex' justifyContent='center' flexWrap='wrap' width={1}>
                {this.state.elementShow
                  ? <Box>
                    <div style={{ height: 20 }}></div>
                    {this.renderElementShowEdit(this.state.elementShow)}
                  </Box>
                  : <div></div>}

                {
                  !this.state.elementShow && this.state.headersTable.length > 0 ? <ShowTableData headers={this.state.headersTable} data={this.state.listShow} onClick={this.setElementShow} /> : <div></div>
                }

                {
                  !this.state.elementShow && this.state.headersTable.length === 0 ?
                    this.state.listShow.map((element) => {
                      return this.renderElementShow(element)
                    })
                    : <div></div>
                }

              </Box>

            </Box>

          </Box>

        </Box >

      </div >
    );
  }
}

export default EditorWorld;
