import React from 'react';

import Box from '@material-ui/core/Box';
import { TextField, Button } from '@material-ui/core';


import { Settings, DynamicFeed, Layers, Assignment } from '@material-ui/icons';


import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import TreeItem from '@material-ui/lab/TreeItem';
import Grid from '@material-ui/core/Grid';


// header
import Header from '../../component/header';

// entity para la simulacion
import { Simulacion } from '../../entity/simulacion';

// entity para el world
import { World } from '../../entity/world';

// vista para la simulacion (settings)
import { SimulacionViewWorldEdit } from '../simulacion/simulacionViewWorldEdit';

// vista para elementos de tareas de tipo interaccion
import { TareaInteraccionViewEdit } from '../tareas/editorsimulacion/tareaInteraccionViewEdit';

// vista para elementos de tareas de tipo mensaje
import { TareaMensajeViewdEdit } from '../tareas/editorsimulacion/tareaMensajeViewEdit';

// vista para elementos de tareas de tipo build
import { TareaBuildViewEdit } from '../tareas/editorsimulacion/tareaBuildViewEdit';

// vista para elementos de tareas de tipo random
import { TareaRandomViewdEdit } from '../tareas/editorsimulacion/tareaRandomViewEdit';

// vista para elementos de slides
import { SlideViewWorldEdit } from '../slide/slideViewWorldEdit';

// vista para elementos de fase
import { FaseViewWorldEdit } from '../segmento/faseViewWorldEdit';


import ShowTableData from '../../component/showTableData';

// utilidades
import { ConfirmDialog, InfoDialog, ColoresPaletaAzul, setLoading } from '../../component/utils';


class Editor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      _id: this.props.match.params.id !== undefined ? this.props.match.params.id : '',

      data: {},
      tipostareas: [],
      canalComunicacion: [],
      ubicaciones: [],
      tareas: [],
      tareasPlantillas: [],
      bots: [],
      empresas: [],
      animaciones: [],

      headersTable: [],
      elementShow: undefined,
      listShow: [],

      openConfirmationDialogAddTarea: false,
      elementAddTarea: undefined,

      steps: [],
      stepOptional: [1],
      stepSkipped: [],
      activeStep: 0,
      activeSeccion: 0,

      messageText: '',
      nivelMostrarSituaciones: 1

    }

    // funcion para recargar la plantilla con la que estamos trabajando
    this.onCargar = this.onCargar.bind(this);

    // funcion para asignar qué elemento es con el que estamos trabajando ahora mismo
    this.setElementShow = this.setElementShow.bind(this);
    // funcion para salir del modo de edición
    this.clearElementShow = this.clearElementShow.bind(this);
    // funcion para asignar qué lista de elementos se va a visualizar
    this.setListShow = this.setListShow.bind(this);

    // render del formulario a mostrar
    this.renderElementShowEdit = this.renderElementShowEdit.bind(this);

    // gestion de los steps
    this.resetStep = this.resetStep.bind(this);
    this.setStep = this.setStep.bind(this);
    this.pintaContenidoSegunStep = this.pintaContenidoSegunStep.bind(this);

    // función para guardar datos
    this.saveSimulacion = this.saveSimulacion.bind(this);
    this.deleteSimulacion = this.deleteSimulacion.bind(this);

    this.saveTarea = this.saveTarea.bind(this);
    this.saveTareaFromPlantilla = this.saveTareaFromPlantilla.bind(this);
    this.deleteTarea = this.deleteTarea.bind(this);

    this.saveSlide = this.saveSlide.bind(this);
    this.deleteSlide = this.deleteSlide.bind(this);

    this.saveFase = this.saveFase.bind(this);

  }

  componentDidMount() {
    this.onCargar();
  }

  async onCargar(seccion) {

    // si no hay una simulación para cargar, no continuo
    if (!this.state.data) {
      console.log('No hay simulación');
      return;
    }

    let tmpElementShow = { ...this.state.elementShow };

    this.setState({ loading: true });

    // obtenemos los tipos de tareas
    if (!seccion) {
      let data = await World.getTiposTareas();
      this.setState({ tipostareas: data.records });
    }

    // obtenemos las animaciones
    if (!seccion) {
      let data = await World.getAnimaciones();
      this.setState({ animaciones: data.records });
    }

    // obtenemos los canales de comunicación para las tareas
    if (!seccion) {
      let data = await World.getTiposCanales();
      this.setState({ canalComunicacion: data.records });
    }

    // cargamos todas las tareas de plantillas
    if (!seccion) {
      let data = await World.getTareas();
      if (data.records) {
        this.setState({ tareasPlantillas: data.records });
      }
    }

    //obtenemos los skills
    if (!seccion || seccion === 'skills') {
      let data = await World.getSkill();
      if (data.records) {
        this.setState({ skills: data.records });
      }
    }

    if (!seccion || seccion === 'general' || seccion === 'slides') {
      let data = await Simulacion.getPlantillaById(this.state._id);

      if (data.records) {

        this.setState({ data: data.records[0] });

        if (!seccion || seccion === 'general') {

          if (tmpElementShow.className !== 'fase') {
            this.setState({ elementShow: data.records[0] });
          }
        }

        if (seccion === 'slides') {
          this.setState({ listShow: data.records[0].slides, elementShow: undefined });
        }

        // genero los steps
        let tmpSteps = ['Settings', 'Slides'];

        if (data.records[0].fases) {
          data.records[0].fases.sort((a, b) => parseInt(a.orden) - parseInt(b.orden)).forEach((element) => tmpSteps.push(element.nombre));

          this.setState({ steps: tmpSteps });
        }

      }
    }

    if (!seccion || seccion === 'ubicaciones') {
      let data = await World.getUbicaciones();

      if (data.records) {
        this.setState({ ubicaciones: data.records });
      }
    }

    if (!seccion || seccion === 'bots') {
      let data = await World.getBot();
      if (data.records) {
        this.setState({ bots: data.records });
      }
    }

    if (!seccion || seccion === 'tareas') {
      let data = await Simulacion.getTareas(this.state._id);

      if (data.records) {

        // por cada tarea, obtenemos las respuestas
        await Promise.all(data.records.map(async (tarea, index) => {

          let respuestas = await Simulacion.getRespuestaTarea(tarea._id);

          data.records[index].respuestas = respuestas.records;
        }));

        this.setState({ tareas: data.records });
      }

    }


    if (!seccion || seccion === 'empresas') {
      let data = await World.getEmpresa()
      if (data.records) {
        this.setState({ empresas: data.records });
      }
    }

    this.setState({ loading: false });


  }

  setElementShow(element) {

    // si lo que estamos mostrando son las tareas globales, entonces no muestro nada, sino que añado la tarea a la simulacion
    if (this.state.listShow === this.state.tareasPlantillas) {
      this.setState({ elementAddTarea: element, openConfirmationDialogAddTarea: true });

      return;
    }


    this.setState({ elementShow: element });

  }

  clearElementShow() {
    this.setState({ elementShow: undefined });
  }

  setListShow(list, headers) {
    this.setState({ listShow: list, headersTable: headers, elementShow: undefined });
  }

  renderElementShowEdit(element) {

    // simulacion
    if (element.className === 'SimulacionPlantilla') {

      // step settings {0}
      if (this.state.activeStep === 0) {
        return <SimulacionViewWorldEdit
          key={element._id}
          item={element}
          empresas={this.state.empresas}
          save={this.saveSimulacion}
          cancel={this.clearElementShow}
          skills={this.state.skills}
          bots={this.state.bots.filter(x => x.tipo === 'Bot')}
          onDelete={this.deleteSimulacion} />;
      }

    }

    // fase
    if (element.className === 'fase') {
      return <FaseViewWorldEdit
        item={element.fase}
        tareasPlantillas={this.state.tareasPlantillas}
        tareas={this.state.tareas}
        tipostareas={this.state.tipostareas}
        fases={this.state.data.fases}
        seccionShow={element.seccion}
        ubicaciones={this.state.ubicaciones}
        bots={this.state.bots.filter(x => x.tipo === 'Bot')}
        skills={this.state.skills}

        onSelect={this.setElementShow}
        onSave={this.saveFase}
        onSaveTarea={this.saveTarea}
        onSaveTareaFromPlantilla={this.saveTareaFromPlantilla}
        onDeleteTarea={this.deleteTarea}
        onCargarTareas={() => this.onCargar('tareas')} />;
    }

    // slide
    if (element.className === 'Slide') {
      if (element.animacion !== undefined) {
        element.animacion = element.animacion._id;
      }

      return <SlideViewWorldEdit
        item={element}
        animaciones={this.state.animaciones}
        save={this.saveSlide}
        cancel={this.clearElementShow}
        onDelete={this.deleteSlide} />;
    }

    // tareainteraccion
    if (element.className === 'TareaInteraccion') {
      return <TareaInteraccionViewEdit
        item={element}
        save={this.saveTarea}
        cancel={this.clearElementShow}
        fases={this.state.data.fases}
        ubicaciones={this.state.ubicaciones}
        bots={this.state.bots.filter(x => x.tipo === 'Bot')}
        tareas={this.state.tareas}
        skills={this.state.skills}
        tipostareas={this.state.tipostareas}
        animaciones={this.state.animaciones}
        situaciones={this.state.data.fases[this.state.activeStep - 2].secciones[this.state.activeSeccion].situaciones}
        canalComunicacion={this.state.canalComunicacion}
        onCargar={() => this.onCargar('tareas')}
        onDelete={this.deleteTarea} />;
    }

    // tareamensaje
    if (element.className === 'TareaMensaje') {
      return <TareaMensajeViewdEdit
        item={element}
        save={this.saveTarea}
        cancel={this.clearElementShow}
        fases={this.state.data.fases}
        ubicaciones={this.state.ubicaciones}
        bots={this.state.bots.filter(x => x.tipo === 'Bot')}
        animaciones={this.state.animaciones}
        tareas={this.state.tareas}
        onCargar={() => this.onCargar('tareas')}
        onDelete={this.deleteTarea} />;
    }

    // tareabuild
    if (element.className === 'TareaBuild') {
      return <TareaBuildViewEdit
        item={element}
        save={this.saveTarea}
        cancel={this.clearElementShow}
        fases={this.state.data.fases}
        ubicaciones={this.state.ubicaciones}
        bots={this.state.bots.filter(x => x.tipo === 'Bot')}
        skills={this.state.skills}
        situaciones={this.state.data.fases[this.state.activeStep - 2].secciones[this.state.activeSeccion].situaciones}
        canalComunicacion={this.state.canalComunicacion}
        animaciones={this.state.animaciones}
        onCargar={() => this.onCargar('tareas')}
        onDelete={this.deleteTarea} />;
    }

    // tarearandom
    if (element.className === 'TareaRandom') {
      return <TareaRandomViewdEdit
        item={element}
        save={this.saveTarea}
        cancel={this.clearElementShow}
        fases={this.state.data.fases}
        ubicaciones={this.state.ubicaciones}
        bots={this.state.bots.filter(x => x.tipo === 'Bot')}
        tareas={this.state.tareas}
        situaciones={this.state.data.fases[this.state.activeStep - 2].secciones[this.state.activeSeccion].situaciones}
        canalComunicacion={this.state.canalComunicacion}
        animaciones={this.state.animaciones}
        onCargar={() => this.onCargar('tareas')}
        onDelete={this.deleteTarea} />;
    }


    return <div key={new Date().getTime().toString()}>Elemento no reconocido</div>;
  }



  saveSimulacion(element) {
    let data = new FormData();

    // esta es la plantilla
    data.append('_id', this.state.data._id);
    if (element.nombre) data.append('nombre', element.nombre);
    if (element.descripcion) data.append('descripcion', element.descripcion);
    if (element.objetivo) data.append('objetivo', element.objetivo);
    if (element.presupuesto) data.append('presupuesto', element.presupuesto);
    if (element.segundosEsperaRespuesta) data.append('segundosEsperaRespuesta', element.segundosEsperaRespuesta);
    if (element.nivel) data.append('nivel', element.nivel);
    data.append('enProduccion', element.enProduccion ? element.enProduccion : false);
    if (element.bot) data.append('bot', element.bot);
    if (element.tipo) data.append('tipo', element.tipo);
    if (element.duracion) data.append('duracion', JSON.stringify(element.duracion));
    if (element.skills) data.append('skills', JSON.stringify(element.skills));
    if (element.fases) data.append('fases', JSON.stringify(element.fases));
    if (element.empresaTarget) data.append('empresaTarget', element.empresaTarget);

    // si hemos indicado una nueva imagen, la enviamos
    if (element.imagenData) {
      data.append('fichero', element.imagenData);
    }


    Simulacion.addPlantilla(data).then(() => {
      this.onCargar('general');

      //this.setState({ messageText: 'Simulación guardada' });
    });
  }

  deleteSimulacion(element) {
    Simulacion.deletePlantilla({ targetid: this.state._id }).then(() => window.location.href = "/misplantillas/");
  }


  saveSlide(element) {
    let data = new FormData();

    console.log(element);

    // esta es la plantilla
    data.append('targetid', this.state._id);

    if (element.id) data.append('id', element.id);
    data.append('descripcion', element.descripcion.length === 0 ? " " : element.descripcion);
    data.append('orden', element.orden);
    data.append('link', element.link.length === 0 ? " " : element.link);
    data.append('enviarImagen', element.enviarImagen ? element.enviarImagen : false);
    data.append('animacion', element.animacion);

    // si hemos indicado una nueva imagen, la enviamos
    if (element.imagenData) {
      data.append('fichero', element.imagenData);
    }


    Simulacion.addSlide(data).then(() => {
      this.onCargar('slides');
      this.clearElementShow();
    });
  }

  deleteSlide(element) {
    Simulacion.deleteSlide({ targetid: this.state._id, id: element.id }).then(() => this.onCargar('slides'));
  }

  async saveTarea(element) {
    let data = new FormData();

    // esta es la plantilla
    data.append('targetid', this.state._id);

    if (element._id) data.append('_id', element._id);
    if (element.tipo) data.append('tipo', element.tipo);
    if (element.nombre) data.append('nombre', element.nombre);
    if (element.ubicacion) data.append('ubicacion', element.ubicacion);

    data.append('daPasoSeccion', element.daPasoSeccion ? element.daPasoSeccion : false);
    data.append('finalizarTarea', element.finalizarTarea ? element.finalizarTarea : false);
    data.append('ejecucionDirecta', element.ejecucionDirecta ? element.ejecucionDirecta : false);

    if (element.orden) data.append('orden', element.orden);
    if (element.bot) data.append('bot', element.bot);
    if (element.fase) data.append('fase', element.fase);
    if (element.seccion) data.append('seccion', element.seccion);
    if (element.situacion) data.append('situacion', element.situacion);

    if (element.canalComunicacion) data.append('canalComunicacion', element.canalComunicacion);

    if (element.momentoActivacion) data.append('momentoActivacion', JSON.stringify(element.momentoActivacion));
    if (element.duracionTarea) data.append('duracionTarea', JSON.stringify(element.duracionTarea));

    if (element.skills) data.append('skills', JSON.stringify(element.skills));

    if (element.probabilidad) data.append('probabilidad', element.probabilidad);

    // si hemos indicado una nueva imagen, la enviamos
    if (element.imagenData) {
      data.append('fichero', element.imagenData);
    }

    let tarea = await Simulacion.addTarea(data);

    await this.onCargar('tareas');

    let nuevaTarea = this.state.tareas.filter(x => x._id.toString() === tarea[0]._id.toString());

    if (nuevaTarea.length > 0) {
      return nuevaTarea[0];
    }
    else {
      return false;
    }


  }

  async saveTareaFromPlantilla(formulario) {

    let data = new FormData();
    data.append('targetid', this.state._id);
    if (formulario._id) data.append('tarea', formulario._id);
    if (formulario.fase) data.append('fase', formulario.fase);
    if (formulario.seccion) data.append('seccion', formulario.seccion);

    let tarea = await Simulacion.addTareaFromPlantilla(data);

    await this.onCargar('tareas');

    let nuevaTarea = this.state.tareas.filter(x => x._id.toString() === tarea[0]._id.toString());

    if (nuevaTarea.length > 0) {
      return nuevaTarea[0];
    }
    else {
      return false;
    }
  }

  deleteTarea(element) {
    Simulacion.deleteTarea({ targetid: element._id }).then(() => this.onCargar('tareas'));
  }


  saveFase(element) {

    // ignoro si estamos en los puntos de settings o slides
    if (this.state.activeStep <= 1) {
      return;
    }

    let data = { ...this.state.data };

    data.fases[this.state.activeStep - 2] = element;

    this.setState({ data: data });

    this.saveSimulacion({ fases: data.fases });
  }

  resetStep() {
    this.setState({ activeStep: 0 }, () => this.pintaContenidoSegunStep());
  }

  setStep({ step, seccion }) {
    this.setState({ elementShow: undefined, activeStep: step, activeSeccion: seccion }, () => this.pintaContenidoSegunStep({ step: step, seccion: seccion }));
  }

  pintaContenidoSegunStep({ step, seccion }) {

    // settings {0}
    if (this.state.activeStep === 0) {
      this.setElementShow(this.state.data)
    }

    // slides {1}
    if (this.state.activeStep === 1) {
      this.setListShow(this.state.data.slides, [
        { nombre: 'imagenAvatar', caption: 'Imagen', tipo: 'imagen', buscar: false },
        { nombre: 'orden', caption: 'Orden', tipo: 'numero', buscar: false },
        { nombre: 'descripcion', caption: 'Slide', tipo: 'texto', buscar: true },
        { nombre: 'link', caption: 'Link', tipo: 'texto', buscar: true }
      ]);
    }

    // pinto las fases
    if (this.state.activeStep > 1) {
      this.setElementShow({ className: 'fase', fase: this.state.data.fases[this.state.activeStep - 2], seccion: seccion });
    }

  }


  cargarTreeView = () => {
    return (
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {
          this.state.steps.map((label, index) => {
            // FASES
            return (
              <TreeItem
                nodeId={label}
                label={label}
                key={index}
                icon={
                  index === 0 ? <Settings />
                    : index === 1 ? <DynamicFeed />
                      : ''}
                onClick={() => { this.setStep({ step: index, seccion: 0 }) }}
                style={{ margin: '5px', padding: '5px', backgroundColor: index === 0 ? ColoresPaletaAzul.ColorBg1 : index === 1 ? ColoresPaletaAzul.ColorBg2 : ColoresPaletaAzul.ColorBg3 }}
              >
                {
                  index >= 2 ?
                    <React.Fragment>
                      {
                        this.state.data.fases && this.state.data.fases[index - 2].secciones.sort((a, b) => parseInt(a.orden) - parseInt(b.orden)).map((seccion, indexSeccion) => {
                          return (
                            <TreeItem nodeId={"fase:" + label + ":" + seccion.nombre}
                              key={indexSeccion}
                              label={`${seccion.orden}) ${seccion.nombre} ${seccion.activa === true ? '(Activa)' : ''} `}
                              onClick={() => { this.setStep({ step: index, seccion: indexSeccion }) }}
                              style={{ fontSize: '8px', margin: '5px', padding: '5px', backgroundColor: ColoresPaletaAzul.ColorBg4 }}
                            >
                              {
                                seccion && seccion.situaciones
                                  ? seccion.situaciones.filter(x => parseInt(x.nivel === undefined ? 1 : x.nivel) === parseInt(this.state.nivelMostrarSituaciones === 0 ? parseInt(x.nivel === undefined ? 1 : x.nivel) : this.state.nivelMostrarSituaciones)).sort((a, b) => parseInt(a.orden) - parseInt(b.orden)).map((situacion, indexSituacion) => {
                                    return (
                                      <TreeItem nodeId={"fase:" + label + ":" + situacion.nombre + ":situacion:" + situacion.nombre}
                                        label={` ${situacion.orden}) ${situacion.nombre} ${situacion.activa === true ? '(Activa)' : ''} (N ${situacion.nivel})`}
                                        key={indexSituacion}

                                        onClick={() => { this.setStep({ step: index, seccion: indexSeccion }) }}
                                        onDrop={() => {

                                        }}

                                        style={{ fontSize: '8px', margin: '5px', padding: '5px' }}>
                                        {
                                          this.state.tareas.sort((a, b) => a.orden - b.orden).filter(x => x.seccion === seccion.nombre && x.situacion === situacion.nombre).map((tarea, indexTarea) => {
                                            return (
                                              <TreeItem nodeId={"fase:" + label + ":" + seccion.nombre + ":tarea:" + tarea.nombre}

                                                label={`<TAREA> ${tarea.orden}) ${tarea.nombre}`}

                                                icon={tarea.daPasoSeccion ? <OpenInNewIcon /> : <div></div>}

                                                key={indexTarea}

                                                onClick={() => { this.setElementShow(tarea) }}

                                                style={{ fontSize: '8px', margin: '5px', padding: '5px', backgroundColor: ColoresPaletaAzul[tarea.tipo], color: 'white' }}>
                                                {
                                                  tarea.respuestas !== undefined
                                                    ? tarea.respuestas.map((respuesta, indexRespuesta) => {
                                                      return <TreeItem nodeId={"respuesta:" + respuesta._id}
                                                        key={indexRespuesta}
                                                        icon={<Assignment />}
                                                        label={`<RESPUESTA> ${respuesta.descripcion}`}
                                                        style={{ fontSize: '8px', padding: '3px', color: 'black' }} />
                                                    })
                                                    : <div></div>
                                                }
                                              </TreeItem>
                                            )
                                          })
                                        }


                                      </TreeItem>
                                    )
                                  })
                                  : <div></div>
                              }

                            </TreeItem>
                          )
                        })
                      }
                    </React.Fragment>
                    : ''
                }
              </TreeItem>)
          })
        }

      </TreeView>
    );
  }

  render() {

    return (
      <div className="backgroundJuego">

        <ConfirmDialog
          content="¿Añadir la tarea?"
          open={this.state.openConfirmationDialogAddTarea}
          setOpen={(value) => this.setState({ openConfirmationDialogAddTarea: value })}
          onConfirm={() => {
            this.saveTareaFromPlantilla({ _id: this.state.elementAddTarea._id });
          }}
        />

        <InfoDialog
          content={this.state.messageText}
          open={this.state.messageText.length > 0}
          onClose={() => {
            this.setState({ messageText: '' });
          }}
        />

        <Header title="Editor de Plantillas" history={this.props.history}></Header>

        <div style={{ height: 20 }}></div>

        {this.state.loading === true ? setLoading() : ''}

        <Grid container>

          <Grid item md={3}>
            <Box>
              <TextField
                margin='dense'
                size='small'
                variant='standard'
                id="nivelMostrar"
                label='Nivel Editar Situacion'
                name='nivelMostrarSituaciones'
                type={'number'}

                value={this.state.nivelMostrarSituaciones}
                onChange={(e) => this.setState({ nivelMostrarSituaciones: parseInt(e.target.value) })}
              />

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  window.open("/editorreactflow/" + this.state._id, "target=_blank");
                }} >
                Abrir ReactFlow
              </Button>

              {this.cargarTreeView()}
            </Box>
          </Grid>

          <Grid item md={9}>
            <Box width={'95%'}>
              {
                // si estamos mostrando los slides, podremos añadir un nuevo slide
                this.state.activeStep === 1
                  ? <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      let data = new FormData();
                      data.append('targetid', this.state._id);

                      Simulacion.addSlide(data).then(() => this.onCargar('slides'));
                    }} >
                    Añadir Slide
                  </Button>
                  : <div></div>
              }


              {this.state.elementShow ? this.renderElementShowEdit(this.state.elementShow) : ''}

              {
                !this.state.elementShow && this.state.headersTable.length > 0
                  ? <ShowTableData
                    headers={this.state.headersTable}
                    data={this.state.listShow}
                    onClick={this.setElementShow} />
                  : <div></div>
              }

              {
                !this.state.elementShow && this.state.headersTable.length === 0 ?
                  this.state.listShow.map((element) => {
                    return this.renderElementShowEdit(element)
                  })
                  : <div></div>
              }
            </Box>

          </Grid>

        </Grid>

      </div >
    );
  }
}

export default Editor;