import React, { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { World } from '../../entity/world';

export function SegmentoViewWorld(props) {

  const [hijos, setHijos] = useState([]);

  useEffect(() => {
    console.log('cargando hijos');
    World.getSegmentos(props.item._id)
      .then(
        data => {
          if (data.records) {
            setHijos(data.records);
          }
        }
      );

  }, []);

  return (
    <Box className='background-list-cards' m={1} p={1} >

      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        <TreeItem
          nodeId={props.item._id}
          label={
            <Box display='flex'>
              {props.item.publico === true ? <CheckCircleIcon color="primary" titleAccess="Público" /> : <div></div>}
              <Typography variant="body1" style={{ marginLeft: 10 }}>{props.item.nombre}</Typography>
            </Box>
          }
          onLabelClick={() => { props.setElementShow(props.item) }} >
          {
            hijos.map((element) => {
              return <TreeItem
                style={{ margin: '5px' }}
                nodeId={element._id}
                label={
                  <Box display='flex'>
                    {element.publico === true ? <CheckCircleIcon color="primary" titleAccess="Público" /> : <div></div>}
                    <Typography variant="body2" style={{ marginLeft: 10 }}>{element.nombre}</Typography>
                  </Box>
                }
                key={element._id}
                onLabelClick={() => { props.setElementShow(element) }} />
            })
          }
        </TreeItem>
      </TreeView>

    </Box >
  );
};