import React from 'react';

import { Box, Dialog, DialogContent, Typography } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ReactFlow, { isNode, Background, Controls, MiniMap } from 'react-flow-renderer';
import dagre from 'dagre';

// header
import Header from '../../component/header';

// entity para la simulacion
import { Simulacion } from '../../entity/simulacion';
import { World } from '../../entity/world';


// utilidades
import { InfoDialog, setLoading } from '../../component/utils';
import { MomEdit } from './momEdit';
import { MomFaseEdit } from './momFaseEdit';
import { MomSeccionEdit } from './momSeccionEdit';
import { MomSituacionEdit } from './momSituacionEdit';


const dagreGraph = new dagre.graphlib.Graph();

const nodeWidth = 180;
const nodeHeight = 40;


class MomFlow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

      loading: false,

      id: this.props.match.params.id !== undefined ? this.props.match.params.id : '',

      data: undefined,
      conocimientos: [],
      incidencias: [],
      segmentos: [],

      showElement: undefined,

      messageText: '',

      elements: [],
    }

    // funcion para recargar los datos desde la API
    this.onCargar = this.onCargar.bind(this);

    // al hacer click en un nodo
    this.onElementClick = this.onElementClick.bind(this);

    // para el render del elemento a mostrar
    this.renderElementEdit = this.renderElementEdit.bind(this);

    // para guardar la cabecera del mom
    this.saveMom = this.saveMom.bind(this);
    this.deleteMom = this.deleteMom.bind(this);

    // para guardar una fase
    this.saveFaseMom = this.saveFaseMom.bind(this);
    this.deleteFaseMom = this.deleteFaseMom.bind(this);

    // para guardar una seccion
    this.saveSeccionMom = this.saveSeccionMom.bind(this);
    this.deleteSeccionMom = this.deleteSeccionMom.bind(this);

    // para guardar una situacion
    this.saveSituacionMom = this.saveSituacionMom.bind(this);
    this.deleteSituacionMom = this.deleteSituacionMom.bind(this);

    // para mostrar las posibles incidencias
    this.showIncidencias = this.showIncidencias.bind(this);
  }

  componentDidMount() {
    this.onCargar();
  }

  onCargar() {
    this.setState({ loading: true });

    // cargamos los conocimientos para vincularlos a un MOM
    Simulacion.getConocimientos()
      .then(
        data => {
          if (data.records) {

            this.setState({ loading: false, conocimientos: data.records });
          }
        }
      );

    // cargamos los segmentos
    World.getSegmentos().then(data => this.setState({ segmentos: data.records }));

    // cargamos el MOM a editar
    Simulacion.getMoms(this.state.id)
      .then(
        data => {
          if (data.records) {

            this.setState({ loading: false, data: data.records[0], elements: data.records[0].reactFlow, incidencias: data.records[0].incidencias });
          }
        }
      );
  }

  saveMom(element) {

    this.setState({ loading: true });

    let formulario = new FormData();
    formulario.append('_id', element._id); // id de la tarea
    formulario.append('nombre', element.nombre);
    formulario.append('descripcion', element.descripcion);
    formulario.append('targetConocimiento', element.targetConocimiento);
    formulario.append('targetSegmento', element.targetSegmento);

    if (element.tags) formulario.append('tags', JSON.stringify(element.tags));
    if (element.fases) formulario.append('fases', JSON.stringify(element.fases));

    Simulacion.addMom(formulario)
      .then(
        data => {
          this.setState({ loading: false, data: data, elements: data.reactFlow, showElement: undefined });
        }
      );

  }

  deleteMom() {
    this.setState({ loading: true });

    Simulacion.deleteMom({ id: this.state.id })
      .then(
        data => {
          if (data) {
            window.location.href = "/moms";
          }
          else {
            this.setState({ loading: false, messageText: 'No se pudo borrar el MOM' });
          }
        }
      );
  }

  saveFaseMom(element) {

    let mom = { ...this.state.data };

    // es una nueva fase para añadir
    if (element === undefined) {
      mom.fases.push({ nombre: 'fase', orden: (parseInt(mom.fases.length) + 1), secciones: [{ orden: 1, nombre: 'seccion', situaciones: [{ orden: 1, nombre: 'situacion 1' }] }] });

      console.log("añadimos una nueva fase");
    }

    // es para modificar una fase
    if (element !== undefined) {

      // recorremos las fases, buscando la que hemos modificado
      mom.fases.forEach((fase, index) => {
        if (fase.id.toString() === element.id.toString()) {
          mom.fases[index] = element;
        }
      });

    }

    this.saveMom(mom);

  }

  deleteFaseMom(element) {
    // buscamos si hay tareas vinculadas a esta fase
    let tareas = this.state.tareas.filter(x => x.fase.toString() === element.nombre);

    if (tareas.length > 0) {
      this.setState({ messageText: 'Hay tareas vinculadas a esta Sección' });
    }
    else {
      let mom = { ...this.state.data };

      // borraos la situación
      // recorremos las fases y secciones, buscando la que hemos modificado
      mom.fases.forEach((fase, indexFase) => {
        if (fase.id.toString() === element.id.toString()) {
          // simulacion.fases.splice(indexFase, 1);
          this.setState({ messageText: 'Tarea por concretar' });
        }
      });

      this.saveMom(mom);
    }

  }

  saveSeccionMom(element) {

    let mom = { ...this.state.data };

    // es una nueva fase para añadir
    if (element === undefined) {
      mom.fases.push({ nombre: 'fase', orden: (parseInt(mom.fases.length) + 1), secciones: [{ orden: 0, nombre: 'seccion' }] });
    }

    // es para modificar una fase
    if (element !== undefined) {

      // recorremos las fases y secciones, buscando la que hemos modificado
      mom.fases.forEach((fase, indexFase) => {
        fase.secciones.forEach((seccion, indexSeccion) => {
          if (seccion.id.toString() === this.state.showElement.id.toString()) {
            mom.fases[indexFase].secciones[indexSeccion] = element;
          }
        })
      });

    }

    this.saveMom(mom);

  }

  deleteSeccionMom(element) {
    let mom = { ...this.state.data };

    // borraos la situación
    // recorremos las fases y secciones, buscando la que hemos modificado
    mom.fases.forEach((fase, indexFase) => {
      fase.secciones.forEach((seccion, indexSeccion) => {
        if (seccion.id.toString() === element.id.toString()) {
          mom.fases[indexFase].secciones.splice(indexSeccion, 1);
        }
      })
    });

    this.saveMom(mom);

  }


  saveSituacionMom(element) {

    let mom = { ...this.state.data };

    // es para modificar una fase
    if (element !== undefined) {

      // recorremos las fases, secciones y situaciones, buscando la que hemos modificado
      mom.fases.forEach((fase, indexFase) => {
        fase.secciones.forEach((seccion, indexSeccion) => {
          seccion.situaciones.forEach((situacion, indexSituacion) => {
            if (situacion.id.toString() === this.state.showElement.id.toString()) {
              mom.fases[indexFase].secciones[indexSeccion].situaciones[indexSituacion] = element;
            }
          })
        })
      });

    }

    this.saveMom(mom);

  }

  deleteSituacionMom(element) {
    let mom = { ...this.state.data };

    // borraos la situación
    // recorremos las fases, secciones y situaciones, buscando la que hemos modificado
    mom.fases.forEach((fase, indexFase) => {
      fase.secciones.forEach((seccion, indexSeccion) => {
        seccion.situaciones.forEach((situacion, indexSituacion) => {
          if (situacion.id.toString() === element.id.toString()) {
            mom.fases[indexFase].secciones[indexSeccion].situaciones.splice(indexSituacion, 1);
          }
        })
      })
    });

    this.saveMom(mom);
  }


  onElementClick(event, element) {
    this.setState({ showElement: element });
  }

  renderElementEdit() {
    if (this.state.showElement === undefined) {
      return <div></div>;
    }

    let render = <div></div>;

    switch (this.state.showElement.data.tipo) {
      case 'mom':
        render = <MomEdit
          item={this.state.data}
          conocimientos={this.state.conocimientos}
          segmentos={this.state.segmentos}
          cancel={() => this.setState({ showElement: undefined })}
          save={this.saveMom}
          onDelete={this.deleteMom} />
        break;
      case 'fase':

        render = <MomFaseEdit
          item={this.state.showElement.data.objeto}
          cancel={() => this.setState({ showElement: undefined })}
          save={this.saveFaseMom}
          onDelete={this.deleteFaseMom} />
        break;

      case 'seccion':

        render = <MomSeccionEdit
          item={this.state.showElement.data.objeto}
          cancel={() => this.setState({ showElement: undefined })}
          save={this.saveSeccionMom}
          onDelete={this.deleteSeccionMom} />
        break;

      case 'situacion':

        render = <MomSituacionEdit
          item={this.state.showElement.data.objeto}
          cancel={() => this.setState({ showElement: undefined })}
          save={this.saveSituacionMom}
          onDelete={this.deleteSituacionMom} />
        break;

      default:
        break;
    }

    return render;
  }


  // TB LR
  getLayoutedElements = (elements, direction = 'LR') => {
    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });

    elements.forEach((el) => {
      if (isNode(el)) {
        dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
      } else {
        dagreGraph.setEdge(el.source, el.target);
      }
    });

    dagre.layout(dagreGraph);

    return elements.map((el) => {
      if (isNode(el)) {

        const nodeWithPosition = dagreGraph.node(el.id);

        el.targetPosition = isHorizontal ? 'left' : 'top';
        el.sourcePosition = isHorizontal ? 'right' : 'bottom';

        // unfortunately we need this little hack to pass a slightly different position
        // to notify react flow about the change. Moreover we are shifting the dagre node position
        // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
        el.position = {
          x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000 + 20,
          y: nodeWithPosition.y - nodeHeight / 2,
        };
      }

      return el;
    });
  };

  showIncidencias() {
    if (this.state.loading === true) {
      return <Box></Box>;
    }

    let list = this.state.incidencias.map((element, index) => <Alert key={index} severity="warning" style={{ margin: 5, width: 250 }}>{element}</Alert>);

    return <Box>{list}</Box>

  }

  render() {

    dagreGraph.setDefaultEdgeLabel(() => ({}));

    return (
      <div className="backgroundJuego">

        <Header title="Edición de MOM" history={this.props.history}></Header>

        <InfoDialog
          content={this.state.messageText}
          open={this.state.messageText.length > 0}
          onClose={() => {
            this.setState({ messageText: '' });
          }}
        />

        <div style={{ height: 20 }}></div>

        {this.state.loading === true ? setLoading() : ''}

        <Box style={{ height: '90vh' }}>
          <ReactFlow
            onLoad={(reactFlowInstance) => reactFlowInstance.fitView()}
            onElementClick={this.onElementClick}

            elements={this.getLayoutedElements(this.state.elements)}
            snapToGrid={true}
            snapGrid={[15, 15]}
          >
            <Background
              variant="dots"
              gap={12}
            />

            <MiniMap />

            {
              this.state.loading === false && this.state.data !== undefined
                ? <Box style={{ opacity: '50%' }} maxWidth={'450px'} marginLeft={'20px'}>
                  <Typography>{this.state.data.descripcion}</Typography>
                </Box>
                : <div></div>
            }

            <this.showIncidencias />

            <Controls>

              {
                this.state.loading === false && this.state.data !== undefined
                  ? <FormControl fullWidth>
                    <InputLabel htmlFor="set-conocimientos">Conocimiento</InputLabel>
                    <Select
                      labelId="set-conocimientos"
                      id="targetConocimiento"
                      value={this.state.data.targetConocimiento}
                      onChange={(e) => {
                        let mom = { ...this.state.data };

                        mom.targetConocimiento = e.target.value;

                        this.saveMom(mom);
                      }}
                    >
                      {
                        this.state.conocimientos.map((element) => {
                          return <MenuItem value={element._id} key={element._id}>
                            <Box width={1} display={'flex'} justifyContent={'space-between'}>
                              <Typography>{element.nombre}</Typography>
                              <Box component="span" style={{ marginLeft: 15, width: 20, height: 20, borderRadius: 25, backgroundColor: element.color }} />
                            </Box>
                          </MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>

                  : <div></div>
              }

            </Controls>

          </ReactFlow>
        </Box>


        <Dialog open={this.state.showElement !== undefined} onClose={() => this.setState({ showElement: undefined })}>
          <DialogContent style={{ backgroundColor: 'white', color: 'black' }}>
            <this.renderElementEdit />
          </DialogContent>
        </Dialog>

      </div >
    );
  }
}

export default MomFlow;