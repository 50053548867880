import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

import { Container, IconButton, TextField } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

// utilidades
import { ConfirmDialog } from '../../component/utils';
import { BotMenuItem } from '../bot/botMenuItem';


export function UbicacionViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // ref para el input de tipo file
  const hiddenFileInput = React.createRef();

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);


  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  return (
    <Container maxWidth='xs'>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box className='background-forms'>

        <Box display='flex' justifyContent="center">
          <img
            alt={state.nombre}
            src={state.imagenAvatar}
            style={{ margin: '10px', maxWidth: '100%', maxHeight: '200px' }}
          />
        </Box>

        <Box display='flex'>

          <input ref={hiddenFileInput} accept='image/*' type='file' style={{ display: 'none' }}
            onChange={e => {
              setState({
                ...state,
                imagenData: e.target.files[0],
                imagenAvatar: URL.createObjectURL(e.target.files[0])
              });
            }} />
          <Avatar style={{ marginRight: '10px' }}>
            <IconButton onClick={() => { hiddenFileInput.current.click(); }} >
              <PhotoCameraIcon />
            </IconButton>
          </Avatar>

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            id="nombre"
            label='Nombre'
            name='nombre'
            autoComplete='nombre'

            value={state.nombre}
            onChange={handleInputChange}
          />

        </Box>

        <div style={{ height: 20 }}></div>

        <FormControl fullWidth>
          <InputLabel htmlFor="set-bot">Bot</InputLabel>
          <Select
            labelId="set-bot"
            id="bot"
            value={state.bot}
            onChange={(e) => setState({ ...state, 'bot': e.target.value })}
          >
            {
              props.bots.map((element) => {
                return <MenuItem value={element._id} key={element._id}><BotMenuItem item={element} /></MenuItem>
              })
            }
          </Select>
        </FormControl>


        <FormControl fullWidth>
          <InputLabel htmlFor="set-zona">Zona</InputLabel>
          <Select
            labelId="set-zona"
            id="zonaTarget"
            value={state.zonaTarget}
            onChange={(e) => setState({ ...state, 'zonaTarget': e.target.value })}
          >
            {
              props.zonas.map((element) => {
                return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
              })
            }
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel htmlFor="set-empresa">Empresa</InputLabel>
          <Select
            labelId="set-empresa"
            id="empresa"
            value={state.empresaTarget}
            onChange={(e) => setState({ ...state, 'empresaTarget': e.target.value })}
          >
            {
              props.empresas.map((element) => {
                return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
              })
            }
          </Select>
        </FormControl>

        <TextField
          margin='dense'
          size='small'
          variant='standard'
          fullWidth
          id="orden"
          label='Orden'
          name='orden'
          type={'number'}

          value={state.orden}
          onChange={handleInputChange}
        />

        <Box display='flex' justifyContent='flex-end' width={1}>

          {
            props.onDelete ?
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: '5px' }}
                onClick={() => setOpenConfirmationDialog(true)}
              >
                Borrar
              </Button>
              : <div></div>
          }

          <Button
            variant="contained"
            color="default"
            style={{ margin: '5px' }}
            onClick={() => props.cancel()}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => props.save(state)}
          >
            Guardar
          </Button>
        </Box>

      </Box>

    </Container>

  );
};
