import React, { useEffect, useState } from 'react';

import { Container, Box, Typography, Chip, List, ListItemText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { FormControl, InputLabel, Select, MenuItem, TextField, TextareaAutosize } from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import { ConfirmDialog } from '../../component/utils';

export function MomEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  console.log(props);

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  // para añadir tags
  const [tag, setTag] = useState('');

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  // añadimos un nuevo tag
  const addTag = () => {
    let tmp = state.tags;
    tmp.push(tag);
    setState({ ...state, tags: tmp });
    setTag('');
  }

  // borraos un tag
  const deleteTag = (element) => {
    let tmp = state.tags;
    tmp.splice(tmp.indexOf(element), 1);

    setState({ ...state, tags: tmp });
    setTag('');
  }

  return (
    <Container>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box>

        <Box>
          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            id="nombre"
            label='Nombre'
            name='nombre'

            value={state.nombre}
            onChange={handleInputChange}
          />

          <FormControl fullWidth>
            <InputLabel htmlFor="set-conocimientos">Conocimiento</InputLabel>
            <Select
              labelId="set-conocimientos"
              id="targetConocimiento"
              value={state.targetConocimiento}
              onChange={(e) => setState({ ...state, 'targetConocimiento': e.target.value })}
            >
              {
                props.conocimientos.map((element) => {
                  return <MenuItem value={element._id} key={element._id}>
                    <Box width={1} display={'flex'} justifyContent={'space-between'}>
                      <Typography>{element.nombre}</Typography>
                      <Box component="span" style={{ marginLeft: 15, width: 20, height: 20, borderRadius: 25, backgroundColor: element.color }} />
                    </Box>
                  </MenuItem>
                })
              }
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel htmlFor="set-segmento">Segmento</InputLabel>
            <Select
              labelId="set-segmento"
              id="targetSegmento"
              value={state.targetSegmento}
              onChange={(e) => setState({ ...state, 'targetSegmento': e.target.value })}
            >
              {
                props.segmentos.map((element) => {

                  if (element.hijos.length > 0) {
                    return element.hijos.map((hijo) => <MenuItem value={hijo._id} key={hijo._id}><Typography>{`(${element.nombre}) ${hijo.nombre}`}</Typography></MenuItem>);
                  }
                  else {
                    return <MenuItem value={element._id} key={element._id}><Typography>{element.nombre}</Typography></MenuItem>;
                  }
                }
                )
              }
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <TextareaAutosize
              id='descripcion'
              name='descripcion'
              wrap='true'
              rowsMin={10}
              style={{ width: '100%' }}

              value={state.descripcion}
              onChange={(e) => setState({ ...state, 'descripcion': e.target.value })}
            />
          </FormControl>


          <Box>

            <Box display='flex'>

              <TextField
                margin='dense'
                size='small'
                variant='standard'
                fullWidth
                id="tag"
                label='New Tag'
                name='tag'

                helperText={'3 carácteres mínimo'}

                value={tag}
                onChange={(e) => setTag(e.target.value)}

                onKeyUp={(e) => {
                  if (e.key === 'Enter' && tag.length >= 3) {
                    addTag();
                  }
                }}

              />

              <Button
                disabled={tag.length < 3}
                title={'añadir tag'}
                onClick={addTag}
                variant="contained"
                size='small'
                color="primary"
                style={{ margin: '5px' }}

              >
                <AddCircleIcon />
              </Button>


            </Box>


            {
              state.tags.map((element, index) => {
                return <Chip
                  label={element}
                  size='small'
                  color="primary"
                  style={{ margin: '3px' }}
                  key={element + index}
                  onClick={() => { deleteTag(element) }}
                />
              })
            }

          </Box>

          <div style={{ height: 20 }}></div>

          <Typography>{`Fases -> ${state.fases.length}`}</Typography>

        </Box>

        <div style={{ height: 20 }}></div>

        <Box display='flex' justifyContent='flex-end' width={1}>

          {
            props.onDelete ?
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: '5px' }}
                onClick={() => setOpenConfirmationDialog(true)}
              >
                Borrar
              </Button>
              : <div></div>
          }

          <Button
            variant="contained"
            color="default"
            style={{ margin: '5px' }}
            onClick={() => props.cancel()}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => props.save(state)}
          >
            Guardar
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => {

              let fases = [...state.fases];

              fases.push({ nombre: 'fase', orden: (parseInt(fases.length) + 1), secciones: [{ orden: 1, nombre: 'seccion', situaciones: [{ orden: 1, nombre: 'situacion 1' }] }] });


              setState({ ...state, "fases": fases });
            }}
          >
            Añadir Fase
          </Button>

        </Box>

      </Box>

    </Container >

  );
};