import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { Container, TextField, Avatar, IconButton } from '@material-ui/core';
import { FormControl, TextareaAutosize } from '@material-ui/core';

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

// utilidades
import { ConfirmDialog } from '../../component/utils';


export function BotJsonSkinViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // ref para el input de tipo file
  const hiddenFileInput = React.createRef();

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);


  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  return (
    <Container maxWidth='xs'>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box className='background-forms'>

        <Box className='m-2'>
          <Box display='flex' justifyContent="center">
            <img
              alt={state.nombre}
              src={state.imagenAvatar}
              style={{ margin: '10px', maxWidth: '100%', maxHeight: '200px' }}
            />
          </Box>
          <Box display='flex mr-2'>

            <input ref={hiddenFileInput} accept='image/*' type='file' style={{ display: 'none' }}
              onChange={e => {
                setState({
                  ...state,
                  imagenData: e.target.files[0],
                  imagenAvatar: URL.createObjectURL(e.target.files[0])
                });
              }} />
            <Avatar style={{ marginRight: '10px' }}>
              <IconButton onClick={() => { hiddenFileInput.current.click(); }} >
                <PhotoCameraIcon />
              </IconButton>
            </Avatar>

            <TextField
              margin='dense'
              size='small'
              variant='standard'
              fullWidth
              id="nombre"
              label='Nombre'
              name='nombre'
              autoComplete='nombre'

              value={state.nombre}
              onChange={handleInputChange}
            />

          </Box>

          <FormControl fullWidth>
            <TextareaAutosize
              id='skinJSON'
              name='skinJSON'
              placeholder='skinJSON'
              wrap='true'
              rowsMin={3}
              style={{ width: 200 }}

              value={state.skinJSON}
              onChange={(e) => setState({ ...state, 'skinJSON': e.target.value })}
            />
          </FormControl>

        </Box>

        <div style={{ height: 20 }}></div>

        <Box display='flex' justifyContent='flex-end' width={1}>

          {
            props.onDelete ?
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: '5px' }}
                onClick={() => setOpenConfirmationDialog(true)}
              >
                Borrar
              </Button>
              : <div></div>
          }

          <Button
            variant="contained"
            color="default"
            style={{ margin: '5px' }}
            onClick={() => props.cancel()}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => props.save(state)}
          >
            Guardar
          </Button>
        </Box>

      </Box>

    </Container>

  );
};
