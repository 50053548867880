import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Container, TextField, Typography } from '@material-ui/core';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export function PreguntaViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  // funcion para guardar la respuesta
  const saveRespuesta = (element) => {
    if (element == undefined) {
      let respuestas = [...state.respuestas];

      // buscamos el siguiente en orden
      let ultimoOrden = 1;
      if (respuestas.length > 0) {
        ultimoOrden = parseInt(respuestas.sort((a, b) => a.orden - b.orden).at(-1).orden) + 1
      }

      respuestas.push({ orden: ultimoOrden, descripcion: "nueva respuesta" });

      setState({ ...state, 'respuestas': respuestas });
    }
  };

  return (
    <Container maxWidth='xs'>

      <Box className='background-forms'>

        <Typography variant="body1" align="center">Preguntas</Typography>

        <Box>
          <TextField
            margin='dense'
            size='small'
            variant='standard'
            id="orden"
            label='Orden'
            name='orden'
            type={'number'}

            value={state.orden}
            onChange={handleInputChange}
          />

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            id="descripcion"
            label='Pregunta'
            name='descripcion'

            multiline
            rows={4}

            value={state.descripcion}
            onChange={handleInputChange}
          />

          <div style={{ height: 20 }}></div>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => saveRespuesta()}
          >
            ADD Respuesta
          </Button>

          <div style={{ height: 20 }}></div>

          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {
              state.respuestas.map((respuesta, indexRespuesta) => {
                return <TreeItem
                  style={{ margin: '5px' }}
                  nodeId={indexRespuesta.toString()}
                  label={
                    <Box display='flex'>
                      <Typography variant="body2" style={{ marginLeft: 10 }}>{`${respuesta.orden} ${respuesta.descripcion}`}</Typography>
                    </Box>
                  }
                  key={indexRespuesta} />
              })
            }
          </TreeView>

          <Box display='flex' justifyContent='flex-end' width={1}>

            {
              props.onDelete ?
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: '5px' }}
                  onClick={() => props.onDelete(state)}
                >
                  Borrar
                </Button>
                : <div></div>
            }

            <Button
              variant="contained"
              color="default"
              style={{ margin: '5px' }}
              onClick={() => props.cancel()}
            >
              Cancelar
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ margin: '5px' }}
              onClick={() => props.save(state)}
            >
              Guardar
            </Button>
          </Box>

        </Box>

      </Box>

    </Container>

  );
};