import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Container, IconButton, TextField, Typography, Slider } from '@material-ui/core';
import { FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import DeleteIcon from '@material-ui/icons/Delete';

import LinearProgress from '@material-ui/core/LinearProgress';

import { ContainerSkills } from '../../component/containerSkills';

// utilidades
import { ConfirmDialog } from '../../component/utils';


export function ItemTipoViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);


  // ref para el input de tipo file
  const hiddenFileInput = React.createRef();

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);


  return (
    <Container maxWidth='sm'>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box display='flex' alignItems='flex-start'>

        <Box className='background-forms'>

          <Box>
            <TextField
              margin='dense'
              size='small'
              variant='standard'
              fullWidth
              id="nombre"
              label='Nombre'
              name='nombre'
              autoComplete='nombre'

              value={state.nombre}
              onChange={handleInputChange}
            />

            <div style={{ height: 20 }}></div>

            <Box display='flex' justifyContent='flex-end' width={1}>

              {
                props.onDelete ?
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ margin: '5px' }}
                    onClick={() => setOpenConfirmationDialog(true)}
                  >
                    Borrar
                  </Button>
                  : <div></div>
              }

              <Button
                variant="contained"
                color="default"
                style={{ margin: '5px' }}
                onClick={() => props.cancel()}
              >
                Cancelar
              </Button>

              <Button
                variant="contained"
                color="primary"
                style={{ margin: '5px' }}
                onClick={() => props.save(state)}
              >
                Guardar
              </Button>
            </Box>

          </Box>

        </Box>

      </Box>
    </Container>

  );
};