import { Constantes } from '../util/constantes';


export const Simulacion = {
    addPlantilla,
    deletePlantilla,
    misPlantillas,
    uploadTareasCSV,
    downloadPlantillaTareasCSV,

    getPlantillaById,
    getReactFlow,

    addUbicacion,
    getUbicaciones,

    addSlide,
    deleteSlide,
    addSlideTarea,
    deleteSlideTarea,

    addTarea,
    deleteTarea,
    addTareaFromPlantilla,
    getTareas,

    addRespuestaTarea,
    deleteRespuestaTarea,
    getRespuestaTarea,

    addEventoRespuesta,
    deleteEventoRespuesta,

    addEventoTarea,
    deleteEventoTarea,

    getMoms,
    addMom,
    deleteMom,
    getConocimientos,

    //general
    postWithUpload,
    deleteGeneral,
    getGeneral


};

async function postWithUpload(data, URI) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeadersUpload(),
        body: data
    };

    const response = await fetch(`${Constantes.API_URL}${URI}`, requestOptions)
        .then((data) => {
            if (data.status !== 200) {
                return false;
            }
            else {
                return data.json();
            }
        })
        .then((data) => {
            return data.records;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });

    return response;
}


async function deleteGeneral(data, URI) {

    const requestOptions = {
        method: 'delete',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(data)
    };

    const response = await fetch(`${Constantes.API_URL}${URI}`, requestOptions)
        .then((data) => {
            if (data.status !== 200) {
                return false
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return false;
        });

    return response;
}

async function getGeneral(URI) {

    const requestOptions = {
        method: 'GET',
        headers: Constantes.getAuthHeaders()
    };

    const response = await fetch(`${Constantes.API_URL}${URI}`, requestOptions)
        .then((data) => {

            if (data) {
                return data.json()
            }
            else {
                return false;
            }

        })
        .then((info) => {
            return info;
        })
        .catch((error) => {
            return false;
        });

    return response;
}


// PLANTILLAS
async function addPlantilla(data) {
    return await postWithUpload(data, 'editor/simulacion/crear');
}

async function deletePlantilla(data) {
    return await deleteGeneral(data, 'editor/simulacion/delete');
}

async function misPlantillas() {
    return await getGeneral('editor/simulacion/getall');
}

async function uploadTareasCSV(data) {
    return await postWithUpload(data, 'editor/simulacion/uploadtareascsv');
}

async function downloadPlantillaTareasCSV(data) {
    return await postWithUpload(data, 'editor/simulacion/getdownloadtareascsv');
}

async function getPlantillaById(simulacion) {
    return await getGeneral(`editor/simulacion/getbyid?targetid=${simulacion}`);
}

async function getReactFlow(filtro) {
    // return await postWithUpload(filtro, `editor/simulacion/getreactflow?targetid=${filtro.id}&fase=${filtro.fase}&seccion=${filtro.seccion}`);
    return await postWithUpload(filtro, `editor/simulacion/getreactflow`);
}

// UBICACIONES
async function addUbicacion(data) {
    return await postWithUpload(data, 'editor/simulacion/addubicacion');
}

async function getUbicaciones(simulacion) {
    return await getGeneral(`editor/simulacion/getubicacion?targetid=${simulacion}`);
}



// SLIDES
async function addSlide(data) {
    return await postWithUpload(data, 'editor/simulacion/addslide');
}

async function deleteSlide(data) {
    return await deleteGeneral(data, 'editor/simulacion/deleteslide');
}

async function addSlideTarea(data) {
    return await postWithUpload(data, 'editor/simulacion/addslidetarea');
}

async function deleteSlideTarea(data) {
    return await deleteGeneral(data, 'editor/simulacion/deleteslidetarea');
}



// TAREAS
async function addTarea(data) {
    return await postWithUpload(data, 'editor/simulacion/addtarea');
}

async function deleteTarea(data) {
    return await deleteGeneral(data, 'editor/simulacion/deletetarea');
}

async function addTareaFromPlantilla(data) {
    return await postWithUpload(data, 'editor/simulacion/addtareafromplantilla');
}

async function getTareas(simulacion) {
    return await getGeneral(`editor/simulacion/gettarea?targetid=${simulacion}`);
}



// RESPUESTAS
async function addRespuestaTarea(data) {
    return await postWithUpload(data, 'editor/simulacion/addrespuestatarea');
}

async function deleteRespuestaTarea(data) {
    return await deleteGeneral(data, 'editor/simulacion/deleterespuestatarea');
}

async function getRespuestaTarea(tarea) {
    return await getGeneral(`editor/simulacion/getrespuestatarea?targetid=${tarea}`);
}



// EVENTOS
async function addEventoRespuesta(data) {
    return await postWithUpload(data, 'editor/simulacion/addeventorespuesta');
}

async function deleteEventoRespuesta(data) {
    return await deleteGeneral(data, 'editor/simulacion/deleteeventorespuesta');
}

async function addEventoTarea(data) {
    return await postWithUpload(data, 'editor/simulacion/addeventotarea');
}

async function deleteEventoTarea(data) {
    return await deleteGeneral(data, 'editor/simulacion/deleteeventotarea');
}


// MOMS
async function getMoms(id) {
    if (id === undefined) {
        return await getGeneral(`editor/world/getmom`);
    }
    else {
        return await getGeneral(`editor/world/getmom?id=${id}`);
    }
}

async function addMom(data) {
    return await postWithUpload(data, 'editor/world/addmom');
}

async function deleteMom(data) {
    return await deleteGeneral(data, 'editor/world/deletemom');
}

async function getConocimientos() {
    return await getGeneral(`editor/world/getconocimientos`);
}
