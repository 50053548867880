import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { LogroMenuItem } from '../logros/logroMenuItem';


export function EventoViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  return (

    <Box className='background-list-cards' m={1} p={1} width={1}>
      <Typography align="center" variant="body1">{state.tipo}</Typography>
      <Typography align="center" variant="body2">{state.data.info}</Typography>
      {
        state.data.atributo && state.data.valor ?
          <Typography align="center" variant="body2">{` [${state.data.atributo ? state.data.atributo : ''}] -> ${state.data.valor ? state.data.valor : ''}`}</Typography>
          : <div></div>
      }
      {
        state.data.logro ?
          <Box display={'flex'} justifyContent={'center'}><LogroMenuItem item={state.data.logro} /></Box>
          : <div></div>
      }
    </Box>

  );
};