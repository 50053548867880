import React, { useEffect, useState } from 'react';

import { Box, IconButton } from '@material-ui/core';
import { Button, ButtonGroup } from '@material-ui/core';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { Avatar, LinearProgress, Slider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { Container } from '@material-ui/core';
import { Dialog, DialogContent } from '@material-ui/core';

import { FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, TextField } from '@material-ui/core';


import DeleteIcon from '@material-ui/icons/Delete';



// utilidades
import { ColoresPaletaAzul } from '../../component/utils';

import ShowTableData from '../../component/showTableData';

// vista para elementos de tareas de tipo interaccion
import { TareaInteraccionViewEdit } from '../tareas/editorsimulacion/tareaInteraccionViewEdit';

// vista para elementos de tareas de tipo mensaje
import { TareaMensajeViewdEdit } from '../tareas/editorsimulacion/tareaMensajeViewEdit';

// vista para elementos de tareas de tipo build
import { TareaBuildViewEdit } from '../tareas/editorsimulacion/tareaBuildViewEdit';

// vista para elementos de tareas de tipo random
import { TareaRandomViewdEdit } from '../tareas/editorsimulacion/tareaRandomViewEdit';

// componente para la gestión de periodos de tiempo
import TranscursoTiempo from '../../component/transcursoTiempo';

import { ContainerSkills } from '../../component/containerSkills';
import { SituacionesViewWorldEdit } from './situacionesViewWorldEdit';


export function FaseViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // configuración de los steps
  const [steps, setSteps] = useState([]);
  const [stepSkipped, setStepSkipped] = useState([]);
  const [activeStep, setActiveStep] = useState(props.seccionShow);

  // indico la tarea que se va a añadir
  const [tareaFromPlantilla, setTareaFromPlantilla] = useState();

  // es la tarea que vamos a editar
  const [tareaEdicion, setTareaEdicion] = useState();

  // tab a mostrar para la fase
  const [tabIndexFase, setTabIndexFase] = useState(2);
  // tab a mostrar para la seccion
  const [tabIndexSeccion, setTabIndexSeccion] = useState(4);

  // para indicar cual es la situación seleccionada
  const [situacionSeleccionada, setSituacionSeleccionada] = useState('');


  const headersPlantillas = [
    { nombre: 'imagenAvatar', caption: 'Imagen', tipo: 'imagen', buscar: false },
    { nombre: 'tipo', caption: 'Tipo', tipo: 'texto', buscar: true },
    { nombre: 'ubicacionNombre', caption: 'Ubicación', tipo: 'texto', buscar: true },
    { nombre: 'nombre', caption: 'Tarea', tipo: 'texto', buscar: true },
    { nombre: 'botNombre', caption: 'Bot', tipo: 'texto', buscar: true },
    { nombre: 'tags', caption: 'Tags', tipo: 'array', buscar: true }
  ];


  const headersTareas = [
    { nombre: 'imagenAvatar', caption: 'Imagen', tipo: 'imagen', buscar: false },
    { nombre: 'tipo', caption: 'Tipo', tipo: 'texto', buscar: true },
    { nombre: 'orden', caption: 'Orden', tipo: 'texto', buscar: true },
    { nombre: 'canalComunicacion', caption: 'Canal', tipo: 'texto', buscar: true },
    { nombre: 'finalizarTarea', caption: 'Fin.Tarea', tipo: 'bool', buscar: true },
    { nombre: 'fase', caption: 'Fase', tipo: 'texto', buscar: true },
    { nombre: 'seccion', caption: 'Sección', tipo: 'texto', buscar: true },
    { nombre: 'situacion', caption: 'Situación', tipo: 'texto', buscar: true },
    { nombre: 'ubicacionNombre', caption: 'Ubicación', tipo: 'texto', buscar: true },
    { nombre: 'nombre', caption: 'Tarea', tipo: 'texto', buscar: true },
    { nombre: 'botNombre', caption: 'Bot', tipo: 'texto', buscar: true },
    { nombre: 'tags', caption: 'Tags', tipo: 'array', buscar: true }
  ];

  useEffect(() => {

    let fase = { ...props.item };

    fase.secciones.map((element) => {
      if (element.activa === undefined) {
        element.activa = false;
      }
      if (element.tareasGlobales === undefined) {
        element.tareasGlobales = false;
      }
      if (element.pasoSeccionAutomatico === undefined) {
        element.pasoSeccionAutomatico = false;
      }
      if (element.pasoSeccionPorID === undefined) {
        element.pasoSeccionPorID = false;
      }
      if (element.pasoSeccionPorTiempo === undefined) {
        element.pasoSeccionPorTiempo = false;
      }

      return element;
    });

    // si esta fase no tiene secciones, le añadimos una
    if (fase.secciones.length === 0) {
      fase.secciones.push({ orden: 0, nombre: 'seccion', tareasGlobales: false });
    }


    setState(fase);

  }, [props.item]);

  useEffect(() => {

    cargaStepsFromSecciones();

    if (activeStep > state.secciones.length) setActiveStep(0);

  }, [state.secciones]);


  useEffect(() => {
    if (tareaFromPlantilla !== undefined) {

      props.onSaveTareaFromPlantilla({ _id: tareaFromPlantilla._id, fase: state.nombre, seccion: state.secciones[activeStep].nombre, situacion: situacionSeleccionada })
        .then((element) => {
          if (element._id) {

            // mostramos el tab con las tareas
            setTabIndexSeccion(3);

            // mostramos la nueva tarea
            setTareaEdicion(element);

          }
        });

      setTareaFromPlantilla();
    }
  }, [tareaFromPlantilla]);

  useEffect(() => {
    setActiveStep(props.seccionShow);
  }, [props.seccionShow]);


  const cargaStepsFromSecciones = () => {
    // genero los steps
    let tmpSteps = [];

    if (state.secciones) {
      state.secciones.forEach((element) => tmpSteps.push(element.nombre));

      setSteps(tmpSteps);
    }
  }

  const siguienteStep = () => {
    if (activeStep < (state.secciones.length - 1)) {
      setActiveStep(activeStep + 1);
    }
  }

  const anteriorStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  }

  const resetStep = () => {
    setActiveStep(0);
  }


  const RequisitosFaseSkills =
    state.requisitos != undefined && state.requisitos.length > 0
      ? (
        <Box className='background-list-cards' m={1} width='300px' >
          <Typography align="center">{'Requisitos definidos'}</Typography>
          {
            state.requisitos.map((element, index) => {
              return <Box m={1} key={index} display='flex' alignItems='center'>

                <Avatar
                  src={element.imagenAvatar}
                  alt={element.nombre}
                  title={element.nombre} />

                <TextField
                  size='small'
                  variant='standard'
                  fullWidth
                  id="nivel"
                  label={element.nombre}
                  name='nivel'
                  type='number'
                  style={{ margin: 10 }}

                  value={element.valor}
                  onChange={(event) => {
                    let tmpSkill = state.requisitos;
                    tmpSkill[index].valor = event.target.value;
                    setState({ ...state, 'requisitos': tmpSkill });

                    if (props.onSave) props.onSave(state);
                  }}
                />


                <IconButton onClick={() => {
                  let tmpSkill = state.requisitos;
                  tmpSkill.splice(index, 1);
                  setState({ ...state, 'requisitos': tmpSkill });

                  if (props.onSave) props.onSave(state);
                }} >
                  <DeleteIcon />
                </IconButton>

              </Box>
            }
            )
          }
        </Box>
      )
      : <h3>No hay requisitos definidos</h3>;

  const ObjetivosFaseSkills =
    state.objetivos != undefined && state.objetivos.length > 0
      ? (
        <Box className='background-list-cards' m={1} width='300px'>
          <Typography align="center">{'Objetivos definidos'}</Typography>
          {
            state.objetivos.map((element, index) => {
              return <Box m={1} key={index} display='flex' alignItems='center'>

                <Avatar
                  src={element.imagenAvatar}
                  alt={element.nombre}
                  title={element.nombre} />

                <TextField
                  size='small'
                  variant='standard'
                  fullWidth
                  id="nivel"
                  label={element.nombre}
                  name='nivel'
                  type='number'
                  style={{ margin: 10 }}

                  value={element.valor}
                  onChange={(event) => {
                    let tmpSkill = state.objetivos;
                    tmpSkill[index].valor = event.target.value;
                    setState({ ...state, 'objetivos': tmpSkill });

                    if (props.onSave) props.onSave(state);
                  }}
                />


                <IconButton onClick={() => {
                  let tmpSkill = state.objetivos;
                  tmpSkill.splice(index, 1);
                  setState({ ...state, 'objetivos': tmpSkill });

                  if (props.onSave) props.onSave(state);
                }} >
                  <DeleteIcon />
                </IconButton>

              </Box>
            }
            )
          }
        </Box>
      )
      : <h3>No hay objetivos definidos</h3>;

  const RequisitosSeccionSkills =
    state.secciones[activeStep].requisitos != undefined && state.secciones[activeStep].requisitos.length > 0
      ? (
        <Box className='background-list-cards' m={1} width='300px'>
          <Typography align="center">{'Requisitos definidos'}</Typography>
          {
            state.secciones[activeStep].requisitos.map((element, index) => {
              return <Box m={1} key={index} display='flex' alignItems='center'>

                <Avatar
                  src={element.imagenAvatar}
                  alt={element.nombre}
                  title={element.nombre} />

                <Box width={1} m={1}>

                  <Typography align="center" variant="caption">{`${element.nombre} ${element.valor}`}</Typography>

                  <LinearProgress variant="determinate" value={parseInt(element.valor)} style={{ width: '100%', height: '10px', margin: '10px' }} />

                  <Slider value={parseInt(element.valor)} min={0} max={100} color='secondary' valueLabelDisplay="auto" marks={[{ value: 0, label: '0' }, { value: parseInt(element.maximo), label: element.maximo }]} onChange={(event, value) => {

                    let tmp = [...state.secciones];
                    tmp[activeStep].requisitos[index].valor = value;

                    setState({ ...state, 'secciones': tmp });

                    if (props.onSave) props.onSave(state);
                  }} />

                </Box>

                <IconButton onClick={() => {
                  let tmp = [...state.secciones];
                  tmp[activeStep].requisitos.splice(index, 1);

                  setState({ ...state, 'secciones': tmp });

                  if (props.onSave) props.onSave(state);
                }} >
                  <DeleteIcon />
                </IconButton>

              </Box>
            }
            )
          }
        </Box>
      )
      : <h3>No hay requisitos definidos</h3>;

  const ObjetivosSeccionSkills =
    state.secciones[activeStep].objetivos != undefined && state.secciones[activeStep].objetivos.length > 0
      ? (
        <Box className='background-list-cards' m={1} width='300px'>
          <Typography align="center">{'Objetivos definidos'}</Typography>
          {
            state.secciones[activeStep].objetivos.map((element, index) => {
              return <Box m={1} key={index} display='flex' alignItems='center'>

                <Avatar
                  src={element.imagenAvatar}
                  alt={element.nombre}
                  title={element.nombre} />

                <Box width={1} m={1}>

                  <Typography align="center" variant="caption">{`${element.nombre} ${element.valor}`}</Typography>

                  <LinearProgress variant="determinate" value={parseInt(element.valor)} style={{ width: '100%', height: '10px', margin: '10px' }} />

                  <Slider value={parseInt(element.valor)} min={0} max={100} color='secondary' valueLabelDisplay="auto" marks={[{ value: 0, label: '0' }, { value: parseInt(element.maximo), label: element.maximo }]} onChange={(event, value) => {

                    let tmp = [...state.secciones];
                    tmp[activeStep].objetivos[index].valor = value;

                    setState({ ...state, 'secciones': tmp });

                    if (props.onSave) props.onSave(state);
                  }} />

                </Box>

                <IconButton onClick={() => {
                  let tmp = [...state.secciones];
                  tmp[activeStep].objetivos.splice(index, 1);

                  setState({ ...state, 'secciones': tmp });

                  if (props.onSave) props.onSave(state);
                }} >
                  <DeleteIcon />
                </IconButton>

              </Box>
            }
            )
          }
        </Box>
      )
      : <h3>No hay objetivos definidos</h3>;


  const Situaciones =
    <Box p={2}>
      <SituacionesViewWorldEdit situaciones={state.secciones[activeStep].situaciones}
        skills={props.skills}
        tareasDeSeccion={props.tareas.filter(x => x.seccion === state.secciones[activeStep].nombre ) }
        onSave={(situaciones) => {
          let tmp = [...state.secciones];
          tmp[activeStep].situaciones = situaciones;

          setState({ ...state, 'secciones': tmp });

          if (props.onSave) props.onSave(state);
        }} />
    </Box>;



  const Secciones =
    <Box width={1} >
      <AppBar position="static" color='secondary' >
        <Tabs value={tabIndexSeccion} onChange={(e, value) => setTabIndexSeccion(value)} aria-label="simple tabs example">
          <Tab label="General" id={0} />
          <Tab label="Requisitos" id={1} />
          <Tab label="Objetivos" id={2} />
          <Tab label="Situaciones" id={3} />
          <Tab label="Tareas" id={4} />
          <Tab label="Tareas (Plantillas)" id={5} />
        </Tabs>
      </AppBar>

      {tabIndexSeccion === 0 && (
        <Container maxWidth='md'>
          <Box display='flex' flexDirection='column' m={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.secciones[activeStep].activa}
                  onChange={(e) => {

                    let tmp = [...state.secciones];
                    tmp[activeStep].activa = e.target.checked;

                    setState({ ...state, 'secciones': tmp });

                    if (props.onSave) props.onSave(state);

                  }}
                  name={"activa"}
                  color="primary"
                />
              }
              label="Sección activa"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.secciones[activeStep].tareasGlobales}
                  onChange={(e) => {

                    let tmp = [...state.secciones];
                    tmp[activeStep].tareasGlobales = e.target.checked;

                    setState({ ...state, 'secciones': tmp });

                    if (props.onSave) props.onSave(state);

                  }}
                  name={"tateasglobales"}
                  color="primary"
                />
              }
              label="Tareas Globales"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.secciones[activeStep].pasoSeccionAutomatico}
                  onChange={(e) => {

                    let tmp = [...state.secciones];
                    tmp[activeStep].pasoSeccionAutomatico = e.target.checked;

                    setState({ ...state, 'secciones': tmp });

                    if (props.onSave) props.onSave(state);

                  }}
                  name={"pasoSeccionAutomatico"}
                  color="primary"
                />
              }
              label="Paso sección automático (todas las Situaciones)"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.secciones[activeStep].seccionRandom}
                  onChange={(e) => {

                    let tmp = [...state.secciones];
                    tmp[activeStep].seccionRandom = e.target.checked;

                    setState({ ...state, 'secciones': tmp });

                    if (props.onSave) props.onSave(state);

                  }}
                  name={"seccionRandom"}
                  color="primary"
                />
              }
              label="Sección con Situaciones Random"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.secciones[activeStep].pasoSeccionPorID}
                  onChange={(e) => {

                    let tmp = [...state.secciones];
                    tmp[activeStep].pasoSeccionPorID = e.target.checked;

                    setState({ ...state, 'secciones': tmp });

                    if (props.onSave) props.onSave(state);

                  }}
                  name={"pasoSeccionPorID"}
                  color="primary"
                />
              }
              label="Paso sección por Tarea"
            />

            <Box width='350px'>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.secciones[activeStep].pasoSeccionPorTiempo}
                    onChange={(e) => {

                      let tmp = [...state.secciones];
                      tmp[activeStep].pasoSeccionPorTiempo = e.target.checked;

                      setState({ ...state, 'secciones': tmp });

                      if (props.onSave) props.onSave(state);

                    }}
                    name={"pasoSeccionPorTiempo"}
                    color="primary"
                  />
                }
                label="Paso sección por tiempo"
              />

              {
                state.secciones[activeStep].pasoSeccionPorTiempo
                  ? (<TranscursoTiempo

                    titulo={'Duración Sección'}
                    item={state.secciones[activeStep].pasoSeccionTiempo}
                    onChange={(element) => {

                      let tmp = [...state.secciones];
                      tmp[activeStep].pasoSeccionTiempo = element;

                      setState({ ...state, 'secciones': tmp });

                      if (props.onSave) props.onSave(state);
                    }}
                  />)
                  : <div></div>

              }

            </Box>
          </Box>
        </Container>
      )}


      {tabIndexSeccion === 1 && (

        <Box display='flex' justifyContent='center' p={2}>

          {RequisitosSeccionSkills}

          <ContainerSkills skills={props.skills} addSkill={(element) => {

            let tmp = [...state.secciones];

            if (!tmp[activeStep].requisitos) {
              tmp[activeStep].requisitos = [];
            }

            // si el skill ya está añadido, no lo vuelvo a añadir
            let existe = tmp[activeStep].requisitos.filter(x => x.id === element.id).length;

            // si no existe, lo añado
            if (existe === 0) {
              tmp[activeStep].requisitos.push(element);
            }

            setState({ ...state, 'secciones': tmp });

            if (props.onSave) props.onSave(state);
          }} />

        </Box>
      )}

      {tabIndexSeccion === 2 && (
        <Box display='flex' justifyContent='center' p={2}>
          {ObjetivosSeccionSkills}

          <ContainerSkills skills={props.skills} addSkill={(element) => {

            let tmp = [...state.secciones];

            if (!tmp[activeStep].objetivos) {
              tmp[activeStep].objetivos = [];
            }

            // si el skill ya está añadido, no lo vuelvo a añadir
            let existe = tmp[activeStep].objetivos.filter(x => x.id === element.id).length;

            // si no existe, lo añado
            if (existe === 0) {
              tmp[activeStep].objetivos.push(element);
            }

            setState({ ...state, 'secciones': tmp });

            if (props.onSave) props.onSave(state);
          }} />

        </Box>
      )}


      {tabIndexSeccion === 3 && (
        <Box>{Situaciones}</Box>
      )}

      {tabIndexSeccion === 4 && (
        <Box p={2}>
          <ButtonGroup color="secondary" aria-label="outlined primary button group">
            {
              props.tipostareas.map((element) => {

                return <Button
                  variant="contained"
                  style={{ backgroundColor: ColoresPaletaAzul[element] }}
                  key={element} onClick={() => {

                    // debemos tener selccionada una situación
                    if (!situacionSeleccionada) {
                      alert('Indique una situación');
                      return;
                    };

                    let data = {};
                    data.tipo = element;
                    data.fase = state.nombre;
                    data.seccion = state.secciones[activeStep].nombre;
                    data.situacion = situacionSeleccionada;

                    props.onSaveTarea(data)
                      .then((element) => {
                        if (element) {
                          // mostramos la nueva tarea
                          setTareaEdicion(element);
                        }
                      });

                  }}>{'Añadir ' + element}</Button>
              })
            }

          </ButtonGroup>

          <div style={{ height: 20 }}></div>

          <FormControl fullWidth>
            <InputLabel htmlFor="set-situaciones">Seleccione una situación para la nueva Tarea</InputLabel>
            <Select
              labelId="set-situaciones"
              placeholder='Seleccione una situación'
              id="situaciones"
              value={situacionSeleccionada}
              onChange={(e) => setSituacionSeleccionada(e.target.value)}
            >
              {
                state.secciones[activeStep].situaciones && state.secciones[activeStep].situaciones.sort((a,b) => parseInt(a.orden) - parseInt(b.orden) ).map((element, index) => {
                  return <MenuItem value={element.nombre} key={index}>{element.nombre}</MenuItem>
                })
              }
            </Select>
          </FormControl>


          <Typography align="center" variant="h6">{'Tareas de la sección ' + state.secciones[activeStep].nombre}</Typography>
          <ShowTableData headers={headersTareas} data={props.tareas.filter(x => x.seccion === state.secciones[activeStep].nombre ) } onClick={(element) => {
            setTareaEdicion(element);
          }} />

        </Box>
      )}

      {tabIndexSeccion === 5 && (
        <Box p={2} className='background-forms-secundario'>
          <Typography align="center" variant="h6">{'Plantillas de tareas disponibles'}</Typography>
          <ShowTableData headers={headersPlantillas} data={props.tareasPlantillas} onClick={(element) => {

            // debemos tener selccionada una situación
            if (!situacionSeleccionada) {
              alert('Indique una situación');
              return;
            };

            setTareaFromPlantilla(c => element);
          }} />
        </Box>
      )}


    </Box>;

  return (
    <Box width={1}>

      <Box bgcolor={ColoresPaletaAzul.ColorBg1} p={1}>
        <h3>{`Fase ${state.nombre} >> ${state.secciones && state.secciones.length > 0 ? `Sección ${state.secciones[activeStep].nombre}` : ''}`}</h3>
      </Box>

      <React.Fragment>

        <AppBar position="static">
          <Tabs value={tabIndexFase} onChange={(e, value) => setTabIndexFase(value)} aria-label="simple tabs example">
            <Tab label="Requisitos de Fase" id={0} />
            <Tab label="Objetivos de Fase" id={1} />
            <Tab label="Sección" id={2} />
          </Tabs>
        </AppBar>

        <div style={{ height: 20 }}></div>

        {tabIndexFase === 0 && (
          <Box display='flex' justifyContent='center'>
            {RequisitosFaseSkills}

            <ContainerSkills skills={props.skills} addSkill={(element) => {
              let tmp = state.requisitos ? state.requisitos : [];

              // si el skill ya está añadido, no lo vuelvo a añadir
              let existe = tmp.filter(x => x.id === element.id).length;

              // si no existe, lo añado
              if (existe === 0) {
                tmp.push(element);
              }

              setState({ ...state, 'requisitos': tmp });

              if (props.onSave) props.onSave(state);
            }} />

          </Box>
        )}

        {tabIndexFase === 1 && (
          <Box display='flex' justifyContent='center'>
            {ObjetivosFaseSkills}

            <ContainerSkills skills={props.skills} addSkill={(element) => {
              let tmp = state.objetivos ? state.objetivos : [];

              // si el skill ya está añadido, no lo vuelvo a añadir
              let existe = tmp.filter(x => x.id === element.id).length;

              // si no existe, lo añado
              if (existe === 0) {
                tmp.push(element);
              }

              setState({ ...state, 'objetivos': tmp });

              if (props.onSave) props.onSave(state);
            }} />

          </Box>
        )}

        {tabIndexFase === 2 && (
          <Box display='flex' justifyContent='center'>
            {Secciones}
          </Box>
        )}

      </React.Fragment>

      <div style={{ height: 20 }}></div>


      {tareaEdicion ? props.onSelect(tareaEdicion) : ''}

    </Box >

  );
};