import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

import { Container, IconButton, TextField, Typography, Slider } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core/';

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import DeleteIcon from '@material-ui/icons/Delete';

import LinearProgress from '@material-ui/core/LinearProgress';

import { ContainerSkills } from '../../component/containerSkills';

// utilidades
import { ConfirmDialog } from '../../component/utils';

// entity para funciones del mundo
import { World } from '../../entity/world';


export function PlayerViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  // para indicar el número de Bots a generar
  const [cuantosBots, setCuantosBots] = useState(0);

  // ref para el input de tipo file
  const hiddenFileInput = React.createRef();

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  useEffect(() => {
  }, [state.imagenAvatar]);

  // esta función añade un nuevo skill al player
  const addSkill = element => {
    let tmp = state.skills;

    // si el skill ya está añadido, no lo vuelvo a añadir
    let existe = tmp.filter(x => x.id === element.id).length;

    // si no existe, lo añado
    if (existe === 0) {
      tmp.push(element);
    }

    setState({ ...state, 'skills': tmp });
  }

  return (
    <Container>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box display='flex' alignItems='flex-start' justifyContent='center'>

        <Box className='background-forms'>

          <Box>
            <Box display='flex' justifyContent="center">
              <img
                alt={state.nombre}
                src={state.imagenAvatar}
                style={{ margin: '10px', maxWidth: '100%', maxHeight: '200px' }}
              />
            </Box>
            <Box display='flex'>

              <input ref={hiddenFileInput} accept='image/*' type='file' style={{ display: 'none' }}
                onChange={e => {
                  setState({
                    ...state,
                    imagenData: e.target.files[0],
                    imagenAvatar: URL.createObjectURL(e.target.files[0])
                  });
                }} />
              <Avatar style={{ marginRight: '10px' }}>
                <IconButton onClick={() => { hiddenFileInput.current.click(); }} >
                  <PhotoCameraIcon />
                </IconButton>
              </Avatar>

              <TextField
                margin='dense'
                size='small'
                variant='standard'
                fullWidth
                id="descripcion"
                label='Descripción'
                name='descripcion'
                autoComplete='descripcion'

                value={state.descripcion}
                onChange={handleInputChange}
              />

            </Box>

            <FormControl fullWidth>
              <InputLabel htmlFor="set-genero">Genero</InputLabel>
              <Select
                labelId="set-genero"
                id="genero"
                value={state.genero}
                onChange={(e) => setState({ ...state, 'genero': e.target.value })}
              >
                <MenuItem value={''} key={'seleccionar-genero'}>{'Seleccionar'}</MenuItem>
                <MenuItem value={'masculino'} key={'masculino'}>{'Masculino'}</MenuItem>
                <MenuItem value={'femenino'} key={'Femenino'}>{'Femenino'}</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel htmlFor="set-empresa">Empresa</InputLabel>
              <Select
                labelId="set-empresa"
                id="empresa"
                value={state.empresaTarget}
                onChange={(e) => setState({ ...state, 'empresaTarget': e.target.value })}
              >
                {
                  props.empresas.map((element) => {
                    return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
                  })
                }
              </Select>
            </FormControl>


            <div style={{ height: 20 }}></div>

            <Typography align="center" variant="caption">Genearar Bots</Typography>

            <div style={{ height: 10 }}></div>

            <Box display='flex' justifyContent='center' p={1}>

              <TextField
                margin='dense'
                size='small'
                variant='standard'
                id="cuantosBots"
                label='Número de Bots'
                name='cuantosBots'

                value={cuantosBots}
                onChange={(e) => setCuantosBots(e.target.value)}
              />

              <Button
                variant="contained"
                color="secondary"
                style={{ margin: '5px' }}
                onClick={() => {
                  let data = new FormData();

                  data.append('targetid', state._id);
                  data.append('cuantos', cuantosBots);

                  World.generarBots(data).then(() => {
                    props.onRefreshBots();
                    props.cancel();
                  });
                }}
              >
                Generar
              </Button>

            </Box>

            <div style={{ height: 20 }}></div>

            <Box display='flex' justifyContent='flex-end' width={1}>

              {
                props.onDelete ?
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ margin: '5px' }}
                    onClick={() => setOpenConfirmationDialog(true)}
                  >
                    Borrar
                  </Button>
                  : <div></div>
              }

              <Button
                variant="contained"
                color="default"
                style={{ margin: '5px' }}
                onClick={() => props.cancel()}
              >
                Cancelar
              </Button>

              <Button
                variant="contained"
                color="primary"
                style={{ margin: '5px' }}
                onClick={() => props.save(state)}
              >
                Guardar
              </Button>
            </Box>

          </Box>

          {
            state.skills && state.skills.length > 0
              ? (
                <Box className='background-list-cards' m={1} p={1} >
                  {
                    state.skills.map((element, index) => {
                      return <Box m={1} key={index} display='flex' alignItems='center'>

                        <Avatar
                          src={element.imagenAvatar}
                          alt={element.nombre}
                          title={element.nombre} />

                        <TextField
                          size='small'
                          variant='standard'
                          fullWidth
                          id="nivel"
                          label={element.nombre}
                          name='nivel'
                          type='number'
                          style={{ margin: 10 }}

                          value={element.valor}
                          onChange={(event) => {
                            let tmpSkill = state.skills;
                            tmpSkill[index].valor = event.target.value;
                            setState({ ...state, 'skills': tmpSkill });
                          }}
                        />

                        <IconButton onClick={() => {
                          let tmpSkill = state.skills;
                          tmpSkill.splice(index, 1);
                          setState({ ...state, 'skills': tmpSkill });
                        }} >
                          <DeleteIcon />
                        </IconButton>

                      </Box>
                    }
                    )
                  }
                </Box>
              )
              : ''
          }


        </Box>

        <ContainerSkills skills={props.skills} addSkill={addSkill} />

      </Box>

    </Container>

  );
};