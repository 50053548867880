import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import { Button, Divider, Typography, ButtonGroup, Grid } from '@material-ui/core';
import { Avatar, Slider, LinearProgress } from '@material-ui/core';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem, IconButton } from '@material-ui/core/';

import DeleteIcon from '@material-ui/icons/Delete';

// entity para la simulacion
import { Simulacion } from '../../../entity/simulacion';
import { World } from '../../../entity/world';
import { EventoViewWorldEdit } from '../../evento/eventoViewWorldEdit';

import { ContainerSkills } from '../../../component/containerSkills';
import { ColoresPaletaAzul } from '../../../component/utils';

import { LogroMenuItem } from '../../logros/logroMenuItem';


export function RespuestaViewEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // tipos de eventos
  const [tiposEventos, setTiposEventos] = useState([]);

  // atributos para modificar de un bot
  const [listaAtributoBotModificar, setListaAtributoBotModificar] = useState([]);

  // evento seleccionado
  const [evento, setEvento] = useState('');
  // tarea seleccionada
  const [tarea, setTarea] = useState('');
  // atributo seleccionado
  const [atributo, setAtributo] = useState('');
  // logro seleccionado
  const [logro, setLogro] = useState('');
  const [logroNombre, setLogroNombre] = useState('');

  // valor del atributo seleccionado
  const [valorAtributo, setValorAtributo] = useState('');


  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // función que controla los cambios en los checkbox
  const handleInputChangeChecked = e => {
    setState({ ...state, [e.target.name]: e.target.checked })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);


  // cargamos los tipos de eventos
  // cargamos los atributos que se pueden modificar de un bot
  useEffect(() => {
    World.getTiposEventos().then((data) => {
      setTiposEventos(data.records);
    });

    World.getAtributosBotModificar().then((data) => {
      setListaAtributoBotModificar(data.records);
    });
  }, []);


  // funcion para añadr un evento de mensaje
  const saveEvento = (element) => {
    let data = new FormData();
    data.append('targetid', state._id); // id de la respuesta
    data.append('tipo', evento);

    if (element) {
      data.append('id', element.id); // id del evento a modificar
    }

    let content = {};

    if (tarea) {

      let tareaSeleccionada = props.tareas.filter(x => x._id === tarea)[0];

      content.tareaTarget = tareaSeleccionada._id;
      content.simulacionTarget = tareaSeleccionada.simulacionTarget;
      content.info = tareaSeleccionada.nombre;
    }

    content.atributo = atributo;
    content.valor = valorAtributo;

    let tmpLogro = { ...logro };
    tmpLogro.nombre = logroNombre;
    content.logro = tmpLogro;

    data.append('data', JSON.stringify(content));


    Simulacion.addEventoRespuesta(data).then(() => {
      // recargamos esta respuesta
      Simulacion.getRespuestaTarea(state.tareaTarget).then((data) => {
        let respuesta = data.records.filter(x => x._id === state._id);

        setState(respuesta[0]);
      });
    });
  };

  // funcion para borrar un evento de mensaje
  const deleteEvento = (element) => {

    Simulacion.deleteEventoRespuesta({ targetid: state._id, id: element }).then(() => {
      // recargamos esta respuesta
      Simulacion.getRespuestaTarea(state.tareaTarget).then((data) => {
        let respuesta = data.records.filter(x => x._id === state._id);

        setState(respuesta[0]);
      });
    });

  };

  // esta función añade un nuevo skill al item
  const addSkill = element => {
    let tmp = state.skills;

    // si el skill ya está añadido, no lo vuelvo a añadir
    let existe = tmp.filter(x => x.id === element.id).length;

    // si no existe, lo añado
    if (existe === 0) {
      tmp.push(element);
    }

    setState({ ...state, 'skills': tmp });
  }


  return (
    <Box className='background-forms' display='flex'>

      <Grid container spacing={1}>

        <Grid item md={6}>

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            id="orden"
            label='Orden'
            name='orden'
            autoComplete='orden'
            type={'number'}

            InputProps={{ inputProps: { min: 0 } }}

            value={state.orden}
            onChange={handleInputChange}
          />

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            id="descripcion"
            label='Descripcion'
            name='descripcion'

            multiline
            rows={5}

            value={state.descripcion}
            onChange={handleInputChange}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={state.finalizarTarea}
                onChange={handleInputChangeChecked}
                name="finalizarTarea"
                color="primary"
              />
            }
            label="Finalizar tarea con esta respuesta"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={state.correcta}
                onChange={handleInputChangeChecked}
                name="correcta"
                color="primary"
              />
            }
            label="Esta es la respuesta correcta"
          />

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            id="mensajeCorrecto"
            label='Mensaje a mostrar si es correcto'
            name='mensajeCorrecto'
            autoComplete='mensajeCorrecto'

            value={state.mensajeCorrecto}
            onChange={handleInputChange}
          />

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            id="mensajeIncorrecto"
            label='Mensaje a mostrar si es incorrecto'
            name='mensajeIncorrecto'
            autoComplete='mensajeIncorrecto'

            value={state.mensajeIncorrecto}
            onChange={handleInputChange}
          />

          <FormControl fullWidth>
            <InputLabel htmlFor="set-animacion">Animación</InputLabel>
            <Select
              labelId="set-animacion"
              id="animacion"
              value={state.animacion === undefined ? '' : state.animacion}
              onChange={(e) => setState({ ...state, 'animacion': e.target.value })}
            >
              {
                props.animaciones.map((element) => {
                  return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
                })
              }
            </Select>
          </FormControl>

          <div style={{ height: 20 }}></div>

          <Box display='flex' justifyContent='flex-end' width={1}>

            <Button
              variant="contained"
              color="default"
              style={{ margin: '5px' }}
              onClick={() => props.cancel()}
            >
              Cancelar
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ margin: '5px' }}
              onClick={() => props.save(state)}
            >
              Guardar Respuesta
            </Button>
          </Box>

          <h3>Skills a modificar con esta respuesta</h3>

          <Box display='flex'>
            {
              state.skills && state.skills.length > 0
                ? (
                  <Box className='background-list-cards' m={1} p={1} >
                    {
                      state.skills.map((element, index) => {
                        return <Box m={1} key={element.id + element.nombre} display='flex' alignItems='center'>

                          <Avatar
                            src={element.imagenAvatar}
                            alt={element.nombre}
                            title={element.nombre} />

                          <TextField
                            size='small'
                            variant='standard'
                            fullWidth
                            id="nivel"
                            label={element.nombre}
                            name='nivel'
                            type='number'
                            style={{ margin: 10 }}

                            value={element.valor}
                            onChange={(event) => {
                              let tmpSkill = state.skills;
                              tmpSkill[index].valor = event.target.value;
                              setState({ ...state, 'skills': tmpSkill });
                            }}
                          />

                          <IconButton onClick={() => {
                            let tmpSkill = state.skills;
                            tmpSkill.splice(index, 1);
                            setState({ ...state, 'skills': tmpSkill });
                          }} >
                            <DeleteIcon />
                          </IconButton>

                        </Box>
                      }
                      )
                    }
                  </Box>
                )
                : ''
            }

            <ContainerSkills skills={props.skills} addSkill={addSkill} />

          </Box>


        </Grid>

        <Grid item md={6}>

          <Box width={1} p={1} bgcolor={ColoresPaletaAzul.ColorBg2} borderRadius={10}>

            <h3>Acciones a realizar en la respuesta</h3>

            <FormControl fullWidth>
              <InputLabel htmlFor='evento'>Evento</InputLabel>
              <Select
                id="evento"
                value={evento}
                onChange={(e) => setEvento(e.target.value)}
              >
                {
                  tiposEventos.map((element) => {
                    return <MenuItem value={element.valor} key={element.valor}>{element.nombre}</MenuItem>
                  })
                }
              </Select>
            </FormControl>

            <FormControl fullWidth disabled={evento.toLowerCase().indexOf('tarea') < 0}>
              <InputLabel htmlFor="set-tarea">Tarea</InputLabel>
              <Select
                labelId="set-tarea"
                id="tarea"
                value={tarea}
                onChange={(e) => setTarea(e.target.value)}
              >
                {
                  props.tareas.map((element) => {
                    return <MenuItem value={element._id} key={element._id}>
                      <Box width={1}>
                        <Typography align="center" variant="caption">{element.nombre}</Typography>
                        <Divider />
                      </Box>
                    </MenuItem>
                  })
                }
              </Select>
            </FormControl>

            <FormControl fullWidth disabled={evento !== 'ModificarAtributoJugador'}>
              <InputLabel htmlFor="set-atributo">Atributo</InputLabel>
              <Select
                labelId="set-atributo"
                id="atributo"
                value={atributo}
                onChange={(e) => setAtributo(e.target.value)}
              >
                {
                  listaAtributoBotModificar.filter(x => x.evento === evento).map((element, index) => {
                    return <MenuItem value={element.valor} key={index}>{element.nombre}</MenuItem>
                  })
                }
              </Select>
            </FormControl>

            <Box className='background-list-cards'>
              <FormControl fullWidth disabled={evento !== 'AsignarLogro'}>
                <InputLabel htmlFor="set-logro">Logro</InputLabel>
                <Select
                  labelId="set-logro"
                  id="logro"
                  value={logro}
                  onChange={(e) => {
                    setLogro(e.target.value);
                    setLogroNombre(e.target.value.nombre);
                  }}
                >
                  {
                    props.logros.map((element, index) => {
                      return <MenuItem value={element} key={index}><LogroMenuItem item={element} /></MenuItem>
                    })
                  }
                </Select>
              </FormControl>

              <TextField
                margin='dense'
                size='small'
                variant='standard'
                fullWidth
                placeholder='Texto para el logro'

                style={{ display: (evento !== 'AsignarLogro' && logro === '' ? 'none' : 'block') }}

                value={logroNombre}
                onChange={(e) => setLogroNombre(e.target.value)}
              />
            </Box>


            <TextField
              margin='dense'
              size='small'
              variant='standard'
              fullWidth
              label='Valor a añadir al atributo'

              disabled={evento !== 'ModificarAtributoJugador'}
              value={valorAtributo}
              onChange={(e) => setValorAtributo(e.target.value)}
            />

            <div style={{ height: 20 }}></div>

            <Box display='flex' justifyContent='flex-end' width={1}>

              <Button
                variant="contained"
                color="default"
                style={{ margin: '5px' }}
                onClick={() => { saveEvento() }}
                disabled={evento === ''}
              >
                ADD Acción
              </Button>

            </Box>

            <Box m={1}>
              {
                state.mensajesLanzar.map((element, index) => {
                  return (<Box display='flex' key={index}>
                    <EventoViewWorldEdit item={element} />

                    <IconButton onClick={() => {
                      deleteEvento(element.id)
                    }} >
                      <DeleteIcon />

                    </IconButton>

                  </Box>);
                })
              }
            </Box>

          </Box>

        </Grid>

      </Grid >

    </Box>

  );
};