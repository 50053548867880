import { Constantes } from '../util/constantes';


export const World = {
    addSegmento,
    deleteSegmento,
    getSegmentos,

    addUbicacion,
    deleteUbicacion,
    getUbicaciones,

    addZona,
    deleteZona,
    getZonas,

    addPlayer,
    deletePlayer,
    getPlayer,

    addBot,
    deleteBot,
    getBot,
    sendSocketMessageBot,
    generarBots,

    addSkill,
    deleteSkill,
    getSkill,

    addItem,
    deleteItem,
    getItem,
    getItemTipo,
    addItemTipo,
    deleteItemTipo,

    addTarea,
    deleteTarea,
    getTareas,
    addSlideTarea,
    deleteSlideTarea,

    addRespuestaTarea,
    deleteRespuestaTarea,
    getRespuestaTarea,

    getTiposTareas,
    getTiposCanales,
    getTiposEventos,
    getAtributosBotModificar,

    addEmpresa,
    deleteEmpresa,
    getEmpresa,


    //general
    postWithUpload,
    deleteGeneral,
    getGeneral,

    // animaciones
    addAnimacion,
    getAnimaciones,
    deleteAnimacion,

    // niveles
    addNivel,
    getNiveles,
    deleteNivel,

    // cuestionarios
    addCuestionario,
    addSlideCuestionario,
    getCuestionarios,
    deleteCuestionario,
    
    // logros
    addLogro,
    getLogros,
    deleteLogro,

    // botsjsonskin
    addBotsJsonSkin,
    getBotsJsonSkin,
    deleteBotsJsonSkin,

    // email
    addEmailPlantilla,
    getEmailPlantillas,
    getEmailPalabrasClave

};


async function postWithUpload(data, URI) {

    const requestOptions = {
        method: 'post',
        headers: Constantes.getAuthHeadersUpload(),
        body: data
    };

    const response = await fetch(`${Constantes.API_URL}${URI}`, requestOptions)
        .then((data) => {
            if (data.status !== 200) {
                return false
            }
            else {
                return data.json();
            }
        })
        .then((data) => {
            return data.records;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });

    return response;
}


async function deleteGeneral(data, URI) {

    const requestOptions = {
        method: 'delete',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(data)
    };

    const response = await fetch(`${Constantes.API_URL}${URI}`, requestOptions)
        .then((data) => {
            if (data.status !== 200) {
                return data.json();
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return false;
        });

    return response;
}

async function getGeneral(URI) {

    const requestOptions = {
        method: 'GET',
        headers: Constantes.getAuthHeaders()
    };

    const response = await fetch(`${Constantes.API_URL}${URI}`, requestOptions)
        .then((data) => {

            if (data) {
                return data.json()
            }
            else {
                return false;
            }

        })
        .then((info) => {
            return info;
        })
        .catch((error) => {
            return false;
        });

    return response;
}


// SEGMENTOS
async function addSegmento(data) {
    return await postWithUpload(data, 'editor/world/addsegmento');
}

async function deleteSegmento(data) {
    return await deleteGeneral(data, 'editor/world/deletesegmento');
}

async function getSegmentos(segmento) {
    return await getGeneral(`editor/world/getsegmento?targetid=${segmento}`);
}


// UBICACIONES
async function addUbicacion(data) {
    return await postWithUpload(data, 'editor/world/addubicacion');
}

async function deleteUbicacion(data) {
    return await deleteGeneral(data, 'editor/world/deleteubicacion');
}

async function getUbicaciones() {
    return await getGeneral('editor/world/getubicacion');
}

// ZONAS
async function addZona(data) {
    return await postWithUpload(data, 'editor/world/addzona');
}

async function deleteZona(data) {
    return await deleteGeneral(data, 'editor/world/deletezona');
}

async function getZonas() {
    return await getGeneral('editor/world/getzona');
}



// PLAYER
async function addPlayer(data) {
    return await postWithUpload(data, 'editor/world/addplayer');
}

async function deletePlayer(data) {
    return await deleteGeneral(data, 'editor/world/deleteplayer');
}

async function getPlayer() {
    return await getGeneral('editor/world/getplayer');
}


// BOTS
async function addBot(data) {
    return await postWithUpload(data, 'editor/world/addbot');
}

async function deleteBot(data) {
    return await deleteGeneral(data, 'editor/world/deletebot');
}

async function getBot() {
    return await getGeneral('editor/world/getbot');
}

async function sendSocketMessageBot(data) {
    return await postWithUpload(data, 'editor/world/sendsocketmessagetobot');
}

async function generarBots(data) {
    return await postWithUpload(data, 'editor/world/generabotfromplantilla');
}


// SKILLS
async function addSkill(data) {
    return await postWithUpload(data, 'editor/world/addskill');
}

async function deleteSkill(data) {
    return await deleteGeneral(data, 'editor/world/deleteskill');
}

async function getSkill() {
    return await getGeneral('editor/world/getskill');
}



// ITEMS
async function addItem(data) {
    return await postWithUpload(data, 'editor/world/additem');
}

async function deleteItem(data) {
    return await deleteGeneral(data, 'editor/world/deleteitem');
}

async function getItem() {
    return await getGeneral('editor/world/getitem');
}

async function getItemTipo() {
    return await getGeneral('editor/world/getitemtipo');
}

async function addItemTipo(data) {
    return await postWithUpload(data, 'editor/world/additemtipo');
}

async function deleteItemTipo(data) {
    return await deleteGeneral(data, 'editor/world/deleteitemtipo');
}


// TAREAS
async function addTarea(data) {
    return await postWithUpload(data, 'editor/world/addtarea');
}

async function deleteTarea(data) {
    return await deleteGeneral(data, 'editor/world/deletetarea');
}

async function getTareas() {
    return await getGeneral('editor/world/gettarea');
}

async function addSlideTarea(data) {
    return await postWithUpload(data, 'editor/world/addslidetarea');
}

async function deleteSlideTarea(data) {
    return await deleteGeneral(data, 'editor/world/deleteslidetarea');
}

async function addRespuestaTarea(data) {
    return await postWithUpload(data, 'editor/world/addrespuestatarea');
}

async function deleteRespuestaTarea(data) {
    return await deleteGeneral(data, 'editor/world/deleterespuestatarea');
}


async function getRespuestaTarea(tarea) {
    return await getGeneral(`editor/world/getrespuestatarea?targetid=${tarea}`);
}

async function getTiposTareas() {
    return await getGeneral('editor/world/gettipostareas');
}

async function getTiposCanales() {
    return await getGeneral('editor/world/getcanalcomunicaciontareas');
}

async function getTiposEventos() {
    return await getGeneral('editor/world/gettiposeventos');
}

async function getAtributosBotModificar() {
    return await getGeneral('editor/world/getatribusbotmodificar');
}

// Empresas
async function addEmpresa(data) {
    return await postWithUpload(data, 'editor/world/addempresa');
}

async function deleteEmpresa(data) {
    return await deleteGeneral(data, 'editor/world/deleteempresa');
}

async function getEmpresa() {
    return await getGeneral('editor/world/getempresa');
}

// animaciones
async function addAnimacion(data) {
    return await postWithUpload(data, 'editor/world/addanimacion');
}

async function deleteAnimacion(data) {
    return await deleteGeneral(data, 'editor/world/deleteanimacion');
}

async function getAnimaciones() {
    return await getGeneral('editor/world/getanimaciones');
}

// niveles
async function addNivel(data) {
    return await postWithUpload(data, 'editor/world/addnivel');
}

async function deleteNivel(data) {
    return await deleteGeneral(data, 'editor/world/deletenivel');
}

async function getNiveles() {
    return await getGeneral('editor/world/getniveles');
}

// cuestionarios
async function addCuestionario(data) {
    return await postWithUpload(data, 'editor/world/addcuestionario');
}

async function addSlideCuestionario(data) {
    return await postWithUpload(data, 'editor/world/addslidecuestionario');
}

async function deleteCuestionario(data) {
    return await deleteGeneral(data, 'editor/world/deletecuestionario');
}

async function getCuestionarios() {
    return await getGeneral('editor/world/getcuestionarios');
}

// logros
async function addLogro(data) {
    return await postWithUpload(data, 'editor/world/addlogro');
}

async function deleteLogro(data) {
    return await deleteGeneral(data, 'editor/world/deletelogro');
}

async function getLogros() {
    return await getGeneral('editor/world/getlogros');
}

// BotsJsonSkin
async function addBotsJsonSkin(data) {
    return await postWithUpload(data, 'editor/world/addbotsjsonskin');
}

async function deleteBotsJsonSkin(data) {
    return await deleteGeneral(data, 'editor/world/deletebotsjsonskin');
}

async function getBotsJsonSkin() {
    return await getGeneral('editor/world/getbotsjsonskin');
}


// email plantillas
async function addEmailPlantilla(data) {
    return await postWithUpload(data, 'email/addplantilla');
}

async function getEmailPlantillas() {
    return await getGeneral('email/getplantillas');
}

async function getEmailPalabrasClave() {
    return await getGeneral('email/getpalabrasclave');
}
