import React from 'react';

import { Box, Avatar, Typography } from '@material-ui/core';

export function LogroMenuItem(props) {

  return (
    <Box display='flex' alignItems='center'>
      <Avatar src={props.item.imagenAvatar} style={{ marginRight: '15px' }} />
      <Typography variant='body1'>{props.item.nombre}</Typography>
    </Box>
  );
};