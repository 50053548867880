import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { Container, IconButton, TextField } from '@material-ui/core';
import { AppBar, Tabs, Tab } from '@material-ui/core';

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import DeleteIcon from '@material-ui/icons/Delete';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core/';

// entity para la simulacion
import { Simulacion } from '../../../entity/simulacion';
import { World } from '../../../entity/world';

// vista para elementos de slides
import { SlideViewWorld } from '../../slide/slideViewWorld';
import { SlideViewWorldEdit } from '../../slide/slideViewWorldEdit';

import { EventoViewWorldEdit } from '../../evento/eventoViewWorldEdit';

// componente para la gestión de periodos de tiempo
import TranscursoTiempo from '../../../component/transcursoTiempo';

// utilidades
import { ConfirmDialog } from '../../../component/utils';

import { BotMenuItem } from '../../bot/botMenuItem';


export function TareaMensajeViewdEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // inicializamos el hook de elementShow
  const [elementShow, setElementShow] = useState(undefined);

  // tipos de eventos
  const [tiposEventos, setTiposEventos] = useState([]);


  // evento seleccionado
  const [evento, setEvento] = useState('');
  // tarea seleccionada  
  const [tarea, setTarea] = useState('');
  // dinero a modificar para el evento
  const [dineroEvento, setDineroEvento] = useState(0);
  // animo a modificar para el evento
  const [animoEvento, setAnimoEvento] = useState(0);


  // inicializamos el hook de la lista de secciones
  const [seccionesList, setSeccionesList] = useState([]);

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  // tab a mostrar
  const [tabIndex, setTabIndex] = useState(0);


  // ref para el input de tipo file
  const hiddenFileInput = React.createRef();

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  useEffect(() => {
  }, [state.imagenAvatar]);

  // cargamos los tipos de eventos
  useEffect(() => {
    World.getTiposEventos().then((data) => {
      setTiposEventos(data.records);
    });
  }, []);

  // comprobamos si hemos cambiado de registro fases
  useEffect(() => {
    let tmp = this.props.fases.filter(x => x.id === state.fase);

    if (tmp.length > 0) {
      setSeccionesList(tmp[0].secciones);
    }
    else {
      setSeccionesList([]);
    }

  }, [state.fase]);


  // funcion para guardar el slide
  const saveSlide = (element) => {
    let data = new FormData();
    data.append('targetid', state._id);

    if (element) {
      data.append('id', element.id);
      data.append('descripcion', element.descripcion.length === 0 ? " " : element.descripcion);
      data.append('orden', element.orden);
      data.append('link', element.link.length === 0 ? " " : element.link);
      data.append('enviarImagen', element.enviarImagen ? element.enviarImagen : false);
      data.append('animacion', element.animacion);

      // si hemos indicado una nueva imagen, la enviamos
      if (element.imagenData) {
        data.append('fichero', element.imagenData);
      }

    }

    Simulacion.addSlideTarea(data).then(() => {
      Simulacion.getTareas(state.simulacionTarget)
        .then((data) => {
          if (data.records) {
            let tareas = data.records.filter(x => x._id.toString() === state._id.toString());

            if (tareas.length > 0) {
              setState({ ...state, 'slides': tareas[0].slides });
            }
          }
        });
    });

  };


  // funcion para añadr un evento de mensaje
  const saveEvento = (element) => {
    let data = new FormData();
    data.append('targetid', state._id); // id de la tarea
    data.append('tipo', evento);

    if (element) {
      data.append('id', element.id); // id del evento a modificar
    }

    let content = {};

    if (tarea) {
      let tareaSeleccionada = props.tareas.filter(x => x._id === tarea)[0];

      content.tareaTarget = tareaSeleccionada.id;
      content.simulacionTarget = tareaSeleccionada.simulacionTarget;
      content.info = tareaSeleccionada.nombre;

    }

    content.dinero = dineroEvento;
    content.animo = animoEvento;

    data.append('data', JSON.stringify(content));

    Simulacion.addEventoTarea(data).then(() => {
      // recargamos esta tarea
      Simulacion.getTareas(state.simulacionTarget).then((data) => {
        let tarea = data.records.filter(x => x._id === state._id);

        setState(tarea[0]);

        setDineroEvento(0);
        setAnimoEvento(0);
      });
    });
  };

  // funcion para borrar un evento de mensaje
  const deleteEvento = (element) => {

    Simulacion.deleteEventoTarea({ targetid: state._id, id: element }).then(() => {
      // recargamos esta tarea
      Simulacion.getTareas(state.simulacionTarget).then((data) => {
        let tarea = data.records.filter(x => x._id === state._id);

        setState(tarea[0]);
      });
    });

  };

  const deleteSlide = (id) => {
    Simulacion.deleteSlideTarea({ targetid: state._id, id: id }).then((respuesta) => {
      if (respuesta) {
        props.onCargar();
      }
    });
  }

  return (
    <Container maxWidth='md'>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box className='background-forms'>

        <Box>

          {
            elementShow
              ? (<Dialog open={elementShow.id !== undefined} maxWidth='md' fullWidth >
                <DialogContent>
                  <SlideViewWorldEdit item={elementShow} animaciones={props.animaciones} save={(data) => saveSlide(data)} cancel={() => setElementShow()} />
                </DialogContent>
              </Dialog>)
              : ''
          }

          <AppBar position="static">
            <Tabs value={tabIndex} onChange={(e, value) => setTabIndex(value)} aria-label="simple tabs example">
              <Tab label="General" id={0} />
              <Tab label="Slides" id={1} />
              <Tab label="Mensajes" id={2} />

            </Tabs>
          </AppBar>

          {tabIndex === 0 && (
            <Box>

              <Typography align="center" variant="h5" style={{ margin: '10px' }}>{'Tarea de tipo Mensaje'}</Typography>

              <Box>
                <input ref={hiddenFileInput} accept='image/*' type='file' style={{ display: 'none' }}
                  onChange={e => {
                    setState({
                      ...state,
                      imagenData: e.target.files[0],
                      imagenAvatar: URL.createObjectURL(e.target.files[0])
                    });
                  }} />
                <Avatar style={{ marginRight: '10px' }}>
                  <IconButton onClick={() => { hiddenFileInput.current.click(); }} >
                    <PhotoCameraIcon />
                  </IconButton>
                </Avatar>

                <Box display='flex' justifyContent='center' width={1}>
                  <img
                    alt={state.nombre}
                    src={state.imagenAvatar}
                    style={{ margin: '10px', maxWidth: '100%', maxHeight: '200px' }}
                  />
                </Box>

              </Box>

              <Container maxWidth='xl'>
                <Box>

                  <TextField
                    margin='dense'
                    size='small'
                    variant='standard'
                    fullWidth
                    id="nombre"
                    label='Texto abreviado'
                    name='nombre'
                    autoComplete='nombre'

                    value={state.nombre}
                    onChange={handleInputChange}
                  />

                  <FormControl fullWidth>
                    <InputLabel htmlFor="set-ubicacion">Ubicación</InputLabel>
                    <Select
                      labelId="set-ubicacion"
                      id="ubicacion"
                      value={state.ubicacion}
                      onChange={(e) => setState({ ...state, 'ubicacion': e.target.value })}
                    >
                      {
                        props.ubicaciones.map((element) => {
                          return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.daPasoSeccion}
                        onChange={(e) => {
                          setState({ ...state, 'daPasoSeccion': !state.daPasoSeccion });
                        }}
                        name={"daPasoSeccion"}
                        color="primary"
                      />
                    }
                    label="Da paso a la siguiente sección"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.ejecucionDirecta}
                        onChange={(e) => {
                          setState({ ...state, 'ejecucionDirecta': !state.ejecucionDirecta });
                        }}
                        name={"ejecucionDirecta"}
                        color="primary"
                      />
                    }
                    label="Ejecución directa"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.finalizarTarea}
                        onChange={(e) => {
                          setState({ ...state, 'finalizarTarea': !state.finalizarTarea });
                        }}
                        name="finalizarTarea"
                        color="primary"
                      />
                    }
                    label="Finalizar tarea una vez ejecutada"
                  />

                  <Box display='flex'>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="set-fase">Fase</InputLabel>
                      <Select
                        labelId="set-fase"
                        id="fase"
                        value={state.fase}
                        onChange={(e) => {
                          setState({ ...state, 'fase': e.target.value });
                        }}
                      >
                        <MenuItem value={{ orden: 0, nombre: '' }} key={'no-selecciondo-fase'}>{'SIN SELECCIONAR'}</MenuItem>
                        {
                          props.fases.map((element, index) => {
                            return <MenuItem value={element.id} key={index}>{`${element.orden} ${element.nombre}`}</MenuItem>
                          })
                        }
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel htmlFor="set-seccion">Seccion</InputLabel>
                      <Select
                        labelId="set-seccion"
                        id="seccion"
                        value={state.seccion}
                        onChange={(e) => setState({ ...state, 'seccion': e.target.value })}
                      >
                        <MenuItem value={{ orden: 0, nombre: '' }} key={'no-selecciondo-seccion'}>{'SIN SELECCIONAR'}</MenuItem>
                        {
                          seccionesList.map((element, index) => {
                            return <MenuItem value={element.id} key={index}>{`${element.orden} ${element.nombre}`}</MenuItem>
                          })
                        }
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel htmlFor="set-situacion">Situacion</InputLabel>
                      <Select
                        labelId="set-situacion"
                        id="situacion"
                        value={state.situacion}
                        onChange={(e) => setState({ ...state, 'situacion': e.target.value })}
                      >
                        {
                          props.situaciones && props.situaciones.map((element, index) => {
                            return <MenuItem value={element.id} key={index}>{element.nombre}</MenuItem>
                          })
                        }
                      </Select>
                    </FormControl>

                  </Box>

                  <FormControl fullWidth>
                    <InputLabel htmlFor="set-bot">Bot</InputLabel>
                    <Select
                      labelId="set-bot"
                      id="bot"
                      value={state.bot}
                      onChange={(e) => setState({ ...state, 'bot': e.target.value })}
                    >
                      {
                        props.bots.map((element) => {
                          return <MenuItem value={element._id} key={element._id}><BotMenuItem item={element} /></MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>

                  <Box display='flex' justifyContent='center'>
                    <TranscursoTiempo titulo={'Momento Activación'} item={state.momentoActivacion} onChange={(element) => setState({ ...state, 'momentoActivacion': element })} />
                    <TranscursoTiempo titulo={'Duración Tarea'} item={state.duracionTarea} onChange={(element) => setState({ ...state, 'duracionTarea': element })} />
                  </Box>

                </Box>
              </Container>

            </Box>
          )}

          {tabIndex === 1 && (
            <Box display='flex' flexDirection='column'>
              <Typography align="center" variant="button">{'Slides'}</Typography>
              {state.slides.map((element) => {
                return <SlideViewWorld item={element} setElementShow={() => {
                  if (element.animacion !== undefined) {
                    element.animacion = element.animacion._id;
                  }

                  setElementShow(element);
                }} key={element.id} onDelete={() => deleteSlide(element.id)} />;
              })}
            </Box>
          )}

          {tabIndex === 2 && (
            <Box>
              <FormControl fullWidth>
                <InputLabel htmlFor="set-evento">Evento</InputLabel>
                <Select
                  labelId="set-evento"
                  id="evento"
                  value={evento}
                  onChange={(e) => setEvento(e.target.value)}
                >
                  {
                    tiposEventos.map((element) => {
                      return <MenuItem value={element} key={element}>{element}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="set-tarea">Tarea</InputLabel>
                <Select
                  labelId="set-tarea"
                  id="tarea"
                  value={tarea}
                  onChange={(e) => setTarea(e.target.value)}
                >
                  {
                    props.tareas.filter(x => x._id !== state.tareaTarget).map((element) => {
                      return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>

              <TextField
                margin='dense'
                size='small'
                variant='standard'
                fullWidth
                label='Dinero'
                type="number"

                value={dineroEvento}
                onChange={(e) => setDineroEvento(e.target.value)}
              />

              <TextField
                margin='dense'
                size='small'
                variant='standard'
                fullWidth
                label='Ánimo'
                type="number"

                value={animoEvento}
                onChange={(e) => setAnimoEvento(e.target.value)}
              />

              <div style={{ height: 20 }}></div>

              <Box display='flex' justifyContent='flex-end' width={1}>

                <Button
                  variant="contained"
                  color="default"
                  style={{ margin: '5px' }}
                  onClick={() => { saveEvento() }}
                  disabled={
                    evento === ''
                  }
                >
                  ADD Acción
                </Button>

              </Box>

              <Box m={1}>
                {
                  state.mensajesLanzar.map((element, index) => {
                    return (<Box display='flex' key={element.id}>
                      <EventoViewWorldEdit item={element} />

                      <IconButton onClick={() => {
                        deleteEvento(element.id)
                      }} >
                        <DeleteIcon />

                      </IconButton>

                    </Box>);
                  })
                }
              </Box>
            </Box>
          )}

        </Box>

        <div style={{ height: 20 }}></div>

        <Box display='flex' justifyContent='flex-end' width={1}>

          {
            props.onDelete ?
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: '5px' }}
                onClick={() => setOpenConfirmationDialog(true)}
              >
                Borrar
              </Button>
              : <div></div>
          }

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => saveSlide()}
          >
            ADD Slide
          </Button>

          <Button
            variant="contained"
            color="default"
            style={{ margin: '5px' }}
            onClick={() => props.cancel()}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => {
              props.save(state);
              props.cancel();
            }}

          >
            Guardar
          </Button>
        </Box>

      </Box>

    </Container >

  );
};