import React from 'react';
import dagre from 'dagre';

import { Box } from '@material-ui/core';
import { Dialog, DialogContent } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core';
import { ButtonGroup, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';


import ReactFlow, { isNode, Background, MiniMap, Controls } from 'react-flow-renderer';

// header
import Header from '../../component/header';

// entity para la simulacion
import { Simulacion } from '../../entity/simulacion';
import { World } from '../../entity/world';

// componentes
import { SimulacionViewWorldEdit } from '../simulacion/simulacionViewWorldEdit';

import { TareaInteraccionViewEdit } from '../tareas/editorsimulacion/tareaInteraccionViewEdit';
import { RespuestaViewEdit } from '../respuesta/editorsimulacion/respuestaViewEdit';
import { TareaBuildViewEdit } from '../tareas/editorsimulacion/tareaBuildViewEdit';
import { TareaRandomViewdEdit } from '../tareas/editorsimulacion/tareaRandomViewEdit';

import { SimulacionReactFlowFaseEdit } from './simulacionReactFlowFaseEdit';
import { SimulacionReactFlowSeccionEdit } from './simulacionReactFlowSeccionEdit';
import { SimulacionReactFlowSituacionEdit } from './simulacionReactFlowSituacionEdit';


// utilidades
import { setLoading, ColoresPaletaAzul, InfoDialog } from '../../component/utils';



import { socketCustom } from '../../util/socket';


const dagreGraph = new dagre.graphlib.Graph();

const nodeWidth = 172;
const nodeHeight = 36;


class EditorReactFlow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

      loading: false,

      _id: this.props.match.params.id !== undefined ? this.props.match.params.id : '',

      simulacion: undefined,
      empresas: [],
      skills: [],
      ubicaciones: [],
      bots: [],
      tareas: [],
      tipostareas: [],
      animaciones: [],
      canalComunicacion: [],
      incidencias: [],
      logros: [],

      elements: [],

      faseSeleccionada: '',
      seccionSeleccionada: '',
      situacionSeleccionada: '',
      showSituaciones: false,

      messageText: ''
    }

    // funcion para recargar los datos desde la API
    this.onCargar = this.onCargar.bind(this);
    this.onCargarSimulacion = this.onCargarSimulacion.bind(this);
    this.onCargarFlow = this.onCargarFlow.bind(this);

    // al hacer click en un nodo
    this.onElementClick = this.onElementClick.bind(this);

    // funcion para salir del modo de edición
    this.clearElementShow = this.clearElementShow.bind(this);

    // para el render del elemento a mostrar
    this.renderElementEdit = this.renderElementEdit.bind(this);

    //  funcion para obtener los elementos, por si hay que filtrarlos
    this.getElementsFlow = this.getElementsFlow.bind(this);

    //  para el render del desplegable con las fases, secciones y situacion
    this.renderSelectFases = this.renderSelectFases.bind(this);
    this.renderSelectSecciones = this.renderSelectSecciones.bind(this);
    this.renderSelectSituaciones = this.renderSelectSituaciones.bind(this);
    this.renderCheckShowSituaciones = this.renderCheckShowSituaciones.bind(this);

    // función para guardar datos
    this.saveSimulacion = this.saveSimulacion.bind(this);
    this.deleteSimulacion = this.deleteSimulacion.bind(this);

    this.saveFase = this.saveFase.bind(this);
    this.deleteFase = this.deleteFase.bind(this);

    this.saveSeccion = this.saveSeccion.bind(this);
    this.deleteSeccion = this.deleteSeccion.bind(this);

    this.saveSituacion = this.saveSituacion.bind(this);
    this.deleteSituacion = this.deleteSituacion.bind(this);

    // tareas
    this.saveTarea = this.saveTarea.bind(this);
    this.deleteTarea = this.deleteTarea.bind(this);
    this.saveRespuesta = this.saveRespuesta.bind(this);

    this.onNodeDragStop = this.onNodeDragStop.bind(this);

    this.botonesNuevasTareas = this.botonesNuevasTareas.bind(this);

    // para mostrar las posibles incidencias
    this.showIncidencias = this.showIncidencias.bind(this);

  }

  componentDidMount() {
    this.onCargar();
  }

  async onCargar() {
    this.setState({ loading: true });

    // obtenemos la simulacion
    let data = await this.onCargarSimulacion();

    //  obtenemos el reactflow de la simulacion
    await this.onCargarFlow();

    this.setState({ loading: true });

    // obtenemos los skills
    let skills = await World.getSkill();

    // obtenemos las ubicaciones
    let ubicaciones = await World.getUbicaciones();

    // obtenemos los bots
    let bots = await World.getBot();

    //  obtenemos las empresas
    let empresas = await World.getEmpresa();

    // obtenemos los tipos de tareas
    let tipostareas = await World.getTiposTareas();

    // obtenemos las animaciones
    let animaciones = await World.getAnimaciones();

    let canalComunicacion = await World.getTiposCanales();

    let logros = await World.getLogros();


    this.setState({
      loading: false,
      simulacion: data.records[0],
      skills: skills.records,
      ubicaciones: ubicaciones.records,
      bots: bots.records,
      empresas: empresas.records,
      tipostareas: tipostareas.records,
      animaciones: animaciones.records,
      canalComunicacion: canalComunicacion.records,
      logros: logros.records
    });

  }

  async onCargarSimulacion() {
    return await Simulacion.getPlantillaById(this.state._id);
  }

  async onCargarFlow() {
    let data = new FormData();
    data.append('targetid', this.state._id);

    if (this.state.faseSeleccionada !== '') {
      data.append('fase', this.state.faseSeleccionada.id);
    }

    if (this.state.seccionSeleccionada !== '') {
      data.append('seccion', this.state.seccionSeleccionada.id);
    }

    if (this.state.situacionSeleccionada !== '') {
      data.append('situacion', this.state.situacionSeleccionada.id);
    }


    this.setState({ loading: true });

    let flow = await Simulacion.getReactFlow(data);

    let tareas = await Simulacion.getTareas(this.state._id);


    this.setState({ loading: false, elements: flow.nodos, incidencias: flow.incidencias, tareas: tareas.records });
  }


  // TB LR
  getLayoutedElements = (elements, direction = 'LR') => {
    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });

    elements.forEach((el) => {
      if (isNode(el)) {
        dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
      } else {
        dagreGraph.setEdge(el.source, el.target);
      }
    });

    dagre.layout(dagreGraph);

    return elements.map((el) => {
      if (isNode(el)) {

        const nodeWithPosition = dagreGraph.node(el.id);
        el.targetPosition = isHorizontal ? 'left' : 'top';
        el.sourcePosition = isHorizontal ? 'right' : 'bottom';

        // unfortunately we need this little hack to pass a slightly different position
        // to notify react flow about the change. Moreover we are shifting the dagre node position
        // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).

        // asignamosuna nueva posición solo cuando la posición viene a cero
        if (el.position.x === 0 && el.position.y === 0) {
          el.position = {
            x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
            y: nodeWithPosition.y - nodeHeight / 2,
          };
        }
      }

      return el;
    });
  };

  onElementClick(event, element) {
    this.setState({ showElement: element });
  }

  clearElementShow() {
    this.setState({ showElement: undefined });
  }

  renderElementEdit() {
    if (this.state.showElement === undefined) {
      return <div></div>;
    }

    let render = <div></div>;

    switch (this.state.showElement.data.tipo) {

      case 'simulacion':

        render = <SimulacionViewWorldEdit
          key={this.state.showElement._id}
          animaciones={this.state.animaciones}

          item={this.state.simulacion}
          empresas={this.state.empresas}
          bots={this.state.bots.filter(x => x.tipo === 'Bot')}
          skills={this.state.skills}

          onDelete={this.deleteSimulacion}
          save={this.saveSimulacion}
          refresh={this.onCargar}
          cancel={this.clearElementShow}
        />;

        break;

      case 'fase':

        render = <SimulacionReactFlowFaseEdit
          item={this.state.showElement.data.objeto}
          cancel={this.clearElementShow}
          save={this.saveFase}
          onDelete={this.deleteFase}
        />

        break;

      case 'seccion':

        render = <SimulacionReactFlowSeccionEdit
          item={this.state.showElement.data.objeto}
          animaciones={this.state.animaciones}
          cancel={this.clearElementShow}
          save={this.saveSeccion}
          onDelete={this.deleteSeccion}
        />

        break;

      case 'situacion':

        render = <SimulacionReactFlowSituacionEdit
          item={this.state.showElement.data.objeto}
          cancel={this.clearElementShow}
          save={this.saveSituacion}
          onDelete={this.deleteSituacion}
        />


        break;

      case 'tarea':

        if (this.state.showElement.data.objeto.className === 'TareaInteraccion') {
          render = <TareaInteraccionViewEdit
            item={this.state.showElement.data.objeto}
            fases={this.state.simulacion.fases}
            ubicaciones={this.state.ubicaciones}
            bots={this.state.bots.filter(x => x.tipo === 'Bot')}
            tareas={this.state.tareas}
            skills={this.state.skills}
            tipostareas={this.state.tipostareas}
            animaciones={this.state.animaciones}
            situaciones={[]}
            canalComunicacion={this.state.canalComunicacion}

            save={this.saveTarea}
            cancel={this.clearElementShow}
            onCargar={this.onCargarFlow}
            onDelete={this.deleteTarea}
          />;
        }

        if (this.state.showElement.data.objeto.className === 'TareaBuild') {
          render = <TareaBuildViewEdit
            item={this.state.showElement.data.objeto}
            fases={this.state.simulacion.fases}
            ubicaciones={this.state.ubicaciones}
            bots={this.state.bots.filter(x => x.tipo === 'Bot')}
            skills={this.state.skills}
            situaciones={[]}
            canalComunicacion={this.state.canalComunicacion}
            animaciones={this.state.animaciones}

            onCargar={this.onCargarFlow}
            save={this.saveTarea}
            cancel={this.clearElementShow}
            onDelete={this.deleteTarea} />;
        }

        if (this.state.showElement.data.objeto.className === 'TareaRandom') {
          render = <TareaRandomViewdEdit
            item={this.state.showElement.data.objeto}
            fases={this.state.simulacion.fases}
            ubicaciones={this.state.ubicaciones}
            bots={this.state.bots.filter(x => x.tipo === 'Bot')}
            tareas={this.state.tareas}
            skills={this.state.skills}
            situaciones={[]}
            canalComunicacion={this.state.canalComunicacion}
            animaciones={this.state.animaciones}

            onCargar={this.onCargarFlow}
            save={this.saveTarea}
            cancel={this.clearElementShow}
            onDelete={this.deleteTarea} />;
        }

        break;

      case 'respuesta':

        //  localizamos la tarea a la que pertenecemos, para filtrar la lista de tareas disponibles solo por las de su misma situacion
        var miTarea = this.state.tareas.filter(x => x._id.toString() === this.state.showElement.data.objeto.tareaTarget.toString());

        var tareas = [];

        if (miTarea.length > 0) {
          tareas = this.state.tareas.filter(x => x.situacion.toString() === miTarea[0].situacion.toString() && x._id.toString() != miTarea[0]._id.toString());
        }

        render = <RespuestaViewEdit item={this.state.showElement.data.objeto}
          tareas={tareas}
          skills={this.state.skills}
          animaciones={this.state.animaciones}
          logros={this.state.logros}
          save={(data) => this.saveRespuesta(data)}
          cancel={this.clearElementShow}
        />;

        break


      default:
        break;
    }

    return render;
  }


  async saveSimulacion(element) {
    if(this.state.incidencias.length > 0 && element.enProduccion === true) {
      // TODO No dejar pasar a producción si hay incidencias
      alert("Tine incidencias para solucionar. No puede pasar esta Simulación a Producción");
      //element.enProduccion = false;
    }


    let data = new FormData();

    // esta es la plantilla
    data.append('_id', this.state.simulacion._id);
    if (element.nombre) data.append('nombre', element.nombre);
    if (element.descripcion) data.append('descripcion', element.descripcion);
    if (element.objetivo) data.append('objetivo', element.objetivo);
    if (element.presupuesto) data.append('presupuesto', element.presupuesto);
    if (element.segundosEsperaRespuesta) data.append('segundosEsperaRespuesta', element.segundosEsperaRespuesta);
    if (element.nivel) data.append('nivel', element.nivel);
    data.append('enProduccion', element.enProduccion ? element.enProduccion : false);
    if (element.bot) data.append('bot', element.bot);
    if (element.tipo) data.append('tipo', element.tipo);
    if (element.duracion) data.append('duracion', JSON.stringify(element.duracion));
    if (element.skills) data.append('skills', JSON.stringify(element.skills));
    if (element.fases) data.append('fases', JSON.stringify(element.fases));
    if (element.empresaTarget) data.append('empresaTarget', element.empresaTarget);
    if (element.position) data.append('position', JSON.stringify(element.position));

    // si hemos indicado una nueva imagen, la enviamos
    if (element.imagenData) {
      data.append('fichero', element.imagenData);
    }

    // guardamos la simulacion
    await Simulacion.addPlantilla(data);

    // obtenemos la simulacion
    let simulacion = await this.onCargarSimulacion();

    this.setState({
      simulacion: simulacion.records[0]
    });

    await this.onCargarFlow();

    // limpiamos el objeto renderizado
    this.clearElementShow();

  }

  deleteSimulacion(element) {
    Simulacion.deletePlantilla({ targetid: this.state._id }).then(() => window.location.href = "/misplantillas/");
  }

  async saveFase(element) {

    let simulacion = { ...this.state.simulacion };

    // es una nueva fase para añadir
    if (element === undefined) {
      simulacion.fases.push({ nombre: 'fase', orden: (parseInt(simulacion.fases.length) + 1), secciones: [{ orden: 1, nombre: 'seccion', situaciones: [{ orden: 1, nombre: 'situacion 1' }] }] });
    }

    // es para modificar una fase
    if (element !== undefined) {

      // recorremos las fases, buscando la que hemos modificado
      simulacion.fases.forEach((fase, index) => {
        if (fase.id.toString() === element.id.toString()) {
          simulacion.fases[index] = element;
        }
      });

    }

    await this.saveSimulacion(simulacion);
  }

  deleteFase(element) {
    // buscamos si hay tareas vinculadas a esta fase
    let tareas = this.state.tareas.filter(x => x.fase.toString() === element.nombre);

    if (tareas.length > 0) {
      this.setState({ messageText: 'Hay tareas vinculadas a esta Sección' });
    }
    else {
      let simulacion = { ...this.state.simulacion };

      // borraos la situación
      // recorremos las fases y secciones, buscando la que hemos modificado
      simulacion.fases.forEach((fase, indexFase) => {
        if (fase.id.toString() === element.id.toString()) {
          // simulacion.fases.splice(indexFase, 1);
          this.setState({ messageText: 'Tarea por concretar' });
        }
      });

      this.saveSimulacion(simulacion);
    }

  }

  saveSeccion(element) {

    let simulacion = { ...this.state.simulacion };

    // es una nueva seccion para añadir
    if (element === undefined) {
      return;
    }

    // es para modificar una seccion
    if (element !== undefined) {

      // recorremos las fases y secciones, buscando la que hemos modificado
      simulacion.fases.forEach((fase, indexFase) => {
        fase.secciones.forEach((seccion, indexSeccion) => {
          if (seccion.id.toString() === this.state.showElement.id.toString()) {
            simulacion.fases[indexFase].secciones[indexSeccion] = element;
          }
        })
      });

    }

    this.saveSimulacion(simulacion);
  }

  deleteSeccion(element) {
    // buscamos si hay tareas vinculadas a esta seccion
    let tareas = this.state.tareas.filter(x => x.seccion.toString() === element.nombre);

    if (tareas.length > 0) {
      this.setState({ messageText: 'Hay tareas vinculadas a esta Sección' });
    }
    else {
      let simulacion = { ...this.state.simulacion };

      // borraos la situación
      // recorremos las fases y secciones, buscando la que hemos modificado
      simulacion.fases.forEach((fase, indexFase) => {
        fase.secciones.forEach((seccion, indexSeccion) => {
          if (seccion.id.toString() === element.id.toString()) {
            simulacion.fases[indexFase].secciones.splice(indexSeccion, 1);
          }
        })
      });

      this.saveSimulacion(simulacion);
    }

  }


  saveSituacion(element) {

    let simulacion = { ...this.state.simulacion };

    // es para modificar una fase
    if (element !== undefined) {

      // recorremos las fases, secciones y situaciones, buscando la que hemos modificado
      simulacion.fases.forEach((fase, indexFase) => {
        fase.secciones.forEach((seccion, indexSeccion) => {
          seccion.situaciones.forEach((situacion, indexSituacion) => {
            if (situacion.id.toString() === this.state.showElement.id.toString()) {
              simulacion.fases[indexFase].secciones[indexSeccion].situaciones[indexSituacion] = element;
            }
          })
        })
      });

    }

    this.saveSimulacion(simulacion);
  }

  deleteSituacion(element) {
    // buscamos si hay tareas vinculadas a esta situacion
    let tareas = this.state.tareas.filter(x => x.situacion.toString() === element.nombre);

    if (tareas.length > 0) {
      this.setState({ messageText: 'Hay tareas vinculadas a esta Situación' });
    }
    else {
      let simulacion = { ...this.state.simulacion };

      // borraos la situación
      // recorremos las fases, secciones y situaciones, buscando la que hemos modificado
      simulacion.fases.forEach((fase, indexFase) => {
        fase.secciones.forEach((seccion, indexSeccion) => {
          seccion.situaciones.forEach((situacion, indexSituacion) => {
            if (situacion.id.toString() === element.id.toString()) {
              simulacion.fases[indexFase].secciones[indexSeccion].situaciones.splice(indexSituacion, 1);
            }
          })
        })
      });

      this.saveSimulacion(simulacion);

    }
  }


  async saveTarea(element) {
    let data = new FormData();

    // esta es la plantilla
    data.append('targetid', this.state._id);

    if (element._id) data.append('_id', element._id);
    if (element.tipo) data.append('tipo', element.tipo);
    if (element.nombre) data.append('nombre', element.nombre);
    if (element.ubicacion) data.append('ubicacion', element.ubicacion);

    data.append('daPasoSeccion', element.daPasoSeccion ? element.daPasoSeccion : false);
    data.append('finalizarTarea', element.finalizarTarea ? element.finalizarTarea : false);
    data.append('ejecucionDirecta', element.ejecucionDirecta ? element.ejecucionDirecta : false);

    if (element.orden) data.append('orden', element.orden);
    if (element.bot) data.append('bot', element.bot);
    if (element.fase) data.append('fase', element.fase);
    if (element.seccion) data.append('seccion', element.seccion);
    if (element.situacion) data.append('situacion', element.situacion);

    if (element.canalComunicacion) data.append('canalComunicacion', element.canalComunicacion);

    if (element.momentoActivacion) data.append('momentoActivacion', JSON.stringify(element.momentoActivacion));
    if (element.duracionTarea) data.append('duracionTarea', JSON.stringify(element.duracionTarea));

    if (element.skills) data.append('skills', JSON.stringify(element.skills));

    if (element.probabilidad) data.append('probabilidad', element.probabilidad);

    // si hemos indicado una nueva imagen, la enviamos
    if (element.imagenData) {
      data.append('fichero', element.imagenData);
    }

    await Simulacion.addTarea(data);

    await this.onCargarFlow()
  }

  deleteTarea(element) {
    this.clearElementShow();

    Simulacion.deleteTarea({ targetid: element._id }).then(() => this.onCargarFlow());
  }

  saveRespuesta(element) {
    let data = new FormData();
    data.append('targetid', element.tareaTarget);

    if (element) {
      data.append('_id', element._id);
      data.append('orden', element.orden);
      if (element.descripcion) data.append('descripcion', element.descripcion);
      if (element.animacion) data.append('animacion', element.animacion);
      data.append('correcta', element.correcta ? element.correcta : false);
      data.append('finalizarTarea', element.finalizarTarea ? element.finalizarTarea : false);
      if (element.mensajeCorrecto) data.append('mensajeCorrecto', element.mensajeCorrecto);
      if (element.mensajeIncorrecto) data.append('mensajeIncorrecto', element.mensajeIncorrecto);
      if (element.skills) data.append('skills', JSON.stringify(element.skills));
    }

    Simulacion.addRespuestaTarea(data).then(() => {
      this.clearElementShow();
      this.onCargarFlow();
    });

  }


  getElementsFlow() {
    if (this.state.elements === undefined) return [];

    let elements = [...this.state.elements];

    // si no hay que mostrar situaciones, las quitamos
    // elements = elements.map((data) => {
    //   if (isNode(data) && data.data.tipo === 'situacion') {
    //     data.isHidden = !this.state.showSituaciones;

    //     return data;
    //   }
    //   else return data;
    // });

    return elements;
  }


  renderSelectFases() {
    if (this.state.simulacion === undefined) return <Box></Box>;


    return <Box margin={1} display={'flex'} alignItems={'center'}>
      <FormControl>
        <InputLabel htmlFor="fases">Fase</InputLabel>
        <Select
          id="fases"
          labelId='fases'
          value={this.state.faseSeleccionada}
          onChange={(e) => this.setState({ faseSeleccionada: e.target.value, seccionSeleccionada: '', situacionSeleccionada: '' }, this.onCargarFlow)}
        >
          <MenuItem value={''} key={0}>{'Todas'}</MenuItem>
          {
            this.state.simulacion.fases.map((element) => {
              return <MenuItem value={element} key={element.id}>{element.nombre}</MenuItem>
            })
          }
        </Select>
      </FormControl>
    </Box>;
  }

  renderSelectSecciones() {

    if (this.state.faseSeleccionada === '') return <Box></Box>;

    return <Box margin={1}>
      <FormControl>
        <InputLabel htmlFor="secciones">Secciones</InputLabel>
        <Select
          id="secciones"
          labelId="secciones"
          value={this.state.seccionSeleccionada}
          onChange={(e) => this.setState({ seccionSeleccionada: e.target.value, situacionSeleccionada: '' }, this.onCargarFlow)}
        >
          <MenuItem value={''} key={0}>{'Todas'}</MenuItem>
          {
            this.state.faseSeleccionada.secciones.map((element) => {
              return <MenuItem value={element} key={element.id}>{element.nombre}</MenuItem>
            })
          }
        </Select>
      </FormControl>
    </Box>;
  }

  renderSelectSituaciones() {
    if (this.state.seccionSeleccionada === '') return <Box></Box>;

    return <Box margin={1}>
      <FormControl>
        <InputLabel htmlFor="situacion">Situacion</InputLabel>
        <Select
          id="situacion"
          labelId="situacion"
          value={this.state.situacionSeleccionada}
          onChange={(e) => this.setState({ situacionSeleccionada: e.target.value }, this.onCargarFlow)}
        >
          <MenuItem value={''} key={0}>{'Todas'}</MenuItem>
          {
            this.state.seccionSeleccionada.situaciones.map((element) => {
              return <MenuItem value={element} key={element.id}>
                <Box>{`${element.nombre} (Nivel ${element.nivel})`}
                </Box>
              </MenuItem>
            })
          }
        </Select>
      </FormControl>
    </Box>
  }

  renderCheckShowSituaciones() {
    return <Box></Box>;

    return <Box margin={1}>
      <FormControlLabel
        control={
          <Checkbox
            checked={this.state.showSituaciones}
            onChange={(event) => this.setState({ showSituaciones: event.target.checked })}
            name="showSituaciones"
            color="primary"
          />
        }
        label="Mostrar Situaciones"
      />

    </Box>
  }

  onNodeDragStop(event, node) {

    let data = {
      tipo: node.data.tipo,
      position: node.position,
      id: node.data.id,
      simulacionTarget: this.state.simulacion._id
    }

    socketCustom.emit('positionedNodeReactFlow', data);
  }


  botonesNuevasTareas() {
    if (!(this.state.faseSeleccionada && this.state.seccionSeleccionada && this.state.situacionSeleccionada)) {
      return <Box></Box>;
    }

    return <ButtonGroup color="secondary" aria-label="outlined primary button group">
      {
        this.state.tipostareas.map((element) => {

          return <Button
            variant="contained"
            style={{ backgroundColor: ColoresPaletaAzul[element] }}
            key={element} onClick={() => {

              let data = {};
              data.tipo = element;
              data.fase = this.state.faseSeleccionada.id;
              data.seccion = this.state.seccionSeleccionada.id;
              data.situacion = this.state.situacionSeleccionada.id;

              this.saveTarea(data);

            }}>{'Añadir ' + element}</Button>
        })
      }

    </ButtonGroup>;
  }

  showIncidencias() {
    if (this.state.loading === true) {
      return <Box></Box>;
    }

    let list = this.state.incidencias.map((element, index) => <Alert key={index} severity="warning" style={{ margin: 5, width: 250 }}>{element}</Alert>);

    return <Box>{list}</Box>

  }

  render() {

    dagreGraph.setDefaultEdgeLabel(() => ({}));

    return (
      <div className="backgroundJuego">

        <Header title="Flow de la Simulación" history={this.props.history}></Header>

        <InfoDialog
          content={this.state.messageText}
          open={this.state.messageText.length > 0}
          onClose={() => {
            this.setState({ messageText: '' });
          }}
        />


        <Box margin={1} display={'flex'} alignItems={'center'}>
          <this.renderSelectFases />
          <this.renderSelectSecciones />
          <this.renderSelectSituaciones />
          <this.renderCheckShowSituaciones />

          <this.botonesNuevasTareas />
        </Box>

        {this.state.loading === true ? setLoading() : ''}

        <Box style={{ height: '80vh' }}>
          <ReactFlow
            onLoad={(reactFlowInstance) => reactFlowInstance.fitView()}
            onElementClick={this.onElementClick}
            onNodeDragStop={this.onNodeDragStop}


            elements={this.getLayoutedElements(this.getElementsFlow())}
            snapToGrid={true}
            snapGrid={[15, 15]}>

            <Background
              variant="dots"
              gap={12}
            />

            <MiniMap />

            <this.showIncidencias />

            <Controls />

          </ReactFlow>

        </Box>

        <Dialog
          fullWidth
          maxWidth={'md'}
          open={this.state.showElement !== undefined}
          onClose={this.clearElementShow}>
          <DialogContent>
            <this.renderElementEdit />
          </DialogContent>
        </Dialog>

      </div >
    );
  }

}

export default EditorReactFlow;